import CryptoJS from "crypto-js/crypto-js.js";

export const AESEncrypt = number => {
  const key = CryptoJS.enc.Utf8.parse("!QAZ@WSX#EDC1qaz");
  const iv = CryptoJS.enc.Utf8.parse(")OKM(IJN&YGV0okn");
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(number), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
};

// temp methods for encrypt and decrypt sesion storage

export const SessionStoragedecrypt = ciphertext => {
  if (!ciphertext) {
    return null;
  }
  const key = CryptoJS.enc.Utf8.parse("!QAZ@WSX#EDC1qaz");
  const iv = CryptoJS.enc.Utf8.parse(")OKM(IJN&YGV0okn");
  const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};
