import {
  GET_DETAILVIEW_MAP,
  GET_DETAILVIEW_MAPSLIP1_SUCCESS,
  GET_DETAILVIEW_MAPSLIP2_SUCCESS,
  GET_DETAILVIEW_MAPSPEED1_SUCCESS,
  GET_DETAILVIEW_MAPSPEED2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORT1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORT2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS,
  GET_DV_MAPEFFORTREFERENCE1_SUCCESS,
  GET_DV_MAPEFFORTREFERENCE2_SUCCESS,
  GET_DV_MAPSLIPPOWER1_SUCCESS,
  GET_DV_MAPSLIPPOWER2_SUCCESS,
  GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS,
  GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS,
  GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS,
  GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS,
  GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  GET_DETAILVIEW_MAP_FAILURE,
  CLEAR_DETAILVIEW_MAP,
  GET_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS,
  GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS,
  GET_DV_TT_EFFORTREFERENCE1_SUCCESS,
  GET_DV_TT_EFFORTREFERENCE2_SUCCESS,
  GET_DV_TT_SLIPPOWER1_SUCCESS,
  GET_DV_TT_SLIPPOWER2_SUCCESS,
  GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS,
  GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS,
  GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS,
  GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS,
  GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  GET_DETAILVIEW_TIMETREND_FAILURE,
  CLEAR_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_SCATTERPLOT,
  GET_DETAILVIEW_SCATTERPLOT_SUCCESS,
  GET_DETAILVIEW_SCATTERPLOT_FAILURE,
  GET_DETAILVIEW_SCATTERPLOT2_SUCCESS,
  GET_DETAILVIEW_SCATTERPLOT2_FAILURE,
  CLEAR_DETAILVIEW_SCATTERPLOT,
  SET_FILTER,
  SET_FILTER_ADVANCED,
  SET_MAINTRENDSIGNAL,
  SET_MAINSCATTERSIGNAL,
  ADD_OVERLAY,
  CLEAR_BASICFILTER,
  CLEAR_ADVANCEDFILTER,
  SET_LOADING,
  GET_DETAILVIEW_HISTOGRAM,
  GET_DETAILVIEW_HISTOGRAM_SUCCESS,
  GET_DETAILVIEW_HISTOGRAM_FAILURE,
  CLEAR_DETAILVIEW_HISTOGRAM,
  SET_TIMETREND_LOADING,
  GET_DETAILVIEW_MAPDATESLIDER,
  GET_DETAILVIEW_MAPDATESLIDER_SUCCESS,
  GET_DETAILVIEW_MAPDATESLIDER_FAILURE,
  CLEAR_DETAILVIEW_MAPDATESLIDER,
} from "./constants";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setTimetrendLoading = (payload) => ({
  type: SET_TIMETREND_LOADING,
  payload,
});

//Map
export const getDetailViewMap = (id, startDate, endDate, docType, bogie) => ({
  type: GET_DETAILVIEW_MAP,
  payload: { id, startDate, endDate, docType, bogie },
});

export const getDetailViewMapSlip1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPSLIP1_SUCCESS,
  payload,
});

export const getDetailViewMapSlip2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPSLIP2_SUCCESS,
  payload,
});

export const getDetailViewMapSpeed1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPSPEED1_SUCCESS,
  payload,
});

export const getDetailViewMapSpeed2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPSPEED2_SUCCESS,
  payload,
});

export const getDetailViewMapEffort1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORT1_SUCCESS,
  payload,
});

export const getDetailViewMapEffort2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORT2_SUCCESS,
  payload,
});

export const getDetailViewMapEffortDriving1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS,
  payload,
});

export const getDetailViewMapEffortDriving2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS,
  payload,
});

export const getDetailViewMapEffortBraking1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS,
  payload,
});

export const getDetailViewMapEffortBraking2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS,
  payload,
});

export const getDetailViewMapEffortBrakingMech1Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  payload,
});

export const getDetailViewMapEffortBrakingMech2Success = (payload) => ({
  type: GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  payload,
});

export const getDvMapDrivingTrailerVel1Success = (payload) => ({
  type: GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS,
  payload,
});

export const getDvMapEffortReference1Success = (payload) => ({
  type: GET_DV_MAPEFFORTREFERENCE1_SUCCESS,
  payload,
});

export const getDvMapEffortReference2Success = (payload) => ({
  type: GET_DV_MAPEFFORTREFERENCE2_SUCCESS,
  payload,
});
export const getDvMapSlipPower1Success = (payload) => ({
  type: GET_DV_MAPSLIPPOWER1_SUCCESS,
  payload,
});

export const getDvMapSlipPower2Success = (payload) => ({
  type: GET_DV_MAPSLIPPOWER2_SUCCESS,
  payload,
});
export const getDvMapPowerBrakingMechanical1Success = (payload) => ({
  type: GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS,
  payload,
});

export const getDvMapPowerBrakingMechanical2Success = (payload) => ({
  type: GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS,
  payload,
});
export const getDvMapAirPressureBrakeCylinder1Success = (payload) => ({
  type: GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS,
  payload,
});

export const getDvMapAirPressureBrakeCylinder2Success = (payload) => ({
  type: GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS,
  payload,
});
export const getDvMapAmplitudeTorsionalOscillation1Success = (payload) => ({
  type: GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  payload,
});

export const getDvMapAmplitudeTorsionalOscillation2Success = (payload) => ({
  type: GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  payload,
});

export const getDetailViewMapFailure = (error) => ({
  type: GET_DETAILVIEW_MAP_FAILURE,
  error,
});

export const clearDetailViewMap = (payload) => ({
  type: CLEAR_DETAILVIEW_MAP,
  payload,
});

export const getDetailViewMapDateSlider = (id, startDate, endDate) => ({
  type: GET_DETAILVIEW_MAPDATESLIDER,
  payload: { id, startDate, endDate },
});

export const getDetailViewMapDateSliderSucess = (payload) => ({
  type: GET_DETAILVIEW_MAPDATESLIDER_SUCCESS,
  payload,
});

export const getDetailViewMapDateSliderFailure = (error) => ({
  type: GET_DETAILVIEW_MAPDATESLIDER_FAILURE,
  error,
});

export const clearDetailViewMapDateSlider = (payload) => ({
  type: CLEAR_DETAILVIEW_MAPDATESLIDER,
  payload,
});

//TimeTrend
export const getDetailViewTimetrend = (
  id,
  startDate,
  endDate,
  docType,
  bogie
) => ({
  type: GET_DETAILVIEW_TIMETREND,
  payload: { id, startDate, endDate, docType, bogie },
});

export const getDetailViewTimetrendSlip1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendSlip2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendSpeed1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendSpeed2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffort1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffort2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortDriving1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortDriving2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortBraking1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortBraking2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortBrakingMech1Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS,
  payload,
});

export const getDetailViewTimetrendEffortBrakingMech2Success = (payload) => ({
  type: GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS,
  payload,
});

export const getDvTtDrivingTrailerVel1Success = (payload) => ({
  type: GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS,
  payload,
});

export const getDvTtEffortReference1Success = (payload) => ({
  type: GET_DV_TT_EFFORTREFERENCE1_SUCCESS,
  payload,
});
export const getDvTtEffortReference2Success = (payload) => ({
  type: GET_DV_TT_EFFORTREFERENCE2_SUCCESS,
  payload,
});

export const getDvTtSlipPower1Success = (payload) => ({
  type: GET_DV_TT_SLIPPOWER1_SUCCESS,
  payload,
});
export const getDvTtSlipPower2Success = (payload) => ({
  type: GET_DV_TT_SLIPPOWER2_SUCCESS,
  payload,
});

export const getDvTtPowerBrakingMechanical1Success = (payload) => ({
  type: GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS,
  payload,
});
export const getDvTtPowerBrakingMechanical2Success = (payload) => ({
  type: GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS,
  payload,
});

export const getDvTtAirPressureBrakeCylinder1Success = (payload) => ({
  type: GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS,
  payload,
});
export const getDvTtAirPressureBrakeCylinder2Success = (payload) => ({
  type: GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS,
  payload,
});

export const getDvTtAmplitudeTorsionalOscillation1Success = (payload) => ({
  type: GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  payload,
});
export const getDvTtAmplitudeTorsionalOscillation2Success = (payload) => ({
  type: GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  payload,
});

export const getDetailViewTimetrendFailure = (error) => ({
  type: GET_DETAILVIEW_TIMETREND_FAILURE,
  error,
});

export const clearDetailViewTimetrend = (payload) => ({
  type: CLEAR_DETAILVIEW_TIMETREND,
  payload,
});

//ScatterPlot
export const getDetailViewScatterplot = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  bogie,
  scatterplotAvg
) => ({
  type: GET_DETAILVIEW_SCATTERPLOT,
  payload: {
    id,
    startDate,
    endDate,
    xAxisSignal,
    yAxisSignal,
    bogie,
    scatterplotAvg,
  },
});

export const getDetailViewScatterplotSuccess = (payload) => ({
  type: GET_DETAILVIEW_SCATTERPLOT_SUCCESS,
  payload,
});

export const getDetailViewScatterplotFailure = (error) => ({
  type: GET_DETAILVIEW_SCATTERPLOT_FAILURE,
  error,
});
//-----------------------------
export const getDetailViewScatterplot2Success = (payload) => ({
  type: GET_DETAILVIEW_SCATTERPLOT2_SUCCESS,
  payload,
});

export const getDetailViewScatterplot2Failure = (error) => ({
  type: GET_DETAILVIEW_SCATTERPLOT2_FAILURE,
  error,
});

export const clearDetailViewScatterplot = (payload) => ({
  type: CLEAR_DETAILVIEW_SCATTERPLOT,
  payload,
});

//----------------------
export const setBasicFilter = (payload) => ({
  type: SET_FILTER,
  payload,
});

export const setAdvancedFilter = (payload) => ({
  type: SET_FILTER_ADVANCED,
  payload,
});

export const clearBasicFilter = (payload) => ({
  type: CLEAR_BASICFILTER,
  payload,
});

export const clearAdvancedFilter = (payload) => ({
  type: CLEAR_ADVANCEDFILTER,
  payload,
});

export const setMainTrendSignal = (trend) => ({
  type: SET_MAINTRENDSIGNAL,
  payload: { trend },
});

export const addOverlay = (payload) => ({
  type: ADD_OVERLAY,
  payload,
});

export const setMainScatterSignal = (scatter) => ({
  type: SET_MAINSCATTERSIGNAL,
  payload: { scatter },
});

//Histogram
export const getDetailViewHistogram = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  zAxisSignal,
  filter
) => ({
  type: GET_DETAILVIEW_HISTOGRAM,
  payload: {
    id,
    startDate,
    endDate,
    xAxisSignal,
    yAxisSignal,
    zAxisSignal,
    filter,
  },
});

export const getDetailViewHistogramSuccess = (payload) => ({
  type: GET_DETAILVIEW_HISTOGRAM_SUCCESS,
  payload,
});

export const getDetailViewHistogramFailure = (error) => ({
  type: GET_DETAILVIEW_HISTOGRAM_FAILURE,
  error,
});

export const clearDetailViewHistogram = (payload) => ({
  type: CLEAR_DETAILVIEW_HISTOGRAM,
  payload,
});
