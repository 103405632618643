import React from "react";
import "./styles.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DropdownList from "react-widgets/lib/DropdownList";
import Multiselect from "react-widgets/lib/Multiselect";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import messages from "./messages";

// const overlaySignalOptions = [
//   "Torsional oscillation control",
//   "Adhesion control",
//   "Sling break",
//   "Sand break",
//   "BT velocity",
//   "Bogi 1",
//   "Bogi 2",
//   "1st position bogie",
//   "2nd position bogie"
// ];

// let mainSignalOptions = [
//   "Torsional oscillation control",
//   "Adhesion control",
//   "Sling break",
//   "Sand break",
//   "BT velocity",
//   "Bogi 1",
//   "Bogi 2"
// ];

class SignalSelector extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
      selectedSignals: null,
    };
  }

  backButton = () => this.props.history.push("/TrainViewPage");
  // this function is to restrict max number of selected item
  /* numberOfSignalsSelected = signals => {
    this.setState({ selectedSignals: signals.length });
    if (signals.length > 1) {
      this.setState({ open: false });
    }
  };

  onToggle = isOpen => {
    if (isOpen === false) {
      this.setState({ open: false });
    } else if (this.props.selectedOverlays !== null) {
      if (isOpen === true && this.props.selectedOverlays.length <= 1) {
        this.setState({ open: true });
      }
    } else if (
      isOpen === true &&
      this.state.selectedSignals <= 1 &&
      this.props.selectedOverlays === null
    ) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  }; */
  // this function is to restrict max number of selected item
  render() {
    return (
      <Row className="containerselectsignal justify-content-between">
        {this.props.chartType === "TREND_TYPE" && (
          <Col md={3}>
            <div className="mainsignal">
              <div className="selectsignal">
                <FormattedMessage {...messages.mainSignalText} />
              </div>
              <DropdownList
                data={this.props.mainSignalValues}
                value={this.props.selectedMain}
                className="dropdownmainsignal"
                onChange={this.props.clickMainSignal}
              />
            </div>
          </Col>
        )}
        {this.props.chartType === "SCATTER_TYPE" && (
          <React.Fragment>
            <Col md={5}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.mainSignalText} />
                </div>
                <DropdownList
                  data={this.props.mainSignalValues}
                  defaultValue={"Speed Driving Trailer / Slip"}
                  className="dropdownmainsignal increasedwidth"
                  onChange={this.props.clickMainSignal}
                />
              </div>
            </Col>
            <Col md={4} className={"noLeftpadding"}>
              <div className={"toggle-button-container"}>
                <button
                  type="button"
                  class={
                    this.props.scatterplotAvg === true
                      ? "toggle-button activeLightBlue"
                      : "toggle-button"
                  }
                  onClick={() =>
                    this.props.handleScatterplotAvgMaxChange("Average")
                  }
                >
                  Average value
                </button>
                <button
                  type="button"
                  class={
                    this.props.scatterplotAvg === true
                      ? "toggle-button"
                      : "toggle-button activeLightBlue"
                  }
                  onClick={() =>
                    this.props.handleScatterplotAvgMaxChange("Maximum")
                  }
                >
                  Maximum value
                </button>
              </div>
            </Col>
          </React.Fragment>
        )}
        {this.props.chartType === "TREND_TYPE" && (
          <Col md={8}>
            <div className="overlaysignal">
              <div className="addsignal">
                <FormattedMessage {...messages.addOverlaysText} />
              </div>
              <Multiselect
                placeholder="None"
                // textField="label"
                // valueField="code"
                /* onToggle={this.onToggle}
                 open={this.state.open}
                 onChange={items => {
                   this.props.clickOverlaySignal(items);
                   this.numberOfSignalsSelected(items);
                 }}*/
                onChange={this.props.clickOverlaySignal}
                disabled={[this.props.selectedMain]}
                data={this.props.overlaySignalValues}
                value={
                  this.props.selectedOverlays !== []
                    ? this.props.selectedOverlays
                    : []
                }
                className={"dropdownoverlaysignal"}
              />
            </div>
          </Col>
        )}
        {/* for histogram dropdownLists */}

        {this.props.chartType === "HISTOGRAM_TYPE" && (
          <React.Fragment>
            <Col md={3}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.xAxisSignalText} />
                </div>
                <DropdownList
                  data={this.props.mainSignalValues}
                  // value={"Speed"}
                  defaultValue={"Speed"}
                  name="xAxisSignal"
                  className="dropdownmainsignal"
                  disabled={[this.props.selectedSignalsHistogram.yAxisSignal]}
                  //onChange={this.props.updateHistogram}
                  onChange={this.props.handleHistogramDropdownChange(
                    "xAxisSignal"
                  )}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.yAxisSignalText} />
                </div>
                <DropdownList
                  data={this.props.yaxisSignalValues}
                  defaultValue={"Effort"}
                  className="dropdownmainsignal"
                  name="yAxisSignal"
                  onChange={this.props.handleHistogramDropdownChange(
                    "yAxisSignal"
                  )}
                  disabled={[this.props.selectedSignalsHistogram.xAxisSignal]}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mainsignal">
                <div className="selectsignal">
                  <FormattedMessage {...messages.zAxisSignalText} />
                </div>
                <DropdownList
                  data={this.props.zaxisSignalValues}
                  defaultValue={"Occurrence"}
                  className="dropdownmainsignal"
                  name="zAxisSignal"
                  onChange={this.props.handleHistogramDropdownChange(
                    "zAxisSignal"
                  )}
                />
              </div>
            </Col>
            <Col md={1} style={{ paddingTop: "27px" }}>
              <button
                className="btn  btn-sm  primarColorBtn"
                onClick={this.props.getHistogramDataBasedonUserSelection}
              >
                Apply
              </button>
            </Col>
          </React.Fragment>
        )}
        <Col md={1}>
          {/* <button
            title="Go to train view"
            onClick={this.backButton}
            className="matrixIcon"
          >
            <img
              src={require("../../../assets/images/matrix.svg")}
              className="download-icon"
            />
          </button> */}
        </Col>
      </Row>
    );
  }
}

export default withRouter(SignalSelector);
