import { createSelector } from "reselect";

const loginresponce = () => state => state.get("loginData");

const loadingSelector = () =>
  createSelector(
    loginresponce(),
    state => state.get("loading")
  );

const loginresponceSelector = () =>
  createSelector(
    loginresponce(),
    state => state.get("loginresponce")
  );

const logincheckSelector = () =>
  createSelector(
    loginresponce(),
    state => state.get("isloggedIn")
  );

// change password
const loadingchangepassSelector = () =>
  createSelector(
    loginresponce(),
    state => state.get("loadingchangepass")
  );

const changepassresponceSelector = () =>
  createSelector(
    loginresponce(),
    state => state.get("changepassresponce")
  );

export {
  loadingSelector,
  loginresponceSelector,
  logincheckSelector,
  loadingchangepassSelector,
  changepassresponceSelector
};
