/*
 * TrainView Messages
 *
 * This contains all the text for the TrainView component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.containers.TrainView.header",
    defaultMessage: "Dashboard/Train view"
  }
});
