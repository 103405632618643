/*
 * TrainView Messages
 *
 * This contains all the text for the TrainView component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  problem: {
    id: "app.components.datepicker.problem",
    defaultMessage: "Invalid date & time range!"
  }
});
