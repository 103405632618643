import React, { Component } from "react";
import "react-light-accordion/demo/css/index.css";
import "./styles.scss";
import TrainList, { BasicFilter, AdvanceFilter } from "../SideMenuItems";

export default class SideMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="containersidemenu">
        <TrainList
          onTrainSelect={this.props.onTrainSelect}
          trainno={this.props.trainno}
          trainList={this.props.trainList}
        />
        <hr />
        <BasicFilter
          onChange={this.props.onChange}
          filter={this.props.basicFilter}
          clearBasicFilter={this.props.clearBasicFilter}
          selectedTab={this.props.selectedTab}
        />
        <hr />
        {this.props.advanceFilter && (
          <AdvanceFilter
            onChange={this.props.onChangeAdvanced}
            filter={this.props.advancedFilter}
            clearAdvancedFilter={this.props.clearAdvancedFilter}
          />
        )}
        <br />
        {/* <NavLink to={"/home"}>Go to home</NavLink> */}
      </div>
    );
  }
}
