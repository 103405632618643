import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../utils/api";
import {
  logIn,
  logInSucess,
  logInFailure,
  changePassword,
  changePassSucess,
  changePassFailure
} from "./actions";
import { LOGIN, CHANGEPASSWORD } from "./constants";
import { handleError } from "../../utils/api-utils";

export function* loginSaga() {
  yield takeLatest(LOGIN, logingInSaga);
}
export default function* logingInSaga(action) {
  try {
    const { username, password } = action.payload;
    const response = yield call(
      api.checkLogin,
      username,
      encodeURIComponent(password)
    );
    // console.log("checking login in saga", response);
    const checkLoginResponce = response.data;
    yield put(logInSucess(checkLoginResponce));
  } catch (error) {
    // yield put(fetchTrainListFailure(error));
    yield put(handleError(error, logInFailure));
  }
}

//  for change password

export function* changePassSaga() {
  yield takeLatest(CHANGEPASSWORD, changingPassSaga);
}
export function* changingPassSaga(action) {
  // console.log("checking password change in saga", action);
  try {
    const { userName, oldpassword, newpassword } = action.payload;
    const response = yield call(
      api.changePassword,
      userName,
      oldpassword,
      newpassword
    );
    console.log("checking password change in saga", response);
    const changePassResponce = response.data;
    yield put(changePassSucess(changePassResponce));
  } catch (error) {
    // yield put(fetchTrainListFailure(error));
    yield put(handleError(error, changePassFailure));
  }
}
