//Library
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { injectIntl, FormattedMessage } from "react-intl";
import Moment from "moment";
import { withRouter } from "react-router-dom";
//Components
import "./styles.scss";
import messages from "./messages";
import {
  overlaySignalOptions,
  mainSignalTrendOptions,
  mainSignalScatterOptions,
  xAxisSignalHistogramOptions,
  yAxisSignalHistogramOptions,
  zAxisSignalHistogramOptions,
} from "./constants";
import Loading from "../../../components/Loading";
import TitleBar from "../../../components/Title/TitleBar";
import FilterDetails from "../../../components/Title/FilterDetails";
import SignalSelector from "../../../components/Title/SignalSelector";
import SingleGraph from "../../../components/SingleGraph";
import {
  IconTimeTrend,
  IconScatterPlot,
  IconHistogram,
  IconGps,
} from "../../../components/Icons";
import DetailViewMap from "../../../components/DetailViewMap";
import DetailViewMapSlider from "../../../components/DetailViewMapSlider";
import DateSlider from "../../../components/DetailViewMapDateSlider";
import DetailViewMapLegend from "../../../components/DetailViewMapLegend";

class SingleTrendPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.child = React.createRef();
    this.state = {
      key: "Slip",
      selectedRun: null,
      markerPosition: {
        lat: 0.0,
        lng: 0.0,
      },
      // isButtonDisabled: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate ||
      this.props.selectedDate !== nextProps.selectedDate ||
      this.props.selectedTrain !== nextProps.selectedTrain ||
      this.props.selectedMainTrend !== nextProps.selectedMainTrend
    ) {
      this.setState({
        selectedRun: null,
        markerPosition: {
          lat: 0.0,
          lng: 0.0,
        },
      });
    }
  }

  updateHistogram = (value) => {
    this.child.current.onHistogramXaxisChange(value);
  };

  onUpdate = ([position]) => {
    console.log("rimjhim");
    sessionStorage.removeItem("clickedTimeStampForRunSlider");
    let selectedRun;
    selectedRun =
      this.state.selectedRun !== null
        ? this.state.selectedRun
        : this.props.mapData.rns && this.props.mapData.rns[0].rid;

    let filterValue = this.props.mapData.rns.filter(
      (item) => item.rid === selectedRun
    );
    let prevFilterValue = filterValue[0].dat[position - 1];
    filterValue = filterValue[0].dat[position]; //[position + 1];

    if (filterValue !== undefined && prevFilterValue !== undefined) {
      if (
        filterValue.pos[0].lat >= 1 &&
        filterValue.pos[1].lat >= 1 &&
        filterValue.gvd === 1 &&
        prevFilterValue.gvd !== 0
      ) {
        this.setState({
          markerPosition: filterValue.pos[1],
        });
      }
      if (position === 0 && filterValue.gvd === 1) {
        this.setState({
          markerPosition: filterValue.pos[1],
        });
      }
    }
  };

  onMapTabClick = async (key) => {
    sessionStorage.removeItem("clickedTimeStamp");
    sessionStorage.removeItem("clickedTimeStampForRunSlider");
    sessionStorage.removeItem("clickedSignal");
    if (key === "New") {
      await this.props.setMainTrendSignal("Speed Driving Trailer");
    } else {
      await this.props.setMainTrendSignal(key);
    }
    this.props.onClickTab("MAP_TYPE");
    this.props.addOverlay(null);
  };

  onMapSliderClick = (runID, startDate, endDate) => {
    // sessionStorage.removeItem("clickedTimeStampForRunSlider");
    if (this.state.selectedRun != runID && this.props.mapData.runId === runID) {
      this.setState({
        selectedRun: runID,
      });
    } else {
      this.setState(
        {
          selectedRun: runID,
          markerPosition: { lat: 0.0, lng: 0.0 },
        },
        () => {
          sessionStorage.removeItem("clickedTimeStampForRunSlider");
        }
      );
    }
    this.props.setZoomIntervalByRunSelection(startDate, endDate);
  };

  onMapReset = () => {
    this.setState({
      selectedRun: null,
      markerPosition: {
        lat: 0.0,
        lng: 0.0,
      },
    });
    this.props.onLoadMoreGraph();
  };

  // onApplyZoomIntervalClick = ()=> {
  //   this.setState({
  //     isButtonDisabled: true
  //   });

  //   this.props.applyZoomInterval()
  // }

  render() {
    const { mapData, mapTotalRuns } = this.props;
    return (
      <div>
        <Row>
          <TitleBar
            trainno={this.props.selectedTrain}
            onClickRedirectMap={() => this.props.history.push("/TrainViewPage")}
            id="detailview"
          />
        </Row>

        <Row>
          <Col md={12} className="tabdetailview">
            <Tabs
              id="controlled-tab"
              activeKey={this.props.selectedTab}
              onSelect={(tab) => this.props.onClickTab(tab)}
            >
              {/* Map tab */}
              <Tab
                eventKey="MAP_TYPE"
                title={
                  <span className="tabIcon">
                    <IconGps />
                    <span className="tabtitle"> Map</span>
                  </span>
                }
              >
                <Row>
                  <Col md={12}>
                    <FilterDetails
                      basicFilter={this.props.basicFilter}
                      advancedFilter={this.props.advancedFilter}
                    />
                  </Col>
                </Row>
                <Row className="lock-zoom-con">
                  <Col /*className="text-right"*/>
                    <span className="boldText">Zoomed interval:</span>
                    <span
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : "visible"
                      }*/
                      className={"visible zoomed-text"}
                    >
                      <span>{this.props.startZoomInterval}</span>
                      <span className="small-space">to</span>
                      <span>{this.props.endZoomInterval}</span>
                      <span className="small-space">-</span>
                      <span>{this.props.lockedZoomDuration}</span>
                    </span>
                    <button
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : " btn  btn-sm  btn-link-custom visible"
                      }*/
                      className={" btn  btn-sm  btn-link-custom visible"}
                      onClick={this.onMapReset}
                      disabled={
                        sessionStorage.getItem("isTrendZoomed") !== "true"
                      }
                      id="resetzoom"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="tabdetailviewmap">
                    <Tabs
                      id="controlled-tab-Map"
                      activeKey={
                        this.props.selectedMainTrend === "Slip"
                          ? "Slip"
                          : this.props.selectedMainTrend === "Effort"
                          ? "Effort"
                          : this.props.selectedMainTrend ===
                            "Effort Mech. Brake"
                          ? "Effort Mech. Brake"
                          : this.props.selectedMainTrend === "Speed at Wheel"
                          ? "Speed at Wheel"
                          : "New"
                      }
                      onSelect={(key) => {
                        //this.setState({ key });
                        this.onMapTabClick(key);
                      }}
                    >
                      {/* Slip tab */}
                      <Tab
                        eventKey="Slip"
                        title={<span className="tabtitle">Slip</span>}
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null /*||
                            this.props.loadingMap === true ||
                            this.state.key === "Slip"*/ ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            <DetailViewMapLegend
                              activeSignal={this.props.selectedMainTrend}
                            />
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={this.props.mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.runId === ""
                                        ? this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid
                                        : this.props.mapData.runId &&
                                          this.props.mapData.runId)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(
                                        item.rid,
                                        item.dat[0].ts,
                                        item.dat[item.dat.length - 1].ts
                                      )
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      {/* Effort tab */}
                      <Tab
                        eventKey="Effort"
                        title={<span className="tabtitle">Effort</span>}
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined || mapData === null ? (
                              //|| this.props.loadingMap === true || this.state.key === "Slip"
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            <DetailViewMapLegend
                              activeSignal={this.props.selectedMainTrend}
                              basicFilter={this.props.basicFilter}
                            />
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.runId === ""
                                        ? this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid
                                        : this.props.mapData.runId &&
                                          this.props.mapData.runId)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(
                                        item.rid,
                                        item.dat[0].ts,
                                        item.dat[item.dat.length - 1].ts
                                      )
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      {/* Effort Driving tab */}
                      {/* <Tab
                        eventKey="EffortDriving"
                        title={<span className="tabtitle">Effort Driving</span>}
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null 
                            //   ||
                            // this.props.loadingMap === true ||
                            // this.state.key === "Slip" 
                            ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(item.rid)
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab> */}
                      {/* Effort Braking (electrical) tab */}
                      {/* <Tab
                        eventKey="EffortBraking"
                        title={
                          <span className="tabtitle">
                            Effort Braking (electrical)
                          </span>
                        }
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null 
                            //   ||
                            // this.props.loadingMap === true ||
                            // this.state.key === "Slip" 
                            ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(item.rid)
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab> */}
                      {/* Effort Braking (mechanical) tab */}
                      <Tab
                        eventKey="Effort Mech. Brake"
                        title={
                          <span className="tabtitle">Effort Mech. Brake</span>
                        }
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null /*||
                            this.props.loadingMap === true ||
                            this.state.key === "Slip"*/ ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            <DetailViewMapLegend
                              activeSignal={this.props.selectedMainTrend}
                              basicFilter={this.props.basicFilter}
                            />
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.runId === ""
                                        ? this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid
                                        : this.props.mapData.runId &&
                                          this.props.mapData.runId)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(
                                        item.rid,
                                        item.dat[0].ts,
                                        item.dat[item.dat.length - 1].ts
                                      )
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      {/* Speed tab */}
                      <Tab
                        eventKey="Speed at Wheel"
                        title={<span className="tabtitle">Speed at Wheel</span>}
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null /*||
                            this.props.loadingMap === true ||
                            this.state.key === "Slip"*/ ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            <DetailViewMapLegend
                              activeSignal={this.props.selectedMainTrend}
                            />
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.runId === ""
                                        ? this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid
                                        : this.props.mapData.runId &&
                                          this.props.mapData.runId)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(
                                        item.rid,
                                        item.dat[0].ts,
                                        item.dat[item.dat.length - 1].ts
                                      )
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      {/* New signal tab */}
                      <Tab
                        eventKey="New"
                        title={
                          <span className="tabtitle">
                            {this.props.selectedMainTrend ===
                              "Speed Driving Trailer" ||
                            this.props.selectedMainTrend ===
                              "Effort Reference" ||
                            this.props.selectedMainTrend === "Power Slip" ||
                            this.props.selectedMainTrend ===
                              "Power Mechanical Brake" ||
                            this.props.selectedMainTrend ===
                              "Pressure Brake Cyl." ||
                            this.props.selectedMainTrend ===
                              "Torsional Osc. Ampl."
                              ? this.props.selectedMainTrend
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()
                              : "Speed Driving Trailer"}
                          </span>
                        }
                      >
                        <Row>
                          <Col md={12}>
                            {mapData === undefined ||
                            mapData ===
                              null /*||
                            this.props.loadingMap === true ||
                            this.state.key === "Slip"*/ ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DetailViewMap
                                mapData={mapData}
                                markerPosition={this.state.markerPosition}
                                basicFilter={this.props.basicFilter}
                                advancedFilter={this.props.advancedFilter}
                                selectedRun={this.state.selectedRun}
                              />
                            )}
                            <DetailViewMapLegend
                              activeSignal={this.props.selectedMainTrend}
                              basicFilter={this.props.basicFilter}
                            />
                            {mapTotalRuns === undefined ||
                            mapTotalRuns === null ? (
                              <Col md={12}>
                                <Loading />
                              </Col>
                            ) : (
                              <DateSlider
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                onMapDateSelect={this.props.onMapDateSelect}
                                selectedDate={this.props.selectedDate}
                                mapTotalRuns={this.props.mapTotalRuns}
                              />
                            )}
                            <div class={"sliderlistcontainer"}>
                              {mapData != undefined &&
                                mapData != null &&
                                mapData.rns &&
                                mapData.rns.length !== 0 &&
                                mapData.rns.map((item, index) => (
                                  <div
                                    variant=""
                                    className={
                                      item.rid ===
                                      (this.state.selectedRun != null
                                        ? this.state.selectedRun
                                        : this.props.mapData.runId === ""
                                        ? this.props.mapData.rns &&
                                          this.props.mapData.rns[0].rid
                                        : this.props.mapData.runId &&
                                          this.props.mapData.runId)
                                        ? "sliderlist sliderlistactive "
                                        : "sliderlist"
                                    }
                                    onMouseDown={() =>
                                      this.onMapSliderClick(
                                        item.rid,
                                        item.dat[0].ts,
                                        item.dat[item.dat.length - 1].ts
                                      )
                                    }
                                    id={item}
                                  >
                                    <DetailViewMapSlider
                                      mapData={item}
                                      onUpdate={this.onUpdate}
                                      selectedRun={item.rid}
                                      basicFilter={this.props.basicFilter}
                                      advancedFilter={this.props.advancedFilter}
                                      unit={mapData.cfg.unt}
                                      disValue={
                                        item.rid ===
                                        (this.state.selectedRun != null
                                          ? this.state.selectedRun
                                          : this.props.mapData.rns &&
                                            this.props.mapData.rns[0].rid)
                                          ? false
                                          : true
                                      }
                                    />
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Tab>

              {/* Time trend tab */}
              <Tab
                eventKey="TREND_TYPE"
                title={
                  <span className="tabIcon">
                    <IconTimeTrend fill="" />
                    <span className="tabtitle"> Time trend</span>
                  </span>
                }
              >
                <Row>
                  <Col md={12}>
                    <FilterDetails
                      basicFilter={this.props.basicFilter}
                      advancedFilter={this.props.advancedFilter}
                    />
                  </Col>
                </Row>
                <SignalSelector
                  mainSignalValues={mainSignalTrendOptions}
                  overlaySignalValues={overlaySignalOptions}
                  selectedMain={this.props.selectedMainTrend}
                  selectedOverlays={this.props.selectedOverlaysTrend}
                  clickMainSignal={this.props.clickMainSignalTrend}
                  clickOverlaySignal={this.props.clickOverlaySignalTrend}
                  chartType={"TREND_TYPE"}
                />
                <Row className="lock-zoom-con">
                  <Col className="" /*className="text-right "*/>
                    <span className="boldText">Zoomed interval:</span>
                    <span
                      /*className={
                      sessionStorage.getItem("zoomIntervalStartDate") ===
                        "null" &&
                      sessionStorage.getItem("zoomIntervalEndDate") === "null"
                        ? "hidden"
                        : "visible zoomed-text"
                    }*/
                      className={"visible zoomed-text"}
                    >
                      <span>{this.props.startZoomInterval}</span>
                      <span className="small-space">to</span>
                      <span>{this.props.endZoomInterval}</span>
                      <span className="small-space">-</span>
                      <span>{this.props.lockedZoomDuration}</span>
                    </span>
                    <span className="zoomed-btns">
                      <button
                        /*className={
                        sessionStorage.getItem("zoomIntervalStartDate") ===
                          "null" &&
                        sessionStorage.getItem("zoomIntervalEndDate") ===
                          "null"
                          ? "hidden"
                          : " btn  btn-sm  btn-link-custom visible"
                      }*/
                        className={" btn  btn-sm  btn-link-custom visible"}
                        onClick={() => {
                          this.props.onLoadMoreGraph("", "", "", true);
                        }}
                        disabled={
                          (sessionStorage.getItem("zoomIntervalStartDate") ===
                            "null" &&
                            sessionStorage.getItem("zoomIntervalEndDate") ===
                              "null") ||
                          this.props.isButtonDisabled
                        }
                        /*id={
                          this.props.isApplyZoomEnabled === true
                            ? "lockzoomclosed"
                            : "lockzoomopen"
                        }*/
                        id={"backzoom"}
                      >
                        Back
                      </button>
                      <button
                        /*className={
                        sessionStorage.getItem("isTrendZoomed") !== "true"
                          ? "hidden"
                          : " btn  btn-sm  btn-link-custom visible"
                      }*/
                        className={" btn  btn-sm  btn-link-custom visible"}
                        onClick={this.props.onLoadMoreGraph}
                        disabled={
                          sessionStorage.getItem("isTrendZoomed") !== "true"
                        }
                        id="resetzoom"
                      >
                        Reset
                      </button>
                    </span>
                  </Col>
                </Row>
                <Row className="lock-zoom-con">
                  <Col className="drag-msg noRightpadding">
                    <FormattedMessage {...messages.clickDragText} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {(this.props.loadingOverlay === true ||
                      this.props.timetrendata === null ||
                      this.props.loadingTimetrend === true) && (
                      <div id="overlay" onclick="off()">
                        <div id="loader">
                          <Loading loadintText="Loading ..." />
                        </div>
                      </div>
                    )}
                    {this.props.timetrendata === null ||
                    this.props.loadingTimetrend === true ? (
                      <Col md={12} className="addMarginTop nodatafound">
                        {/* <Loading /> */}
                      </Col>
                    ) : this.props.timetrendata.length !== 0 ? (
                      this.props.selectedTab === "TREND_TYPE" && (
                        <Fragment>
                          <div id="chartLoader">
                            {/* <Loading /> */}
                            <div id="overlay" onclick="off()">
                              <div id="loader">
                                <Loading loadintText="Loading ..." />
                              </div>
                            </div>
                          </div>
                          <SingleGraph
                            className="chart"
                            data={this.props.timetrendata}
                            selectedMainTrend={this.props.selectedMainTrend}
                            selectedOverlaysTrend={
                              this.props.selectedOverlaysTrend
                            }
                            chartType={"TREND_TYPE"}
                            basicFilter={this.props.basicFilter}
                            advancedFilter={this.props.advancedFilter}
                            onLoadMoreGraph={this.props.onLoadMoreGraph}
                          />
                        </Fragment>
                      )
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">
                          No data found for this time interval !
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Tab>

              {/* Scatter plot tab */}
              <Tab
                eventKey="SCATTER_TYPE"
                title={
                  <span className="tabIcon">
                    <IconScatterPlot />
                    <span className="tabtitle">Scatter plot</span>
                  </span>
                }
              >
                <Row>
                  <Col md={12}>
                    <FilterDetails
                      basicFilter={this.props.basicFilter}
                      advancedFilter={this.props.advancedFilter}
                    />
                  </Col>
                </Row>
                <SignalSelector
                  mainSignalValues={mainSignalScatterOptions}
                  selectedMain={this.props.selectedMainScatter}
                  clickMainSignal={this.props.handleScatterDropdownChange}
                  chartType={"SCATTER_TYPE"}
                  handleScatterplotAvgMaxChange={
                    this.props.handleScatterplotAvgMaxChange
                  }
                  scatterplotAvg={this.props.scatterplotAvg}
                />
                <Row className="lock-zoom-con">
                  {/* <Col className="drag-msg noRightpadding">
                    <FormattedMessage {...messages.clickDragText} />
                  </Col> */}
                  <Col /*className="text-right"*/>
                    <span className="boldText">Zoomed interval:</span>
                    <span
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : "visible"
                      }*/
                      className={"visible zoomed-text"}
                    >
                      <span>{this.props.startZoomInterval}</span>
                      <span className="small-space">to</span>
                      <span>{this.props.endZoomInterval}</span>
                      <span className="small-space">-</span>
                      <span>{this.props.lockedZoomDuration}</span>
                    </span>
                    <button
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : " btn  btn-sm  btn-link-custom visible"
                      }*/
                      className={" btn  btn-sm  btn-link-custom visible"}
                      onClick={this.props.onLoadMoreGraph}
                      disabled={
                        sessionStorage.getItem("isTrendZoomed") !== "true"
                      }
                      id="resetzoom"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
                <Row className="lock-zoom-con">
                  <Col className="drag-msg noRightpadding">
                    <FormattedMessage {...messages.clickDragText} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {this.props.scatterplotdata === null ||
                    this.props.loading === true ||
                    this.props.loadingScatterB2 === true ? (
                      <Col md={12}>
                        <Loading />
                      </Col>
                    ) : this.props.scatterplotdata.length !== 0 ? (
                      this.props.selectedTab === "SCATTER_TYPE" && (
                        <Fragment>
                          <div id="chartLoader">
                            <Loading />
                          </div>
                          <SingleGraph
                            className="chart"
                            data={this.props.scatterplotdata}
                            selectedMainScatter={this.props.selectedMainScatter}
                            chartType={"SCATTER_TYPE"}
                          />
                        </Fragment>
                      )
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">
                          No data found for this time interval !
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Tab>

              {/* Histogram tab */}
              <Tab
                eventKey="HISTOGRAM_TYPE"
                title={
                  <span className="tabIcon">
                    <IconHistogram />
                    <span className="tabtitle">Histogram</span>
                  </span>
                }
              >
                <Row>
                  <Col md={12}>
                    <FilterDetails
                      basicFilter={this.props.basicFilter}
                      advancedFilter={this.props.advancedFilter}
                    />
                  </Col>
                </Row>
                <SignalSelector
                  mainSignalValues={xAxisSignalHistogramOptions}
                  yaxisSignalValues={yAxisSignalHistogramOptions}
                  zaxisSignalValues={zAxisSignalHistogramOptions}
                  selectedMain={this.props.selectedMainScatter}
                  clickMainSignal={this.props.clickMainSignalScatter}
                  chartType={"HISTOGRAM_TYPE"}
                  updateHistogram={this.updateHistogram}
                  handleHistogramDropdownChange={
                    this.props.handleHistogramDropdownChange
                  }
                  getHistogramDataBasedonUserSelection={
                    this.props.getHistogramDataBasedonUserSelection
                  }
                  selectedSignalsHistogram={this.props.selectedSignalsHistogram}
                />
                <Row className="lock-zoom-con">
                  <Col /*className="text-right"*/>
                    <span className="boldText">Zoomed interval:</span>
                    <span
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : "visible"
                      }*/
                      className={"visible zoomed-text"}
                    >
                      <span>{this.props.startZoomInterval}</span>
                      <span className="small-space">to</span>
                      <span>{this.props.endZoomInterval}</span>
                      <span className="small-space">-</span>
                      <span>{this.props.lockedZoomDuration}</span>
                    </span>
                    <button
                      /*className={
                        this.props.isApplyZoomEnabled !== true
                          ? "hidden"
                          : " btn  btn-sm  btn-link-custom visible"
                      }*/
                      className={" btn  btn-sm  btn-link-custom visible"}
                      onClick={this.props.onLoadMoreGraph}
                      disabled={
                        sessionStorage.getItem("isTrendZoomed") !== "true"
                      }
                      id="resetzoom"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
                <Row className="lock-zoom-con">
                  <Col className="drag-msg noRightpadding">
                    <FormattedMessage {...messages.clickDragText} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {this.props.histogramdata === null ||
                    this.props.loading === true ? (
                      <Col md={12}>
                        <Loading />
                      </Col>
                    ) : this.props.histogramdata.dat.length !== 0 ? (
                      this.props.selectedTab === "HISTOGRAM_TYPE" && (
                        <Fragment>
                          <div id="chartLoader">
                            <Loading />
                          </div>
                          <SingleGraph
                            className="chart"
                            ref={this.child}
                            data={this.props.histogramdata}
                            chartType={"HISTOGRAM_TYPE"}
                          />
                        </Fragment>
                      )
                    ) : (
                      <Col md={12} className="addMarginTop nodatafound">
                        <div> Sorry...</div>
                        <div className="addMarginTop">
                          No data found for this time interval !
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

SingleTrendPage.defaultProps = {
  selectedTrain: undefined,
  startTime: undefined,
  endTime: undefined,
  startTimeDate: undefined,
  endTimeDate: undefined,
  graphsData: undefined,
};

SingleTrendPage.propTypes = {
  selectedTrain: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  startTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  graphsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withRouter(SingleTrendPage);
