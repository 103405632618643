import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import "./styles.scss";

export default class DetailViewMapLegend extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    console.log("basicFilter", this.props.basicFilter);
    return (
      <div className="maplegendcontainer">
        <Accordion atomic={false}>
          <AccordionItem title={"Legend"}>
            {(() => {
              switch (this.props.activeSignal) {
                case "Slip":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div> 0.0-0.54km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>1.08-1.62km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>2.16-2.70km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>3.24-3.78km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>4.32-4.86km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>5.40-5.94km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>6.48-7.02km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>7.56km/h
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div> 0.54-1.08km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>1.62-2.16km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>2.70-3.24km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>3.78-4.32km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>4.86-5.40km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>5.94-6.48km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>7.02-7.56km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Effort":
                  if (
                    this.props.basicFilter !== undefined &&
                    this.props.basicFilter.bogie === 3
                  ) {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-20kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>40-60kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>80-100kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>120-140kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>160-180kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>200-220kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>240-260kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>280kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>20-40kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>60-80kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>100-120kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>140-160kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>180-200kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>220-240kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>260-280kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  } else {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-10kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>20-30kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>40-50kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>60-70kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>80-90kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>100-110kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>120-130kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>140kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>10-20kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>30-40kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>50-60kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>70-80kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>90-100kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>110-120kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>130-140kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                case "Effort Mech. Brake":
                  if (
                    this.props.basicFilter !== undefined &&
                    this.props.basicFilter.bogie === 3
                  ) {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-5.4kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>10.8-16.20kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>21.6-27.0kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>32.40-37.80kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>43.2-48.6kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>54.0-59.40kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>64.80-70.2kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>75.60kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>5.4-10.8kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>16.20-21.6kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>27.0-32.40kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>37.80-43.2kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>48.6-54.0kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>59.40-64.80kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>70.2-75.60kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  } else {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-2.7kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>5.4-8.10kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>10.8-13.5kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>16.20-18.90kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>21.6-24.3kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>27.0-29.70kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>32.40-35.1kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>37.80kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>2.7-5.4kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>8.10-10.8kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>13.5-16.20kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>18.90-21.6kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>24.3-27.0kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>29.70-32.40kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>35.1-37.80kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                case "Speed at Wheel":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0-14km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>28-42km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>56-70km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>84-98km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>112-126km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>140-154km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>168-182km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>196km/h
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>14-28km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>42-56km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>70-84km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>98-112km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>126-140km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>154-168km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>182-196km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Speed Driving Trailer":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0-14km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>28-42km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>56-70km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>84-98km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>112-126km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>140-154km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>168-182km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>196km/h
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>14-28km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>42-56km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>70-84km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>98-112km/h
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>126-140km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>154-168km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>182-196km/h
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Effort Reference":
                  if (
                    this.props.basicFilter !== undefined &&
                    this.props.basicFilter.bogie === 3
                  ) {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-20kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>40-60kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>80-100kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>120-140kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>160-180kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>200-220kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>240-260kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>280kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>20-40kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>60-80kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>100-120kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>140-160kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>180-200kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>220-240kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>260-280kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  } else {
                    return (
                      <div className="legend">
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color1"></div>0.0-10kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color3"></div>20-30kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color5"></div>40-50kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color7"></div>60-70kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color9"></div>80-90kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color11"></div>100-110kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color13"></div>120-130kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color15"></div>>140kN
                          </Col>
                        </Row>
                        <Row>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color2"></div>10-20kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color4"></div>30-40kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color6"></div>50-60kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color8"></div>70-80kN
                          </Col>
                          <Col className="noLeftpadding noRightpadding">
                            <div id="color10"></div>90-100kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color12"></div>110-120kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color14"></div>130-140kN
                          </Col>

                          <Col className="noLeftpadding noRightpadding">
                            <div id="color16"></div> No data
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                case "Power Slip":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0.0-10kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>20-30kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>40-50kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>60-70kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>80-90kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>100-110kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>120-130kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>140kW
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>10-20kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>30-40kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>50-60kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>70-80kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>90-100kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>110-120kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>130-140kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Power Mechanical Brake":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0.0-34kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>68-102kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>136-170kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>204-238kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>272-306kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>340-374kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>408-442kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>476kW
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>34-68kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>102-136kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>170-204kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>238-272kW
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>306-340kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>374-408kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>442-476kW
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Pressure Brake Cyl.":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0.0-0.34bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>0.68-1.02bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>1.36-1.70bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>2.04-2.38bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>2.72-3.06bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>3.40-3.74bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>4.08-4.42bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>4.76 bar
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>0.34-0.68bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>1.02-1.36bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>1.70-2.04bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>2.38-2.72bar
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>3.06-3.40bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>3.74-4.08bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>4.42-4.76bar
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                case "Torsional Osc. Ampl.":
                  return (
                    <div className="legend">
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color1"></div>0.0-2.7mHz
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color3"></div>5.4-8.10mHz
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color5"></div>10.8-13.5mHz
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color7"></div>16.20-18.90mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color9"></div>21.6-24.3mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color11"></div>27.0-29.70mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color13"></div>32.40-35.1mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color15"></div>>37.80mHz
                        </Col>
                      </Row>
                      <Row>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color2"></div>2.7-5.4mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color4"></div>8.10-10.8mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color6"></div>13.5-16.20mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color8"></div>18.90-21.6mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color10"></div>24.3-27.0mHz
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color12"></div>29.70-32.40mHz
                        </Col>
                        <Col className="noLeftpadding noRightpadding">
                          <div id="color14"></div>35.1-37.80mHz
                        </Col>

                        <Col className="noLeftpadding noRightpadding">
                          <div id="color16"></div> No data
                        </Col>
                      </Row>
                    </div>
                  );
                default:
                  return <div className="legend">No data available</div>;
              }
            })()}
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
}
