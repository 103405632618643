import { takeLatest, call, put } from "redux-saga/effects";
import api from "../../utils/api";
import {
  fetchTrainList,
  fetchTrainListSucess,
  fetchTrainListFailure,
  fetchMapData,
  fetchMapDataSucess,
  fetchMapDataFailure
} from "./actions";
import { FETCH_MAP_DATA, FETCH_TRAIN_LIST } from "./constants";
import { handleError } from "../../utils/api-utils";

export function* fetachTrainSaga() {
  yield takeLatest(FETCH_TRAIN_LIST, fetachingTrainSaga);
}
export default function* fetachingTrainSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchTrainListData, startDate, endDate);
    const TrainList = response.dat;
    console.log("dashboard data", TrainList);
    yield put(fetchTrainListSucess(TrainList));
  } catch (error) {
    // yield put(fetchTrainListFailure(error));
    yield put(handleError(error, fetchTrainListFailure));
  }
}

export function* fetachMapDataSaga() {
  yield takeLatest(FETCH_MAP_DATA, fetachingMapDataSaga);
}
export function* fetachingMapDataSaga(action) {
  try {
    const { startDate, endDate } = action.payload;
    const response = yield call(api.fetchMapDataList, startDate, endDate);
    console.log("gps data", response.dc);
    const MapData = response.dc;

    // dispatch a success action to the store with the new dog
    yield put(fetchMapDataSucess(MapData));
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put(handleError(error, fetchMapDataFailure));
  }
}
