import React from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { Col, Row, Button } from "react-bootstrap";
import Loading from "../../components/Loading";

export default class LoginForm extends React.Component {
  render() {
    return (
      <Row className="loginbackground">
        <Col md={6}>
          <div className="abblogoinlogin">
            <img
              src={require("../../assets/images/abb-logo.svg")}
              alt="ABB Logo"
            />
          </div>
          <div className="wwotext">Wheel Wear Monitoring</div>
        </Col>
        <Col md={6} className="loginfromcontainer">
          {this.props.loginResponce &&
          this.props.loginResponce.userValid === false ? (
            <div className="error-message">
              {this.props.loginResponce.responseMessage}
            </div>
          ) : (
            ""
          )}
          <Form className="loginform">
            {/* <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Role</Form.Label>
              <Form.Control as="select">
                <option>Engineer</option>
                <option>Manager</option>
              </Form.Control>
            </Form.Group> */}
            <div className="userpasscontainer">
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  onChange={this.props.handleInputChange}
                  autoComplete="off"
                  value={this.props.formValues.username}
                  autoFocus={true}
                  onKeyPress={this.props.handleKeyPress}
                />
                <div className="error-message">{this.props.userNameError}</div>
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  onChange={this.props.handleInputChange}
                  autoComplete="off"
                  value={this.props.formValues.password}
                  onKeyPress={this.props.handleKeyPress}
                />
                <div className="error-message">{this.props.passwordError}</div>
              </Form.Group>
            </div>
            {/* <Form.Group controlId="rememberme">
              <Form.Check type="checkbox" label="Remember password" />
            </Form.Group> */}

            <Button
              className="smallButton"
              variant="primary"
              size="lg"
              onClick={this.props.handleLoginClick}
            >
              {this.props.loading ? "Validating..." : " Log in"}
            </Button>
          </Form>
        </Col>
        <Col md={12} className="rightstext">
          All rights reserved {new Date().getFullYear()}
        </Col>
      </Row>
    );
  }
}
