import Highcharts from "highcharts/highstock";
import exportChart from "../../assets/images/exportChart.svg";
import debug from "debug";
import Moment from "moment";

export const colors = [
  "#5abfae", //Teal 40
  "#ED5739", //  Orange 50
  "#005CA3", //Blue 70
  "#d98f45", //Gold 40
  "#1e6119", //Green 70
  "#a30005", // Red 80
  "#686868", //Grey 60
  "#753B00", //Gold 70
  "#E64997", // Magenta 50
  "#b5aa0d", //Yellow 40
];

export function checkChatValues(chartValues) {
  let isVisible = "";
  for (let i = 0; i < chartValues.length - 1; i++) {
    if (
      chartValues[i].config.label.indexOf("B1") !== -1 &&
      chartValues[i + 1].config.label.indexOf("B2") !== -1
    ) {
      isVisible = false;
    } else {
      isVisible = true;
    }
  }
  return isVisible;
}

export function getSingleTrendData(
  selectedOverlays,
  chart,
  selectedMain,
  filter
) {
  const ranges = [];
  const average = [];
  const configs = [];
  const colors = [
    "#5abfae", //Teal 40
    "#ED5739", //  Orange 50
    "#005CA3", //Blue 70
    "#d98f45", //Gold 40
    "#1e6119", //Green 70
    "#a30005", // Red 80
    "#686868", //Grey 60
    "#753B00", //Gold 70
    "#E64997", // Magenta 50
    "#b5aa0d", //Yellow 40
  ];
  let rangedata = [],
    averages = [],
    xaxisvalue = [],
    seriesValues = [],
    xAxis,
    yAxis = [];

  let chartValues = [];
  /*chartValues.push(...chart.filter(item => item.config.label === selectedMain));
  selectedOverlays &&
    selectedOverlays.map(value =>
      chartValues.push(...chart.filter(item => item.config.label === value))
    );*/
  console.log(
    "Filter: ",
    filter.operation,
    " Chart: ",
    chart,
    " SelectedMain: ",
    selectedMain
  );
  chartValues = chart;

  for (let j = 0; j < chartValues.length; j += 1) {
    rangedata = [];
    averages = [];
    xaxisvalue = [];

    for (let i = 0; i < chartValues[j].data.length; i += 1) {
      const item = chartValues[j].data[i];

      //if (!item.value) {
      let formatedDate = Moment.utc(item.ts).format("HH:mm:ss");
      xaxisvalue.push([formatedDate]);
      averages.push([formatedDate, item.avg]);
      rangedata.push([formatedDate, item.min, item.max]);
      // } else {
      //averages.push([item.timeStamp, item.value]);
      // }
    }

    seriesValues.push(
      {
        name: chartValues[j].config.label,
        type: "line",
        color: colors[j],
        data: averages,
        tooltip: {
          pointFormatter: function () {
            var point = this;
            if (filter.bogie === 3) {
              const seriesTwo = point.series.chart.series[2];
              return (
                `<span style="color:${point.color}">\u25CF</span> ` +
                `<b>${point.series.name}</b>: <b>${point.y} ${chartValues[j].config.unit}</b><br/>` +
                `<span style="color:${seriesTwo.color}">\u25CF</span>` +
                `<b>${seriesTwo.name}</b>: <b>${
                  seriesTwo.data[point.index].y
                } ${chartValues[j].config.unit}</b>`
              );
            } else {
              return (
                '<span style="color:' +
                point.color +
                '">\u25CF</span> ' +
                point.series.name +
                ": <b>" +
                point.y +
                " " +
                chartValues[j].config.unit +
                "</b><br/>"
              );
            }
          },
        },
        boostThreshold: 0,
        turboThreshold: Number.MAX_VALUE,
      },
      {
        name: chartValues[j].config.label,
        type: "arearange",
        data: rangedata,
        lineWidth: 0,
        linkedTo: ":previous",
        color: colors[j],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        // tooltip: {
        //   //valueSuffix: " " + chartValues[j].config.unit,
        // },
        yAxis: j,
        boostThreshold: 0,
        turboThreshold: Number.MAX_VALUE,
      }
    );

    yAxis.push({
      opposite: j !== 0,
      min:
        chartValues[j].config.label.indexOf("Slip") !== -1
          ? -15
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("Speed") !== -1
          ? -20
          : null,
      max:
        chartValues[j].config.label.indexOf("Slip") !== -1
          ? 15
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("Speed") !== -1
          ? 225
          : null,
      tickInterval:
        chartValues[j].config.label.indexOf("EffortBrakingMechanical") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? 27
          : undefined,
      title: {
        enabled: false,
        text: `${chartValues[j].config.label} ${chartValues[j].config.unit}`,
        style: {
          color: j !== 0 ? colors[j] : colors[0],
        },
      },
      labels: {
        format: `{value}`, //`{value} ${chartValues[j].config.unit}`,
        style: {
          color: j !== 0 ? colors[j] : colors[0],
        },
      },
      id: `${chartValues[j].config.label}`,
      visible:
        chartValues[j].config.label.indexOf("B2") !== -1 &&
        checkChatValues(chartValues) === false
          ? false
          : true,
    });

    xAxis = [
      {
        categories: xaxisvalue,
        crosshair: true,
        gridLineWidth: 1,
        gridLineColor: "#e9e9e9",
        tickInterval: 10,
        crosshair: {
          width: 1,
          color: "#0b73c2",
        },
        labels: {
          // formatter: function() {
          //   return Highcharts.dateFormat("%H:%M:%S ", 1 * 1000); // milliseconds not seconds
          // }
        },
      },
    ];
  }

  return {
    xAxis,
    yAxis,
    series: seriesValues,
  };
}

export function getTrendChart(selectedOverlays, chart, selectedMain, filter) {
  const data = getSingleTrendData(
    selectedOverlays,
    chart,
    selectedMain,
    filter
  );
  let options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      // zoomType: "x",
      //panning: true,
      //panKey: "shift",
      //marginBottom: 60,
      borderColor: "#dbdbdb",
      borderWidth: 1,
      height: 300,
      marginBottom: 80,
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    title: {
      text: null,
    },
    // subtitle: {
    //   text: ""
    // },
    xAxis: data.xAxis,
    yAxis: data.yAxis,
    tooltip: {
      //shared: true
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
      },
      line: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        gapSize: 5,
      },
      arearange: {
        cursor: "pointer",
      },
      series: {
        enableMouseTracking: false,
      },
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      padding: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      //layout: "horizontal",
      x: 0,
      y: 0,
      //floating: true,
      symbolPadding: 5,
      symbolWidth: 10,
      itemStyle: {
        color: "#000000",
        fontWeight: "normal",
        fontSize: "12px",
      },
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        "rgba(255,255,255,0.25)",
    },
    series: data.series,
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 3,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getScatterSingleSignal(data, trendsParam, props, lang) {
  // if (!data || !props || !props.selectedScatter) return null;
  // debug("dev")("getScatterSingleSignal", data, trendsParam, props);
  const current = [];
  let config;
  let j = 1;
  let color = 0;
  data.forEach((c) => {
    // if (c.config.code.toUpperCase() === props.selectedScatter.toUpperCase()) {
    //   const color = c.color ? c.color : Highcharts.getOptions().colors[j];
    //   current.push({
    //     name: c.series[0].name,
    //     data: c.series[0].data,
    //     color,
    //     turboThreshold: 0,
    //     marker: {
    //       fillColor: color,
    //       radius: 2
    //     }
    //   });
    //   ({ config } = c);
    //   j += 1;
    // }

    current.push({
      name: c.config.bnm,
      color: colors[color],
      data: c.data,
      boostThreshold: 10000,
      turboThreshold: Number.MAX_VALUE,
      marker: {
        radius: 3,
      },
    });
    j += 1;
    color += 1;
  });

  const options = {
    chart: {
      name: "scatterData",
      type: "scatter",
      marginTop: 70,
      enableMouseTracking: true,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      //zoomType: "xy",
      shadow: false,
      resetZoomButton: {
        position: {
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 5,
        },
        relativeTo: "chart",
      },
      borderColor: "#dbdbdb",
      borderWidth: 1,
      height: 300,
      marginBottom: 50,
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    //trends: "Slip vs Speed",
    title: {
      text: null,
    },
    subtitle: {
      text: `${data[0].documentType} <span class="sampleinterval">(${data[0].resampleInterval} sampling)</span>`,
      style: {
        fontSize: "11px",
        marginBottom: "8px",
      },
    },
    xAxis: {
      className: "scatter-x-axis",
      tickInterval: 5,
      //max: 235,
      title: {
        enabled: false,
        text: `<b>${data[0].config.xsn}</b>`,
        align: "middle",
      },
      startOnTick: true,
      endOnTick: false,
      showLastLabel: true,
      labels: {
        y: 25,
      },
    },
    yAxis: {
      className: "scatter-y-axis",
      title: {
        enabled: false,
        text: `<b>${data[0].config.ysn}</b>`,
        align: "middle",
        // rotation: 0,
        // y: -15,
        // x: -5,
        // align: "high"
        // offset: 0
      },
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
      //x: 150,
      y: 20,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      borderWidth: 0,
      itemStyle: {
        color: "#000000",
        fontWeight: "normal",
        fontSize: "12px",
      },
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
        // states: {
        //   inactive: {
        //     opacity: 0
        //   }
        // },
        // animation: {
        //   duration: 2000
        // }
      },
      scatter: {
        // states: {
        //   inactive: {
        //     opacity: 1
        //   }
        // },
        /*tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: `${data[0].config.xSignalName}: <b>{point.x} ${
            data[0].config.xSignalUnit
          }</b><br />${data[0].config.ySignalName}: <b>{point.y}${
            data[0].config.ySignalUnit
          } </b><br />${"Date/Time"}: <b>{point.timeStamp}</b>`
        }*/
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.series.name}</b><br>
      ${data[0].config.xsn}: <b>${this.point.x} ${
          data[0].config.xsu
        }</b><br />${data[0].config.ysn}: <b>${this.point.y} ${
          data[0].config.ysu
        } </b><br />${"Date/Time"}:<b> ${Moment.utc(this.point.ts).format(
          "DD.MM.YYYY HH:mm:ss"
        )}</b>`;
      },
    },
    series: current,
    /* series: [
      {
        name: "Bogie1",
        //color: 'rgba(223, 83, 83, .5)',
        data: data[1].data
      }
    ],*/

    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 3,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}

// histogram
export function getHistogramSingleSignal(seriesData) {
  // if (!chart || !props) return null;
  //debug('dev')('Histogram:', chart, props);
  console.log("Histogram Func()");
  let title;
  let unit;
  const options = {
    chart: {
      type: "heatmap",
      marginTop: 57,
      // events: {
      //   load: function() {
      //     document.getElementById("chartLoader").style.display = "none";
      //   }
      // },
      //inverted: true
      panning: true,
      panKey: "shift",
      //marginBottom: 60,
      borderColor: "#dbdbdb",
      borderWidth: 1,
      height: 300,
      marginBottom: 50,
      // events: {
      //   load: function() {
      //     document.getElementById("chartLoader").style.display = "none";
      //   }
      // }
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text: ` ${seriesData.doc}`,
    },
    xAxis: [
      {
        // title: {
        //   text: `${seriesData.signalConfig.xSignalName} [${
        //     seriesData.signalConfig.xSignalUnit
        //   }]`
        // },
        startOnTick: true,
        minRange: 20,
        gridZIndex: 0,
        gridLineWidth: 0,
        gridLineColor: "#dbdbdb",
        tickInterval: 10,
      },
    ],
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    yAxis: {
      // min: 111,
      // max: 161,
      title: {
        // text: ` ${seriesData.signalConfig.ySignalName} [${
        //   seriesData.signalConfig.ySignalUnit
        // }]`
        text: null,
      },
      minPadding: 0,
      maxPadding: 0,
      startOnTick: true,
      endOnTick: false,
      //tickPositions: [0, 6, 12, 18, 24],
      minRange: 20,
      // tickInterval: 10,
      tickWidth: 1,
      gridZIndex: 0,
      gridLineWidth: 0,
      gridLineColor: "#dbdbdb",
      reversed: false,
    },
    colorAxis: {
      stops: [
        [0, "#ffffff"],
        [0.1, "#bfedbb"],
        [0.2, "#98d694"],
        [0.3, "#0ca919"],
        [0.4, "#d6c918"],
        [0.5, "#ffd800"],
        [0.6, "#ff7300"],
        [0.7, "#ff4754"],
        [0.8, "#f03040"],
        [0.9, "#e12e2e"],
        [1, "#cc0815"],
      ],
      reversed: false,
      // min: -6,
      //  max: 5,
      // minColor: "#dadada",
      // maxColor: "#c4463a",
      startOnTick: false,
      endOnTick: true,
      labels: false,
      // labels: {
      //   format: `{value} [s]`
      // }
    },
    series: [
      {
        // boostThreshold: 11653,
        //colsize: 10,
        //borderIndex: 0,
        //borderColor: "#e5e5e5",
        //borderWidth: 0,
        colsize: seriesData.bin,
        rowsize: seriesData.bin,
        turboThreshold: Number.MAX_VALUE,
        nullColor: "#ffffff",
        data: seriesData.dat,
        states: {
          hover: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
          color: "#000000",
        },
      },
    ],
    /*tooltip: {
      formatter: function() {
        return `${seriesData.signalConfig.xSignalName}: <b>${this.point.x} ${
          seriesData.signalConfig.xSignalUnit
        } </b><br />
          ${seriesData.signalConfig.ySignalName}: <b> ${this.point.y} ${
          seriesData.signalConfig.ySignalUnit
        }</b><br />${"Value"}: <b>${this.point.value} ${"s"}</b>`;
      }
    },*/
    tooltip: {
      formatter: function () {
        return `${seriesData.cfg.xsn}: <b>${this.point.x} ${
          seriesData.cfg.xsu
        }</b><br />
      ${seriesData.cfg.ysn}: <b> ${this.point.y} ${
          seriesData.cfg.ysu
        }</b><br />${"Occurrence"}: <b>${this.point.nlv} ${"s"}</b>`;
      },
    },
    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "top",
      y: 40,
      symbolWidth: 12,
      symbolHeight: 190,
    },
    plotOptions: {
      heatmap: {
        cursor: "zoom-in",
      },
      series: {
        stickyTracking: false,
        animation: false,
      },
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 3,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}
