import React, { Component } from "react";
import "./styles.scss";

const FilterDetailsTrainview = ({ basicFilter, advancedFilter }) => {
  let strOperation = "Operation mode: ";
  return (
    <div className="filtersTitleTrainview">
      <span className="basicfilter">{"Basic filter"}</span>

      <span className="weathercondition">
        <img
          src={require("../../../assets/images/wet.svg")}
          className={"weatherconditionicon"}
        />
        {"Weather condition: " + basicFilter.weather}
      </span>

      <span className="operationmode">
        <img
          src={require("../../../assets/images/magnet.svg")}
          className={"operationmodeicon"}
        />
        {basicFilter.operation &&
          basicFilter.operation.sort().map((item) => {
            if (item === 1) {
              strOperation += "Pulling, cabin 1 in front; ";
            }
            if (item === 2) {
              strOperation += "Pulling, cabin 2 in front; ";
            }
            if (item === 3) {
              strOperation += "Pushing, cabin 1 in front; ";
            }
            if (item === 4) {
              strOperation += "Pushing, cabin 2 in front; ";
            }
            if (item === 5) {
              strOperation += "Standstill; ";
            }
          })}
        {basicFilter.operation && basicFilter.operation.length === 6
          ? (strOperation = "Operation mode: All ")
          : strOperation}
      </span>

      <span className="bogie">
        <img
          src={require("../../../assets/images/generator.svg")}
          className={"bogieicon"}
        />
        {basicFilter.bogie &&
         basicFilter.bogie === 1
         ? ("Bogie: 1")
         : basicFilter.bogie === 2
         ? ("Bogie: 2")
         : ("Bogie: All")}
      </span>
    </div>
  );
};

export default FilterDetailsTrainview;
