let API = null;

if (process.env.REACT_APP_WWO_API === "development") {
  API = {
    resourceUrl: "https://wwomonitoring-api-dev.azurewebsites.net",
    baseUrl: "https://wwomonitoring-api-dev.azurewebsites.net"
  };
} else if (process.env.REACT_APP_WWO_API === "production") {
  API = {
    resourceUrl: "https://wwomonitoring-api.azurewebsites.net",
    baseUrl: "https://wwomonitoring-api.azurewebsites.net"
  };
} else if (process.env.REACT_APP_WWO_API === "stage") {
  API = {
    resourceUrl: "https://wwomonitoring-api-staging.azurewebsites.net",
    baseUrl: "https://wwomonitoring-api-staging.azurewebsites.net"
  };
} else {
  API = {
    resourceUrl: "https://wwomonitoring-api.azurewebsites.net",
    baseUrl: "https://wwomonitoring-api.azurewebsites.net"
  };
}

const exportedAPI = API;
export default exportedAPI;