import React, { Fragment, useState } from "react";
import ReactWindow from "reactjs-windows";
import "reactjs-windows/dist/index.css";
import { Col, Button } from "react-bootstrap";
import "./styles.scss";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

class Help extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      openWin1: false,
    };
  }

  handleClick = (flag) => {
    this.setState({ openWin1: flag });
  };

  render() {
    return (
      <Fragment>
        <div
          class={this.state.openWin1 === false ? "helpbtn visible" : "hidden"}
          onClick={() => this.handleClick(true)}
        >
          <a href="#">
            <img
              src={require("../../assets/images/abb_help.svg")}
              className={""}
              alt="Help"
            />
            &nbsp;Help
          </a>
        </div>
        {this.state.openWin1 && (
          <ReactWindow
            title="Help and tutorial"
            onClose={() => this.handleClick(false)}
          >
            <Accordion atomic={true}>
              <AccordionItem title={"Dashboard"}>
                <div className="helpcontent">
                  <div className="info-text">
                    This page is currently under the development and will be
                    released soon.
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem title={"Train view"}>
                <div className="helpcontent">
                  <div className="info-text">
                    This page is currently under the development and will be
                    released soon.
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem title={"Details view"}>
                <div className="helpcontent">
                  <div className="info-text">
                    This page is currently under the development and will be
                    released soon.
                  </div>
                </div>
              </AccordionItem>
            </Accordion>
          </ReactWindow>
        )}
      </Fragment>
    );
  }
}

export default Help;
