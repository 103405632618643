export const FETCH_MAP_DATA = "FETCH_MAP_DATA";
export const FETCH_MAP_DATA_SUCCESS = "FETCH_MAP_DATA_SUCCESS";
export const FETCH_MAP_DATA_FAILURE = "FETCH_MAP_DATA_FAILURE";

export const FETCH_TRAIN_LIST = "FETCH_TRAIN_LIST";
export const FETCH_TRAIN_LIST_SUCCESS = "FETCH_TRAIN_LIST_SUCCESS";
export const FETCH_TRAIN_LIST_FAILURE = "FETCH_TRAIN_LIST_FAILURE";

export const SET_CURRENT_TRAIN = "SET_CURRENT_TRAIN";
export const SET_DATE = "SET_DATE";
