import { fromJS } from "immutable";
import Moment from "moment";
import {
  FETCH_MAP_DATA,
  FETCH_MAP_DATA_SUCCESS,
  FETCH_MAP_DATA_FAILURE,
  FETCH_TRAIN_LIST,
  FETCH_TRAIN_LIST_SUCCESS,
  FETCH_TRAIN_LIST_FAILURE,
  SET_CURRENT_TRAIN,
  SET_DATE,
} from "./constants";

const initialState = fromJS({
  loading: null,
  loadingMap: false,
  startDate: new Date(2022, 2, 1, 0, 0, 0), ///new Date(Moment().subtract(10, "days").startOf("day")),
  endDate: new Date(2022, 2, 10, 23, 59, 59), //new Date(Moment().subtract(1, "days").endOf("day")),
  selectedTrain: "Re 460 010-2",
  mapData: null,
  fleetData: null,
  error: null,
});

export function reducerDashboard(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAP_DATA:
      return state.set("loadingMap", true).set("error", null);
    case FETCH_MAP_DATA_SUCCESS:
      return state.set("loadingMap", false).set("mapData", action.Mapdata);
    case FETCH_MAP_DATA_FAILURE:
      return state
        .set("loadingMap", false)
        .set("mapData", null)
        .set("error", action.error);

    case FETCH_TRAIN_LIST:
      return state.set("loading", true).set("error", null);
    case FETCH_TRAIN_LIST_SUCCESS:
      return state.set("loading", false).set("fleetData", action.TrainList);
    case FETCH_TRAIN_LIST_FAILURE:
      return state
        .set("loading", false)
        .set("fleetData", null)
        .set("error", action.error);

    case SET_CURRENT_TRAIN:
      return state.set("selectedTrain", action.trainName);

    case SET_DATE:
      return state
        .set("startDate", action.payload.startDate)
        .set("endDate", action.payload.endDate);

    default:
      return state;
  }
}
