import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { IconPowertrain } from "../Icons";
import "./styles.scss";

const TrainList = ({ onTrainSelect, trainno, trainList }) => {
  return (
    <div>
      <div className="trainlisttitle">Train list</div>
      <div>
        {trainList !== null
          ? trainList.map((item, index) => (
              <div
                variant=""
                className={
                  //item.locomotiveName === trainno
                  item.td == "0.0 km"
                    ? "trainlistcontainer disabledcontent"
                    : item.nm === trainno
                    ? "trainlistcontainer trainlistactive"
                    : "trainlistcontainer"
                }
                onClick={onTrainSelect}
                id={item.nm}
              >
                <span className="trainlisticon">
                  <img src={require("../../assets/images/subway.svg")} />
                </span>{" "}
                {item.nm}
              </div>
            ))
          : " Loading ..."}
      </div>
    </div>
  );
};

export const BasicFilter = ({
  onChange,
  filter,
  clearBasicFilter,
  selectedTab,
}) => {
  return (
    <div>
      <div className="basicfiltertitle">
        <Row>
          <Col md={7} sm={6} xs={6} className="noLeftpadding">
            Basic filter
          </Col>
          <Col md={5} sm={6} xs={6} className="clrbtn">
            <Button className="smallbtn" onClick={clearBasicFilter}>
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <div className="basicfilteraccordion">
        <Accordion atomic={false}>
          <AccordionItem
            title={
              <span>
                <img
                  src={require("../../assets/images/wet.svg")}
                  className="accordiontitleicon"
                />
                Weather conditions
                {/*/
                <span className="accordiontitlevalue"> {filter.weather}</span>*/}
              </span>
            }
          >
            <Form.Check
              custom
              type="radio"
              label="Wet"
              name="weather"
              id="weatherconditionwet"
              value="Wet"
              onChange={onChange}
              className={"elements"}
              checked={filter.weather === "Wet" ? true : false}
              disabled
            />
            <Form.Check
              custom
              type="radio"
              label="Dry"
              name="weather"
              id="weatherconditiondry"
              value="Dry"
              onChange={onChange}
              className={"elements"}
              checked={filter.weather === "Dry" ? true : false}
              disabled
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="weather"
              id="weatherconditiondry"
              value="All"
              onChange={onChange}
              className={"elements"}
              checked={filter.weather === "All" ? true : false}
              disabled
            />
          </AccordionItem>

          <AccordionItem
            title={
              <span>
                <img
                  src={require("../../assets/images/magnet.svg")}
                  className="accordiontitleicon"
                />
                Operation mode
                <span className="accordiontitlevalue">
                  {/* ({filter.operation && filter.operation.length}) */}
                </span>
              </span>
            }
          >
            <Form.Check
              custom
              type="checkbox"
              label="Pulling, cabin 1 in front"
              name="operation"
              id="operationmode1"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.operation && filter.operation.includes(1)}
              disabled={selectedTab === "HISTOGRAM_TYPE" ? true : false}
            />
            <Form.Check
              custom
              type="checkbox"
              label="Pulling, cabin 2 in front"
              name="operation"
              id="operationmode2"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.operation && filter.operation.includes(2)}
              disabled={selectedTab === "HISTOGRAM_TYPE" ? true : false}
            />
            <Form.Check
              custom
              type="checkbox"
              label="Pushing, cabin 1 in front"
              name="operation"
              id="operationmode3"
              value="3"
              onChange={onChange}
              className={"elements"}
              checked={filter.operation && filter.operation.includes(3)}
              disabled={selectedTab === "HISTOGRAM_TYPE" ? true : false}
            />
            <Form.Check
              custom
              type="checkbox"
              label="Pushing, cabin 2 in front"
              name="operation"
              id="operationmode4"
              value="4"
              onChange={onChange}
              className={"elements"}
              checked={filter.operation && filter.operation.includes(4)}
              disabled={selectedTab === "HISTOGRAM_TYPE" ? true : false}
            />
            <Form.Check
              custom
              type="checkbox"
              label="Standstill"
              name="operation"
              id="operationmode5"
              value="5"
              onChange={onChange}
              className={"elements"}
              checked={filter.operation && filter.operation.includes(5)}
              disabled={selectedTab === "HISTOGRAM_TYPE" ? true : false}
            />
          </AccordionItem>

          <AccordionItem
            title={
              <span>
                <img
                  src={require("../../assets/images/generator.svg")}
                  className="accordiontitleicon"
                />
                Bogie
              </span>
            }
          >
            <Form.Check
              custom
              type="radio"
              label="Bogie 1"
              name="bogie"
              id="bogie1"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.bogie === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Bogie 2"
              name="bogie"
              id="bogie2"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.bogie === 2 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="bogie"
              id="bogieall"
              value="3"
              onChange={onChange}
              className={"elements"}
              checked={filter.bogie === 3 ? true : false}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
export const AdvanceFilter = ({ onChange, filter, clearAdvancedFilter }) => {
  return (
    <div>
      <div className="advancefiltertitle">
        <Row>
          <Col md={7} sm={6} xs={6} className="noLeftpadding">
            Advanced filter
          </Col>
          <Col md={5} sm={6} xs={6} className="clrbtn">
            <Button className="smallbtn" onClick={clearAdvancedFilter}>
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      <div className="advancefilteraccordion">
        <Accordion atomic={false}>
          {/* <AccordionItem title="Routes">
            <div className="filterroutelist">
              <Form.Check
                custom
                type="checkbox"
                label="ZH-OTL"
                name="routes1"
                id="routes1"
                value="ZH-OTL"
                onChange={onChange}
                className={"elements"}
                disabled
              />
              <Form.Check
                custom
                type="checkbox"
                label="OLT-BE"
                name="routes2"
                id="routes2"
                value="OLT-BE"
                onChange={onChange}
                className={"elements"}
                disabled
              />
              <Form.Check
                custom
                type="checkbox"
                label="OTL-BSL"
                name="routes2"
                id="routes2"
                value="OTL-BSL"
                onChange={onChange}
                className={"elements"}
                disabled
              />
              <Form.Check
                custom
                type="checkbox"
                label="LU-OLT"
                name="routes3"
                id="routes3"
                value="LU-OLT"
                onChange={onChange}
                className={"elements"}
                disabled
              />
            </div>
          </AccordionItem> */}

          <AccordionItem title="Ambient temperature">
            <Form.Check
              custom
              type="checkbox"
              label="Above 25 °C"
              name="temperature"
              id="temperaturehigh"
              value="3"
              onChange={onChange}
              className={"elements"}
              checked={filter.temperature && filter.temperature.includes(3)}
            />
            <Form.Check
              custom
              type="checkbox"
              label="0 °C to 25 °C"
              name="temperature"
              id="temperaturemedium"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.temperature && filter.temperature.includes(2)}
            />
            <Form.Check
              custom
              type="checkbox"
              label="Below 0 °C"
              name="temperature"
              id="temperaturelow"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.temperature && filter.temperature.includes(1)}
            />
          </AccordionItem>

          <AccordionItem title="Torsional oscillation protection">
            <Form.Check
              custom
              type="radio"
              label="Active"
              name="toprotection"
              id="toprotectionactive"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.toprotection === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Inactive"
              name="toprotection"
              id="toprotectioninactive"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.toprotection === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="toprotection"
              id="toprotectionall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.toprotection === 2 ? true : false}
            />
          </AccordionItem>

          <AccordionItem title="Anti slip brake">
            <Form.Check
              custom
              type="radio"
              label="Active"
              name="antislipbrake"
              id="antislipbrakeactive"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.antislipbrake === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Inactive"
              name="antislipbrake"
              id="antislipbrakeinactive"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.antislipbrake === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="antislipbrake"
              id="antislipbrakeall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.antislipbrake === 2 ? true : false}
            />
          </AccordionItem>

          {/* <AccordionItem title="Adhesion control">
            <Form.Check
              custom
              type="radio"
              label="Active"
              name="adhesioncontrol"
              id="adhesioncontrolactive"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.adhesioncontrol === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Inactive"
              name="adhesioncontrol"
              id="adhesioncontrolinactive"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.adhesioncontrol === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="adhesioncontrol"
              id="adhesioncontrolall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.adhesioncontrol === 2 ? true : false}
            />
          </AccordionItem> */}

          <AccordionItem title="Driving trailer velocity">
            <Form.Check
              custom
              type="radio"
              label="Available"
              name="drivingtrailervelocity"
              id="dtvelocityavailable"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.drivingtrailervelocity === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Not available"
              name="drivingtrailervelocity"
              id="dtvelocitynotavailable"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.drivingtrailervelocity === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="drivingtrailervelocity"
              id="dtvelocityall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.drivingtrailervelocity === 2 ? true : false}
            />
          </AccordionItem>

          {/* <AccordionItem title="Sling brake">
            <Form.Check
              custom
              type="radio"
              label="Active"
              name="slingbrake"
              id="slingbrakeactive"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.slingbrake === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Inactive"
              name="slingbrake"
              id="slingbrakeinactive"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.slingbrake === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="slingbrake"
              id="slingbrakeall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.slingbrake === 2 ? true : false}
            />
          </AccordionItem> */}

          <AccordionItem title="Sander">
            <Form.Check
              custom
              type="radio"
              label="Active"
              name="sander"
              id="sanderactive"
              value="1"
              onChange={onChange}
              className={"elements"}
              checked={filter.sander === 1 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="Inactive"
              name="sander"
              id="sanderinactive"
              value="0"
              onChange={onChange}
              className={"elements"}
              checked={filter.sander === 0 ? true : false}
            />
            <Form.Check
              custom
              type="radio"
              label="All"
              name="sander"
              id="sanderall"
              value="2"
              onChange={onChange}
              className={"elements"}
              checked={filter.sander === 2 ? true : false}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default TrainList;
