import { fromJS } from "immutable";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGGEDIN,
  CHANGEPASSWORD,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAILURE
} from "./constants";

const initialState = fromJS({
  loading: false,
  username: "",
  password: "",
  error: null,
  loginresponce: null,
  isloggedIn: false,
  loadingchangepass: false,
  changepassresponce: null
});

export function reducerLogin(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return state.set("loading", true).set("error", null);
    case LOGIN_SUCCESS:
      return state
        .set("loading", false)
        .set("loginresponce", action.loginresponce);
    case LOGIN_FAILURE:
      return state
        .set("loading", false)
        .set("loginresponce", null)
        .set("error", action.error);
    case LOGGEDIN:
      return state.set("isloggedIn", action.isloggedIn);

    // change pass
    case CHANGEPASSWORD:
      return state.set("loadingchangepass", true).set("error", null);
    case CHANGEPASSWORD_SUCCESS:
      return state
        .set("loadingchangepass", false)
        .set("changepassresponce", action.changepassresponce);
    case CHANGEPASSWORD_FAILURE:
      return state
        .set("loadingchangepass", false)
        .set("changepassresponce", null)
        .set("error", action.error);
    default:
      return state;
  }
}
