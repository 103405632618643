/**
 *
 * VersionInfo
 *
 */

import React from "react";
import { Col } from "react-bootstrap";
import preval from "preval.macro";
import packageJson from "../../../package.json";
import Moment from "moment";

require("./styles.scss");

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const formateddate = Moment(buildTimestamp).format("DD.MM.YYYY HH:mm:ss");
const copyRightYear = new Date().getFullYear();
function VersionInfo() {
  //const fullVersion = '[AIV]{version}_{date}[/AIV]';
  const fullVersion = `${packageJson.version}`;
  const buildDate = `${formateddate} `;
  return (
    <div>
      <Col className="version-info-container">
        WWO {fullVersion} <span className="hideMe"> - {buildDate}</span>
        {/* <a href="http://new.abb.com/provider-information" target="_blank">
          Provider Information/Impressum
        </a> */}
        &copy; Copyright {copyRightYear} ABB
      </Col>
    </div>
  );
}

export default VersionInfo;
