import {
  FETCH_MAP_DATA,
  FETCH_MAP_DATA_SUCCESS,
  FETCH_MAP_DATA_FAILURE,
  FETCH_TRAIN_LIST,
  FETCH_TRAIN_LIST_SUCCESS,
  FETCH_TRAIN_LIST_FAILURE,
  SET_CURRENT_TRAIN,
  SET_DATE
} from "./constants";

//Map
export const fetchMapData = (startDate, endDate) => ({
  type: FETCH_MAP_DATA,
  payload: { startDate, endDate }
});

export const fetchMapDataSucess = Mapdata => ({
  type: FETCH_MAP_DATA_SUCCESS,
  Mapdata
});

export const fetchMapDataFailure = error => ({
  type: FETCH_MAP_DATA_FAILURE,
  error
});

//TrainList
export const fetchTrainList = (startDate, endDate) => ({
  type: FETCH_TRAIN_LIST,
  payload: { startDate, endDate }
});

export const fetchTrainListSucess = TrainList => ({
  type: FETCH_TRAIN_LIST_SUCCESS,
  TrainList
});

export const fetchTrainListFailure = error => ({
  type: FETCH_TRAIN_LIST_FAILURE,
  error
});

export const setSelectedTrain = trainName => ({
  type: SET_CURRENT_TRAIN,
  trainName
});

export const setDate = (startDate, endDate) => ({
  type: SET_DATE,
  payload: { startDate, endDate }
});
