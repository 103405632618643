import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, withRouter } from "react-router-dom";
import { SessionStoragedecrypt } from "../../containers/Login/aes-encryption";

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ||
      SessionStoragedecrypt(sessionStorage.getItem("token")) == "true" ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.shape()
};

PrivateRoute.defaultProps = {
  isAuthenticated: false,
  location: {}
};

export default withRouter(PrivateRoute);
