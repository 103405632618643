import React from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./styles.scss";

class Breadcrumb extends React.Component {
  render() {
    return (
      <Row>
        <Col md={12} className="breadcrum-container">
          <span className="pageTitle boldText  float-left">
            <span>
              <img
                src={require("../../assets/images/home.svg")}
                className={""}
                alt="Breadcrumbicon"
              />
            </span>
            <span className="breadcrumbPath">
              {this.props.BreadcrumbPath.props.defaultMessage.indexOf(
                "Dashboard/"
              ) !== -1 ? (
                this.props.BreadcrumbPath.props.defaultMessage.indexOf(
                  "Dashboard/Train view/"
                ) !== -1 ? (
                  <span>
                    <NavLink to="/Dashboard" className="activebreadcrumb">
                      {`Dashboard / `}
                    </NavLink>
                    <NavLink to="/TrainViewPage" className="activebreadcrumb">
                      {`Train view / `}
                    </NavLink>
                    Details view
                  </span>
                ) : (
                  <span>
                    <NavLink to="/Dashboard" className="activebreadcrumb">
                      {`Dashboard / `}
                    </NavLink>
                    Train view
                  </span>
                )
              ) : (
                "Dashboard"
              )}
            </span>
          </span>
          <span className="trainMonitered float-right">
            <span className="boldText"> {this.props.NumberofTrains} </span>{" "}
            trains monitored{" "}
          </span>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Breadcrumb);
