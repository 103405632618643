import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Nav, Col, Button } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import "./Header.scss";

class Header extends React.Component {
  constructor(...args) {
    super(...args);
    this.textInput = React.createRef();
  }

  openModal = () => {
    this.props.showChangePasswordmodal(true);
    setTimeout(() => {
      this.textInput.current.focus();
    }, 1);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          autoFocus={false}
          enforceFocus={false}
          show={this.props.showmodal}
          onHide={() => this.props.showChangePasswordmodal(false)}
          className="changepasscontainer"
          centered
        >
          <Form className="changepassform">
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.changePassResponce ? (
                <div
                  className={
                    this.props.changePassResponce.responseMessage ===
                    "Password updated sucessfully."
                      ? "sucess-message"
                      : "error-message"
                  }
                >
                  {this.props.apiResponseMessage}
                </div>
              ) : (
                ""
              )}

              <Form.Group controlId="currentpass">
                <Form.Label>Current password</Form.Label>
                <Form.Control
                  type="password"
                  name="currentpass"
                  onChange={this.props.handleInputChange}
                  autoComplete="off"
                  autoFocus={true}
                  value={this.props.formValues.currentpass}
                  onKeyPress={this.props.handleKeyPress}
                  ref={this.textInput}
                />
                <div className="error-message">
                  {this.props.currentPassError}
                </div>
              </Form.Group>

              <Form.Group controlId="newpassword">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  name="newpassword"
                  onChange={this.props.handleInputChange}
                  autoComplete="off"
                  value={this.props.formValues.newpassword}
                  maxLength={30}
                  onKeyPress={this.props.handleKeyPress}
                />
                <div className="error-message">{this.props.newPassError}</div>
              </Form.Group>
              <Form.Group controlId="repeatpassword">
                <Form.Label>Repeat new password</Form.Label>
                <Form.Control
                  type="password"
                  name="repeatepass"
                  onChange={this.props.handleInputChange}
                  autoComplete="off"
                  value={this.props.formValues.repeatepass}
                  maxLength={30}
                  onKeyPress={this.props.handleKeyPress}
                />
                <div className="error-message">
                  {this.props.repeatenewPassError}
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="smallButton cancel"
                variant="secondary"
                size="lg"
                onClick={() => this.props.showChangePasswordmodal(false)}
              >
                {this.props.changePassResponce &&
                this.props.apiResponseMessage ===
                  "Password updated sucessfully."
                  ? "Close"
                  : "Cancel"}
              </Button>
              <Button
                className="smallButton"
                variant="primary"
                size="lg"
                onClick={() => this.props.changePasswordClick()}
              >
                {this.props.loading ? "Saving..." : " Save"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Navbar collapseOnSelect fixed="top" expand="lg">
          <Navbar.Brand onClick={this.props.redirectToHome}>
            <Col className="nav-logo" md={12}>
              <div>
                <p className="red-mark" />
                <p className="site-name">Wheel Wear Monitoring</p>
              </div>
            </Col>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Item>
                <Nav.Link className="not-active">
                  <img
                    src={require("../../assets/images/feedback.svg")}
                    className={""}
                    alt="Feedback"
                  />
                  &nbsp; Feedback
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="not-active">
                  <img
                    src={require("../../assets/images/help.svg")}
                    className={""}
                    alt="Help"
                  />
                  &nbsp; Help
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="not-active">
                  <img
                    src={require("../../assets/images/goToLink.svg")}
                    className={""}
                    alt="My ABB"
                  />
                  &nbsp; My ABB
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <img
                    src={require("../../assets/images/user.svg")}
                    className={""}
                    alt="Admin"
                  />
                  &nbsp;
                  <span
                    className="username"
                    title="Change Password"
                    // onClick={() => this.props.showChangePasswordmodal(true)}
                    onClick={this.openModal}
                  >
                    {this.props.loginResponce != undefined
                      ? this.props.loginResponce.userName
                      : sessionStorage.getItem("givenname") !== null
                      ? sessionStorage.getItem("givenname")
                      : "Not logged in"}
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="not-active">
                  <select
                    className="languageSelection"
                    id="language"
                    name="language"
                  >
                    <option>EN</option>
                    <option>FR</option>
                    <option>CH</option>
                    <option>DH</option>
                  </select>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <img
                    src={require("../../assets/images/logout.svg")}
                    className={""}
                    alt="Logout"
                    onClick={this.props.handleLogoutClick}
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
