import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import messages from "./messages";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { createStructuredSelector } from "reselect";
import Loading from "../../components/Loading";
import DashboardMap from "../../components/DashboardMap";
import Breadcrumb from "../../components/Breadcrumb";
import "./styles.scss";
import Datepicker from "../../components/DatePicker";
import { connect } from "react-redux";
import {
  fetchMapData,
  fetchTrainList,
  setSelectedTrain,
  setDate,
} from "./actions";
import {
  startDateSelector,
  endDateSelector,
  mapDataSelector,
  loadingSelector,
  fleetDataSelector,
  loadingMapSelector,
} from "./selectors";
import FleetView from "../../components/FleetView";
import {
  clearTrainViewMap,
  clearTrainViewHistogram,
} from "../TrainViewPage/actions";
import {
  clearDetailViewMap,
  clearDetailViewMapDateSlider,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
} from "../DetailTrainPage/actions";
import Help from "../../components/Help";

moment.locale("en");
momentLocalizer();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineColor: "#abd4fd",
      lineColorHover: "#2e92fa",
      lineSeleted: "",
    };
  }
  onMouseOverEvent = (currentLine, isHover) => {
    this.setState({ lineSeleted: currentLine });
    if (isHover === true) {
      document.getElementById(currentLine).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  onMouseOutEvent = () => {
    this.setState({ lineSeleted: "" });
  };

  componentDidMount() {
    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    this.props.getMapData(startDate, endDate);
    this.props.getTrainList(startDate, endDate);
  }

  applyDateChange = async (start_Date, end_Date, trainSelect = true) => {
    if (trainSelect)
      if (
        new Date(start_Date).valueOf() ==
          new Date(this.props.startDate.replace(/%3A/g, ":")).valueOf() &&
        new Date(end_Date).valueOf() ==
          new Date(this.props.endDate.replace(/%3A/g, ":")).valueOf()
      ) {
        return false;
      }
    await this.props.setDate(start_Date, end_Date);

    await this.props.clearTrainViewMap();
    await this.props.clearTrainViewHistogram();
    await this.props.clearDetailViewMap();
    await this.props.clearDetailViewMapDateSlider();
    await this.props.clearDetailViewTimetrend();
    await this.props.clearDetailViewScatterplot();
    await this.props.clearDetailViewHistogram();

    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    this.props.getMapData(startDate, endDate);
    this.props.getTrainList(startDate, endDate);
  };

  onTrainSelect = async (selectedTrain) => {
    await this.props.setSelectedTrain(selectedTrain);
    await this.props.clearTrainViewMap();
    await this.props.clearTrainViewHistogram();
    await this.props.clearDetailViewMap();
    await this.props.clearDetailViewMapDateSlider();
    await this.props.clearDetailViewTimetrend();
    await this.props.clearDetailViewScatterplot();
    await this.props.clearDetailViewHistogram();
    this.props.history.push("/TrainViewPage");
  };

  render() {
    const {
      loading,
      mapdata,
      fleetData,
      startDate,
      endDate,
      loadingMap,
    } = this.props;
    return (
      <div className="page-home">
        <Help />
        <Breadcrumb
          BreadcrumbIcon={
            <img
              src={require("../../assets/images/home.svg")}
              className={""}
              alt="Home"
            />
          }
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          NumberofTrains={fleetData && fleetData.length}
        />
        <Row>
          <Col md={12}>
            <Datepicker
              onApplyClick={this.applyDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
          {/* <Col md={3}> { JSON.stringify(this.state)} <hr /></Col> */}
          {mapdata === null || loading === true || loadingMap === true ? (
            <Col md={12}>
              <Loading />
            </Col>
          ) : mapdata.length > 0 ? (
            <React.Fragment>
              <Col
                sm={12}
                md={3}
                className="full-width noLeftpadding noRightpadding newmargintop "
              >
                <FleetView
                  trainData={fleetData}
                  fetching={loading}
                  onHover={this.onMouseOverEvent}
                  onHoverOut={this.onMouseOutEvent}
                  onTrainSelect={this.onTrainSelect}
                  routeSeleted={this.state.lineSeleted}
                />
              </Col>
              <Col
                sm={12}
                md={9}
                className="full-width noRightpadding newmargintop"
              >
                <DashboardMap
                  mapPath={mapdata}
                  onHover={this.onMouseOverEvent}
                  onHoverOut={this.onMouseOutEvent}
                  routeColor={this.state.lineColor}
                  routeColorHover={this.state.lineColorHover}
                  routeSeleted={this.state.lineSeleted}
                />
              </Col>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Col
                sm={12}
                md={3}
                className="full-width noLeftpadding noRightpadding newmargintop"
              >
                <FleetView
                  trainData={fleetData}
                  fetching={loading}
                  onHover={this.onMouseOverEvent}
                  onHoverOut={this.onMouseOutEvent}
                  onTrainSelect={this.onTrainSelect}
                />
              </Col>

              <Col
                sm={12}
                md={9}
                className="full-width noRightpadding newmargintop "
              >
                <div className="nodatanotificationcontainer">
                  <Alert variant="danger" id="nodatanotification" dismissible>
                    <p className="nodatanotificationcontent">
                      No data recorded on selected day.
                    </p>
                  </Alert>
                </div>
                <div className="disabledcontent">
                  <DashboardMap
                    mapPath={mapdata}
                    onHover={this.onMouseOverEvent}
                    onHoverOut={this.onMouseOutEvent}
                    routeColor={this.state.lineColor}
                    routeColorHover={this.state.lineColorHover}
                    routeSeleted={this.state.lineSeleted}
                  />
                </div>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </div>
    );
  }
}

Dashboard.defaultProps = {};

Dashboard.propTypes = {
  formatedData: PropTypes.any,
  loading: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector(),
  startDate: startDateSelector(),
  endDate: endDateSelector(),
  mapdata: mapDataSelector(),
  fleetData: fleetDataSelector(),
  loadingMap: loadingMapSelector(),
});

const mapDispatchToProps = {
  getTrainList: fetchTrainList,
  getMapData: fetchMapData,
  setSelectedTrain,
  setDate,
  clearDetailViewMap,
  clearDetailViewMapDateSlider,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
  clearTrainViewMap,
  clearTrainViewHistogram,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
