/**
 *
 * DateDiffText
 *
 */

import React from "react";
import moment from "moment";
import styles from "./styles.scss";
// import debug from 'debug';

class DateDiffText extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  getDifferenceText = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const localStartDate = StartDate;
      const localEndDate = EndDate;
      const diff = moment(localEndDate).diff(moment(localStartDate));
      const d = moment.duration(diff);

      const days = Math.floor(d.asHours() / 24);
      const hours = Math.floor(d.asHours() - days * 24);
      const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
      let msg = days > 0 ? ` ${days} days` : "";
      msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : "";
      msg += minutes > 0 ? ` ${minutes} minutes` : "";

      if (days < 0 || hours < 0 || minutes < 0) {
        msg = "";
      }
      return msg;
    }

    return "";
  };

  render() {
    const text = this.getDifferenceText(
      this.props.startdate,
      this.props.enddate
    );
    return <span className="duration">{text}</span>;
  }
}

export default DateDiffText;
