import {
  GET_TRAINVIEW_MAP,
  GET_TRAINVIEW_MAPSLIP1_SUCCESS,
  GET_TRAINVIEW_MAPSLIP2_SUCCESS,
  GET_TRAINVIEW_MAPSPEED1_SUCCESS,
  GET_TRAINVIEW_MAPSPEED2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORT1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORT2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS,
  GET_TRAINVIEW_MAP_FAILURE,
  CLEAR_TRAINVIEW_MAP,
  GET_TRAINVIEW_GRAPHDATA,
  GET_TRAINVIEW_GRAPHDATA_SUCCESS,
  GET_TRAINVIEW_GRAPHDATA_FAILURE,
  GET_TRAINVIEW_HISTOGRAM,
  GET_TRAINVIEW_HISTOGRAM_SUCCESS,
  GET_TRAINVIEW_HISTOGRAM_FAILURE,
  CLEAR_TRAINVIEW_HISTOGRAM,
  SET_TAB,
} from "./constants";

//Map
export const getTrainViewMap = (id, startDate, endDate, docType, bogie) => ({
  type: GET_TRAINVIEW_MAP,
  payload: { id, startDate, endDate, docType, bogie },
});

export const getTrainViewMapSlip1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPSLIP1_SUCCESS,
  payload,
});

export const getTrainViewMapSlip2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPSLIP2_SUCCESS,
  payload,
});

export const getTrainViewMapSpeed1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPSPEED1_SUCCESS,
  payload,
});

export const getTrainViewMapSpeed2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPSPEED2_SUCCESS,
  payload,
});

export const getTrainViewMapEffort1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORT1_SUCCESS,
  payload,
});

export const getTrainViewMapEffort2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORT2_SUCCESS,
  payload,
});

export const getTrainViewMapEffortDriving1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS,
  payload,
});

export const getTrainViewMapEffortDriving2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS,
  payload,
});

export const getTrainViewMapEffortBraking1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS,
  payload,
});

export const getTrainViewMapEffortBraking2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS,
  payload,
});

export const getTrainViewMapEffortBrakingMech1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  payload,
});

export const getTrainViewMapEffortBrakingMech2Success = (payload) => ({
  type: GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  payload,
});

export const getTrainViewMapDrivingTrailerVel1Success = (payload) => ({
  type: GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS,
  payload,
});

export const getTrainViewMapFailure = (error) => ({
  type: GET_TRAINVIEW_MAP_FAILURE,
  error,
});

export const clearTrainViewMap = (payload) => ({
  type: CLEAR_TRAINVIEW_MAP,
  payload,
});

//Time trend Graph
export const getTrainViewGraphData = (id, startDate, endDate) => ({
  type: GET_TRAINVIEW_GRAPHDATA,
  payload: { id, startDate, endDate },
});

export const getTrainViewGraphDataSuccess = (Graphdata) => ({
  type: GET_TRAINVIEW_GRAPHDATA_SUCCESS,
  Graphdata,
});

export const getTrainViewGraphDataFailure = (error) => ({
  type: GET_TRAINVIEW_GRAPHDATA_FAILURE,
  error,
});

//Scatter plot Graph
export const getTrainViewScatterData = () => ({
  type: GET_TRAINVIEW_GRAPHDATA,
});

export const getTrainViewScatterDataSuccess = (Graphdata) => ({
  type: GET_TRAINVIEW_GRAPHDATA_SUCCESS,
  Graphdata,
});

export const getTrainViewScatterDataFailure = (error) => ({
  type: GET_TRAINVIEW_GRAPHDATA_FAILURE,
  error,
});

//Histogram Graph
export const getTrainViewHistogram = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  zAxisSignal,
  filter
) => ({
  type: GET_TRAINVIEW_HISTOGRAM,
  payload: {
    id,
    startDate,
    endDate,
    xAxisSignal,
    yAxisSignal,
    zAxisSignal,
    filter,
  },
});

export const getTrainViewHistogramSuccess = (payload) => ({
  type: GET_TRAINVIEW_HISTOGRAM_SUCCESS,
  payload,
});

export const getTrainViewHistogramFailure = (error) => ({
  type: GET_TRAINVIEW_HISTOGRAM_FAILURE,
  error,
});

export const clearTrainViewHistogram = (payload) => ({
  type: CLEAR_TRAINVIEW_HISTOGRAM,
  payload,
});

export const setTab = (payload) => ({
  type: SET_TAB,
  payload,
});
