import React, { Component } from "react";
import "./styles.scss";

export default class SelectorLine extends Component {
  render() {
    let currentColor;
    currentColor = this.props.currentColor;

    if (this.props.operation != "" || this.props.gpsValid === 0)
      currentColor = "grey";

    return (
      <div
        style={{
          width: this.props.colorArea + "%",
          backgroundColor: currentColor
        }}
        className="railStyle"
      />
    );
  }
}
