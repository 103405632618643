export const GET_TRAINVIEW_MAPDATA = "GET_TRAINVIEW_MAPDATA";
export const GET_TRAINVIEW_MAPDATA_SUCCESS = "GET_TRAINVIEW_MAPDATA_SUCCESS";
export const GET_TRAINVIEW_MAPDATA_FAILURE = "GET_TRAINVIEW_MAPDATA_FAILURE";

export const GET_TRAINVIEW_GRAPHDATA = "GET_TRAINVIEW_GRAPHDATA";
export const GET_TRAINVIEW_GRAPHDATA_SUCCESS =
  "GET_TRAINVIEW_GRAPHDATA_SUCCESS";
export const GET_TRAINVIEW_GRAPHDATA_FAILURE =
  "GET_TRAINVIEW_GRAPHDATA_FAILURE";

export const GET_TRAINVIEW_SCATTER = "GET_TRAINVIEW_SCATTER";
export const GET_TRAINVIEW_SCATTER_SUCCESS = "GET_TRAINVIEW_SCATTER_SUCCESS";
export const GET_TRAINVIEW_SCATTER_FAILURE = "GET_TRAINVIEW_SCATTER_FAILURE";

export const GET_TRAINVIEW_HISTOGRAM = "GET_TRAINVIEW_HISTOGRAM";
export const GET_TRAINVIEW_HISTOGRAM_SUCCESS =
  "GET_TRAINVIEW_HISTOGRAM_SUCCESS";
export const GET_TRAINVIEW_HISTOGRAM_FAILURE =
  "GET_TRAINVIEW_HISTOGRAM_FAILURE";
export const CLEAR_TRAINVIEW_HISTOGRAM = "CLEAR_TRAINVIEW_HISTOGRAM";

export const SET_TAB = "SET_TAB";

export const GET_TRAINVIEW_MAP = "GET_TRAINVIEW_MAP";
export const GET_TRAINVIEW_MAPSLIP1_SUCCESS = "GET_TRAINVIEW_MAPSLIP1_SUCCESS";
export const GET_TRAINVIEW_MAPSLIP2_SUCCESS = "GET_TRAINVIEW_MAPSLIP2_SUCCESS";
export const GET_TRAINVIEW_MAPSPEED1_SUCCESS =
  "GET_TRAINVIEW_MAPSPEED1_SUCCESS";
export const GET_TRAINVIEW_MAPSPEED2_SUCCESS =
  "GET_TRAINVIEW_MAPSPEED2_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORT1_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORT1_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORT2_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORT2_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS";
export const GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS =
  "GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS";
export const GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS =
  "GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS";
export const GET_TRAINVIEW_MAP_FAILURE = "GET_TRAINVIEW_MAP_FAILURE";
export const CLEAR_TRAINVIEW_MAP = "CLEAR_TRAINVIEW_MAP";
