import React, { Component } from "react";
import "./styles.scss";
import Col from "react-bootstrap/Col";

const TitleBar = ({ trainno, onClickRedirectMap, id }) => {
  return (
    <Col md={12} className="containertitlebar">
      <img
        src={require("../../../assets/images/subway.svg")}
        className="icontrain"
      />
      <span className="text">{trainno}</span>
      <img src={require("../../../assets/images/Menu.svg")} className="menu" />
      <img
        src={require("../../../assets/images/icon-config.svg")}
        className="iconconfig"
      />
      <span className="btn-link-custom-con">
        <button className="btn btn-link-custom" onClick={onClickRedirectMap}>
          {id === "trainview" ? "Go to detailed map" : "Go to train view"}
        </button>
      </span>
    </Col>
  );
};

export default TitleBar;
