//Library
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// import CryptoJS from "crypto-js/crypto-js.js";
import { AESEncrypt, SessionStoragedecrypt } from "./aes-encryption";
import {
  loadingSelector,
  loginresponceSelector,
  logincheckSelector
} from "./selectors";
import LoginForm from "../../components/Login";
import { logIn, loggedIn } from "./actions";

class LoginPage extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      username: "",
      password: "",
      userNameError: "",
      passwordError: ""
    };
  }
  componentDidUpdate() {
    if (
      this.props.loginResponce != undefined &&
      this.props.loginResponce.userValid === true
    ) {
      this.props.loggedIn(true);
      this.props.history.push("/Dashboard");
      sessionStorage.setItem("token", AESEncrypt(true));
      sessionStorage.setItem("givenname", this.props.loginResponce.userName);
    }
  }

  handleLoginClick = () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const isValidEmail = regexp.test(this.state.username.trim());

    if (this.state.username === "") {
      this.setState({
        userNameError: "User name  is required",
        passwordError: ""
      });
    } else if (isValidEmail !== true) {
      this.setState({
        userNameError: "Please enter a valid email address",
        passwordError: ""
      });
    } else if (this.state.password === "") {
      this.setState({
        userNameError: "",
        passwordError: "Password is required"
      });
    } else {
      this.props.logIn(
        this.state.username.trim(),
        AESEncrypt(this.state.password)
      ); //  enable it to send encrypted password
      // this.props.logIn(this.state.username, this.state.password);

      this.setState({
        // username: "",
        password: "",
        userNameError: "",
        passwordError: ""
      });
    }
  };

  handleInputChange = e => {
    if (e !== undefined) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.handleLoginClick();
    }
  };

  render() {
    console.log("checking login in render");
    if (SessionStoragedecrypt(sessionStorage.getItem("token")) == "true") {
      this.props.history.push("/Dashboard");
    }
    // if (
    //   this.props.loginResponce != undefined &&
    //   this.props.loginResponce === true
    // ) {
    //   sessionStorage.setItem("isValidUser", true);
    //   this.props.history.push("/Dashboard");
    // }

    return (
      <div className="loginpage">
        <LoginForm
          handleLoginClick={this.handleLoginClick}
          handleInputChange={this.handleInputChange}
          loginResponce={this.props.loginResponce}
          loading={this.props.loading}
          userNameError={this.state.userNameError}
          passwordError={this.state.passwordError}
          formValues={this.state}
          handleKeyPress={this.handleKeyPress}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector(),
  loginResponce: loginresponceSelector(),
  logincheck: logincheckSelector()
});
const mapDispatchToProps = {
  logIn: logIn,
  loggedIn: loggedIn
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
