import React, { Component } from "react";
import "./styles.scss";

const FilterDetails = ({ basicFilter, advancedFilter }) => {
  let strOperation = "Operation mode: ",
    strTemperature = "Temperature: ",
    strDrivingtrailervelocity = "Driving trailer velocity: ",
    strSander = "Sander: ",
    strTOprotection = "Torsional oscillation protection: ",
    strAntislipbrake = "Anti slip brake: ";
  // strAdhesioncontrol = "Adhesion Control: ",
  // strSlingbrake = "Sling brake: ";
  return (
    <div className="filtersTitle">
      <span className="basicfilter">{"Basic filter"}</span>

      <span className="weathercondition">
        <img
          src={require("../../../assets/images/wet.svg")}
          className={"weatherconditionicon"}
        />
        {"Weather condition: " + basicFilter.weather}
      </span>

      <span className="operationmode">
        <img
          src={require("../../../assets/images/magnet.svg")}
          className={"operationmodeicon"}
        />
        {basicFilter.operation &&
          basicFilter.operation.sort().map((item) => {
            if (item === 1) {
              strOperation += "Pulling, cabin 1 in front; ";
            }
            if (item === 2) {
              strOperation += "Pulling, cabin 2 in front; ";
            }
            if (item === 3) {
              strOperation += "Pushing, cabin 1 in front; ";
            }
            if (item === 4) {
              strOperation += "Pushing, cabin 2 in front; ";
            }
            if (item === 5) {
              strOperation += "Standstill; ";
            }
          })}
        {basicFilter.operation && basicFilter.operation.length === 6
          ? (strOperation = "Operation mode: All ")
          : strOperation}
      </span>

      <span className="bogie">
        <img
          src={require("../../../assets/images/generator.svg")}
          className={"bogieicon"}
        />
        {basicFilter.bogie &&
         basicFilter.bogie === 1
         ? ("Bogie: 1")
         : basicFilter.bogie === 2
         ? ("Bogie: 2")
         : ("Bogie: All")}
      </span>

      <span className="dashline">{" | "}</span>
      <span className="advancefilter">{"Advanced filter"}</span>
      <span className="advancefilterValues">
        {advancedFilter.temperature &&
          advancedFilter.temperature.sort().map((item) => {
            if (item === 1) {
              strTemperature += "Low; ";
            }
            if (item === 2) {
              strTemperature += "Medium; ";
            }
            if (item === 3) {
              strTemperature += "High; ";
            }
          })}
        {strTemperature}
      </span>
      <span className="advancefilterValues">
        {advancedFilter.drivingtrailervelocity &&
        advancedFilter.drivingtrailervelocity === 1
          ? (strDrivingtrailervelocity += "Available; ")
          : advancedFilter.drivingtrailervelocity === 0
          ? (strDrivingtrailervelocity += "Not Available; ")
          : (strDrivingtrailervelocity += "All; ")}
      </span>
      <span className="advancefilterValues">
        {advancedFilter.sander && advancedFilter.sander === 1
          ? (strSander += "Active; ")
          : advancedFilter.sander === 0
          ? (strSander += "Inactive; ")
          : (strSander += "All; ")}
      </span>
      <span className="advancefilterValues">
        {advancedFilter.toprotection && advancedFilter.toprotection === 1
          ? (strTOprotection += "Active; ")
          : advancedFilter.toprotection === 0
          ? (strTOprotection += "Inactive; ")
          : (strTOprotection += "All; ")}
      </span>
      <span className="advancefilterValues">
        {advancedFilter.antislipbrake && advancedFilter.antislipbrake === 1
          ? (strAntislipbrake += "Active; ")
          : advancedFilter.antislipbrake === 0
          ? (strAntislipbrake += "Inactive; ")
          : (strAntislipbrake += "All; ")}
      </span>
      {/* <span className="advancefilterValues">
        {advancedFilter.adhesioncontrol && advancedFilter.adhesioncontrol === 1
          ? (strAdhesioncontrol += "Active; ")
          : advancedFilter.adhesioncontrol === 0
          ? (strAdhesioncontrol += "Inactive; ")
          : (strAdhesioncontrol += "All; ")}
      </span>
      <span className="advancefilterValues">
        {advancedFilter.slingbrake && advancedFilter.slingbrake === 1
          ? (strSlingbrake += "Active; ")
          : advancedFilter.slingbrake === 0
          ? (strSlingbrake += "Inactive; ")
          : (strSlingbrake += "All; ")}
      </span> */}
    </div>
  );
};

export default FilterDetails;
