import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import api from "../../utils/api";
import {
  getDetailViewMapSlip1Success,
  getDetailViewMapSlip2Success,
  getDetailViewMapSpeed1Success,
  getDetailViewMapSpeed2Success,
  getDetailViewMapEffort1Success,
  getDetailViewMapEffort2Success,
  getDetailViewMapEffortDriving1Success,
  getDetailViewMapEffortDriving2Success,
  getDetailViewMapEffortBraking1Success,
  getDetailViewMapEffortBraking2Success,
  getDetailViewMapEffortBrakingMech1Success,
  getDetailViewMapEffortBrakingMech2Success,
  getDvMapDrivingTrailerVel1Success,
  getDvMapEffortReference1Success,
  getDvMapEffortReference2Success,
  getDvMapSlipPower1Success,
  getDvMapSlipPower2Success,
  getDvMapPowerBrakingMechanical1Success,
  getDvMapPowerBrakingMechanical2Success,
  getDvMapAirPressureBrakeCylinder1Success,
  getDvMapAirPressureBrakeCylinder2Success,
  getDvMapAmplitudeTorsionalOscillation1Success,
  getDvMapAmplitudeTorsionalOscillation2Success,
  getDetailViewMapFailure,
  getDetailViewTimetrendSlip1Success,
  getDetailViewTimetrendSlip2Success,
  getDetailViewTimetrendSpeed1Success,
  getDetailViewTimetrendSpeed2Success,
  getDetailViewTimetrendEffort1Success,
  getDetailViewTimetrendEffort2Success,
  getDetailViewTimetrendEffortDriving1Success,
  getDetailViewTimetrendEffortDriving2Success,
  getDetailViewTimetrendEffortBraking1Success,
  getDetailViewTimetrendEffortBraking2Success,
  getDetailViewTimetrendEffortBrakingMech1Success,
  getDetailViewTimetrendEffortBrakingMech2Success,
  getDvTtDrivingTrailerVel1Success,
  getDvTtEffortReference1Success,
  getDvTtEffortReference2Success,
  getDvTtSlipPower1Success,
  getDvTtSlipPower2Success,
  getDvTtPowerBrakingMechanical1Success,
  getDvTtPowerBrakingMechanical2Success,
  getDvTtAirPressureBrakeCylinder1Success,
  getDvTtAirPressureBrakeCylinder2Success,
  getDvTtAmplitudeTorsionalOscillation1Success,
  getDvTtAmplitudeTorsionalOscillation2Success,
  getDetailViewTimetrendFailure,
  getDetailViewScatterplotSuccess,
  getDetailViewScatterplot2Success,
  getDetailViewScatterplotFailure,
  getDetailViewHistogramSuccess,
  getDetailViewHistogramFailure,
  getDetailViewMapDateSliderSucess,
} from "./actions";
import {
  GET_DETAILVIEW_MAP,
  GET_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_SCATTERPLOT,
  GET_DETAILVIEW_HISTOGRAM,
  GET_DETAILVIEW_MAPDATESLIDER,
} from "./constants";
import { handleError } from "../../utils/api-utils";

//Map
export function* getDetailViewMapSaga() {
  yield takeEvery(GET_DETAILVIEW_MAP, gettingDetailViewMap);
}
export function* gettingDetailViewMap(action) {
  try {
    const { id, startDate, endDate, docType, bogie } = action.payload;

    const response = yield call(
      api.fetchDetailViewMapData,
      id,
      startDate,
      endDate,
      docType,
      bogie
    );
    let GraphData = response.dc[0];
    if (GraphData === undefined) GraphData = { data: [] };

    if (docType === "Slip" && bogie === "1") {
      yield put(getDetailViewMapSlip1Success(GraphData));
    }
    if (docType === "Slip" && bogie === "2") {
      yield put(getDetailViewMapSlip2Success(GraphData));
    }
    if (docType === "Speed" && bogie === "1") {
      yield put(getDetailViewMapSpeed1Success(GraphData));
    }
    if (docType === "Speed" && bogie === "2") {
      yield put(getDetailViewMapSpeed2Success(GraphData));
    }
    if (docType === "Effort" && bogie === "1") {
      yield put(getDetailViewMapEffort1Success(GraphData));
    }
    if (docType === "Effort" && bogie === "2") {
      yield put(getDetailViewMapEffort2Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "1") {
      yield put(getDetailViewMapEffortDriving1Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "2") {
      yield put(getDetailViewMapEffortDriving2Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "1") {
      yield put(getDetailViewMapEffortBraking1Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "2") {
      yield put(getDetailViewMapEffortBraking2Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "1") {
      yield put(getDetailViewMapEffortBrakingMech1Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "2") {
      yield put(getDetailViewMapEffortBrakingMech2Success(GraphData));
    }

    if (docType === "DrivingTrailerVel" && bogie === "1") {
      yield put(getDvMapDrivingTrailerVel1Success(GraphData));
    }
    if (docType === "EffortReference" && bogie === "1") {
      yield put(getDvMapEffortReference1Success(GraphData));
    }
    if (docType === "EffortReference" && bogie === "2") {
      yield put(getDvMapEffortReference2Success(GraphData));
    }
    if (docType === "SlipPower" && bogie === "1") {
      yield put(getDvMapSlipPower1Success(GraphData));
    }
    if (docType === "SlipPower" && bogie === "2") {
      yield put(getDvMapSlipPower2Success(GraphData));
    }
    if (docType === "PowerBrakingMechanical" && bogie === "1") {
      yield put(getDvMapPowerBrakingMechanical1Success(GraphData));
    }
    if (docType === "PowerBrakingMechanical" && bogie === "2") {
      yield put(getDvMapPowerBrakingMechanical2Success(GraphData));
    }
    if (docType === "AirPressureBrakeCylinder" && bogie === "1") {
      yield put(getDvMapAirPressureBrakeCylinder1Success(GraphData));
    }
    if (docType === "AirPressureBrakeCylinder" && bogie === "2") {
      yield put(getDvMapAirPressureBrakeCylinder2Success(GraphData));
    }
    if (docType === "AmplitudeTorsionalOscillation" && bogie === "1") {
      yield put(getDvMapAmplitudeTorsionalOscillation1Success(GraphData));
    }
    if (docType === "AmplitudeTorsionalOscillation" && bogie === "2") {
      yield put(getDvMapAmplitudeTorsionalOscillation2Success(GraphData));
    }
  } catch (error) {
    yield put(handleError(error, getDetailViewMapFailure));
  }
}

export function* getDetailViewMapDateSliderSaga() {
  yield takeEvery(GET_DETAILVIEW_MAPDATESLIDER, gettingDetailViewMapDateSlider);
}
export function* gettingDetailViewMapDateSlider(action) {
  try {
    const { id, startDate, endDate, docType, bogie } = action.payload;

    const response = yield call(
      api.fetchDetailViewMapDateSliderData,
      id,
      startDate,
      endDate,
      docType,
      bogie
    );
    let GraphData = response.dc[0];

    yield put(getDetailViewMapDateSliderSucess(GraphData));
  } catch (error) {
    yield put(handleError(error, getDetailViewMapFailure));
  }
}

//Timetrend
export function* getDetailViewTimetrendSaga() {
  yield takeEvery(GET_DETAILVIEW_TIMETREND, gettingDetailViewTimetrend);
}
export function* gettingDetailViewTimetrend(action) {
  try {
    const { id, startDate, endDate, docType, bogie } = action.payload;

    const response = yield call(
      api.fetchDetailViewTimetrendData,
      id,
      startDate,
      endDate,
      docType,
      bogie
    );
    const GraphData = response.dc[0];
    if (GraphData === undefined) GraphData = { data: [], config: { unit: "" } };

    if (docType === "Slip" && bogie === "1") {
      yield put(getDetailViewTimetrendSlip1Success(GraphData));
    }
    if (docType === "Slip" && bogie === "2") {
      yield put(getDetailViewTimetrendSlip2Success(GraphData));
    }
    if (docType === "Speed" && bogie === "1") {
      yield put(getDetailViewTimetrendSpeed1Success(GraphData));
    }
    if (docType === "Speed" && bogie === "2") {
      yield put(getDetailViewTimetrendSpeed2Success(GraphData));
    }
    if (docType === "Effort" && bogie === "1") {
      yield put(getDetailViewTimetrendEffort1Success(GraphData));
    }
    if (docType === "Effort" && bogie === "2") {
      yield put(getDetailViewTimetrendEffort2Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "1") {
      yield put(getDetailViewTimetrendEffortDriving1Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "2") {
      yield put(getDetailViewTimetrendEffortDriving2Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "1") {
      yield put(getDetailViewTimetrendEffortBraking1Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "2") {
      yield put(getDetailViewTimetrendEffortBraking2Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "1") {
      yield put(getDetailViewTimetrendEffortBrakingMech1Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "2") {
      yield put(getDetailViewTimetrendEffortBrakingMech2Success(GraphData));
    }

    if (docType === "DrivingTrailerVel" && bogie === "1") {
      yield put(getDvTtDrivingTrailerVel1Success(GraphData));
    }
    if (docType === "EffortReference" && bogie === "1") {
      yield put(getDvTtEffortReference1Success(GraphData));
    }
    if (docType === "EffortReference" && bogie === "2") {
      yield put(getDvTtEffortReference2Success(GraphData));
    }
    if (docType === "SlipPower" && bogie === "1") {
      yield put(getDvTtSlipPower1Success(GraphData));
    }
    if (docType === "SlipPower" && bogie === "2") {
      yield put(getDvTtSlipPower2Success(GraphData));
    }
    if (docType === "PowerBrakingMechanical" && bogie === "1") {
      yield put(getDvTtPowerBrakingMechanical1Success(GraphData));
    }
    if (docType === "PowerBrakingMechanical" && bogie === "2") {
      yield put(getDvTtPowerBrakingMechanical2Success(GraphData));
    }
    if (docType === "AirPressureBrakeCylinder" && bogie === "1") {
      yield put(getDvTtAirPressureBrakeCylinder1Success(GraphData));
    }
    if (docType === "AirPressureBrakeCylinder" && bogie === "2") {
      yield put(getDvTtAirPressureBrakeCylinder2Success(GraphData));
    }
    if (docType === "AmplitudeTorsionalOscillation" && bogie === "1") {
      yield put(getDvTtAmplitudeTorsionalOscillation1Success(GraphData));
    }
    if (docType === "AmplitudeTorsionalOscillation" && bogie === "2") {
      yield put(getDvTtAmplitudeTorsionalOscillation2Success(GraphData));
    }
  } catch (error) {
    yield put(handleError(error, getDetailViewTimetrendFailure));
  }
}

//Scatterplot
export function* getDetailViewScatterplotSaga() {
  yield takeEvery(GET_DETAILVIEW_SCATTERPLOT, gettingDetailViewScatterplotSaga);
}
export function* gettingDetailViewScatterplotSaga(action) {
  try {
    const {
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      bogie,
      scatterplotAvg,
    } = action.payload;
    const response = yield call(
      api.fetchDetailViewScatterplotData,
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      bogie,
      scatterplotAvg
    );
    const GraphData = response.dc;
    //yield put(getDetailViewScatterplotSuccess(GraphData));
    //----------------
    if (bogie === "1") {
      yield put(getDetailViewScatterplotSuccess(GraphData));
    }
    if (bogie === "2") {
      yield put(getDetailViewScatterplot2Success(GraphData));
    }
    //-----------------
  } catch (error) {
    yield put(handleError(error, getDetailViewScatterplotFailure));
  }
}

//Histogram
export function* getDetailViewHistogramSaga() {
  yield takeLatest(GET_DETAILVIEW_HISTOGRAM, gettingDetailViewHistogramSaga);
}
export function* gettingDetailViewHistogramSaga(action) {
  try {
    const {
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      zAxisSignal,
      filter,
    } = action.payload;
    const response = yield call(
      api.fetchDetailViewHistogramData,
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      zAxisSignal,
      filter
    );
    const GraphData = response; /*response;*/
    yield put(getDetailViewHistogramSuccess(GraphData));
  } catch (error) {
    yield put(handleError(error, getDetailViewHistogramFailure));
  }
}
