/*global google*/
import React, { Component } from "react";
import Moment from "moment";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
  //InfoWindow
} from "react-google-maps";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import { googleMapURL, googleMapCustomStyle } from "../Googlemapkey";
let prevPosition = undefined,
  prevPositionIsvalidFalse = undefined,
  gpsValid = 1;
let startPosition = null,
  startFlag = true;

const DetailViewGoogleMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 46.94809, lng: 7.44744 }}
      /*defaultCenter={
        props.positions[~~(props.positions.length / 2)].positions[1]
      }*/
      /*center={
        props.markerPosition.lat === 0 && startPosition !== null
          ? startPosition
          : props.markerPosition
      }*/
      //mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
      }}
    >
      {
        ((gpsValid = 1),
        (startFlag = true),
        (startPosition = null),
        (prevPosition = undefined),
        (prevPositionIsvalidFalse = undefined))
      }
      {props.positions.map((obj, index) => {
        if (index !== 0 && obj.gvd === 1) {
          //&& props.positions.length - 1 !== index
          // if (gpsValid === 0) {
          //   gpsValid = 2;
          //   return;
          // }
          if (gpsValid === 0 || gpsValid === 2) {
            gpsValid = 1;
            if (
              prevPositionIsvalidFalse !== undefined &&
              prevPositionIsvalidFalse[0].lat !== 0
            ) {
              //let currentColor = "#C8C8C8"; //LightGray
              prevPositionIsvalidFalse[1].lat = obj.pos[1].lat;
              prevPositionIsvalidFalse[1].lng = obj.pos[1].lng;
              return (
                <Polyline
                  path={prevPositionIsvalidFalse}
                  options={{
                    strokeColor: "#C8C8C8", //LightGray
                    strokeWeight: 4,
                  }}
                />
              );
            } else {
              return;
            }
          }

          let currentColor = props.basicFilter.bogie === 3 ? obj.cla : obj.clr;

          if (currentColor === "green") currentColor = "#0CA919";
          else if (currentColor === "yellow") currentColor = "#FFD800";
          else if (currentColor === "red") currentColor = "#F03040";
          else if (currentColor === "gray") currentColor = "#545454";

          if (currentColor && obj.pos) {
            if (
              props.basicFilter.operation &&
              !props.basicFilter.operation.find((item) => item == obj.ftr.opm)
            )
              currentColor = "#C8C8C8"; //LightGray
            /*
            //without any logic for testing
            return (
              <Polyline
                path={obj.positions}
                options={{
                  strokeColor: currentColor,
                  strokeWeight: 3
                }}
              />
            );*/
            if (obj.pos[0].lat !== 0 && obj.pos[1].lat !== 0) {
              prevPositionIsvalidFalse = obj.pos;
              prevPosition = undefined;
              if (startFlag) {
                startPosition = obj.pos[0];
                startFlag = false;
              }
              return (
                <Polyline
                  path={obj.pos}
                  options={{
                    strokeColor: currentColor,
                    strokeWeight: 4,
                  }}
                />
              );
            } else if (obj.pos[0].lat !== 0 && obj.pos[1].lat === 0) {
              prevPosition = obj.pos;
              prevPositionIsvalidFalse = undefined;
              console.log("10");
            } else if (obj.pos[0].lat === 0 && obj.pos[1].lat !== 0) {
              if (prevPosition !== undefined) {
                prevPosition[1].lat = obj.pos[1].lat;
                prevPosition[1].lng = obj.pos[1].lng;
                console.log("01");
                return (
                  <Polyline
                    path={prevPosition}
                    options={{
                      strokeColor: currentColor,
                      strokeWeight: 4,
                    }}
                  />
                );
              }
            }
          }
        } else if (index !== 0 && obj.gvd === 0) {
          gpsValid = 0;
        }
      })}
      <Marker
        //position={startPosition === null ? props.markerPosition : startPosition}
        position={
          props.markerPosition.lat === 0 && startPosition !== null
            ? startPosition
            : props.markerPosition
        }
        defaultAnimation={google.maps.Animation.DROP}
        // options={{
        //   icon: {
        //     url: require("../../assets/images/Arrow_direction.png"),
        //     scaledSize: { width: 40, height: 40 },
        //     anchor: { x: 20, y: 20 },
        //   }
        // }}
        // draggable={true}
        options={{
          icon: {
            url: require("../../assets/images/Google_Train_icon.svg"),
            scale: 5,
            fillColor: "#2e92fa",
            fillOpacity: 0.9,
            strokeWeight: 0,
            anchor: { x: 15, y: 15 },
            //rotation: -45
          },
        }}
      >
        {/* {props.isOpen && (
          <InfoWindow>
            <span>
              {" "}
              {JSON.stringify(
                props.markerPosition.lat === 0 || props.markerPosition.lng === 0
                  ? props.positions[2].positions[1]
                  : props.markerPosition
              )}{" "}
            </span>
          </InfoWindow>
        )} */}
      </Marker>
    </GoogleMap>
  ))
);

const NoDashbordMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 46.948393, lng: 7.435987 }}
      //mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
      }}
    />
  ))
);

class DetailViewMap extends Component {
  constructor(props) {
    super(props);
    gpsValid = 1;
    prevPositionIsvalidFalse = undefined;
    prevPosition = undefined;
    startFlag = true;
    startPosition = null;
  }
  componentWillReceiveProps(nextProps) {
    gpsValid = 1;
    prevPositionIsvalidFalse = undefined;
    prevPosition = undefined;
    //startFlag = true;
    //startPosition = null;
  }
  shouldComponentUpdate() {
    if (sessionStorage.getItem("clickedTimeStampForRunSlider") !== null) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let value, selectedRun;
    selectedRun =
      this.props.selectedRun !== null
        ? this.props.selectedRun
        : this.props.mapData.runId === ""
        ? this.props.mapData.rns && this.props.mapData.rns[0].rid
        : this.props.mapData.runId && this.props.mapData.runId;
    value =
      this.props.mapData.rns &&
      this.props.mapData.rns.filter((item) => item.rid === selectedRun);

    console.log("sessionStorage.getItem", value, this.props.markerPosition);
    let nextValidGpsIndex = 0;
    if (
      this.props.mapData !== undefined &&
      this.props.mapData !== null &&
      value !== undefined &&
      value.length !== 0 &&
      sessionStorage.getItem("clickedTimeStampForRunSlider") !== null &&
      value[0].dat.findIndex(
        (obj) =>
          obj.ts ==
          Moment(
            sessionStorage.getItem("clickedTimeStampForRunSlider"),
            "DD.MM.YYYY, HH:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss")
      ) !== -1
    ) {
      for (
        var i = value[0].dat.findIndex(
          (obj) =>
            obj.ts ==
            Moment(
              sessionStorage.getItem("clickedTimeStampForRunSlider"),
              "DD.MM.YYYY, HH:mm:ss"
            ).format("YYYY-MM-DDTHH:mm:ss")
        );
        i < value[0].dat.length;
        i++
      ) {
        // console.log("valid gps", i);
        if (value[0].dat[i].gvd === 1) {
          nextValidGpsIndex = i;
          console.log("valid gps", nextValidGpsIndex);
          break;
        }
      }
    }

    return (
      <div id="multicolormap">
        {this.props.mapData !== undefined &&
        this.props.mapData !== null &&
        value !== undefined &&
        value.length !== 0 ? (
          <DetailViewGoogleMap
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `55vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markerPosition={
              sessionStorage.getItem("clickedTimeStampForRunSlider") !== null &&
              value[0].dat.findIndex(
                (obj) =>
                  obj.ts ==
                  Moment(
                    sessionStorage.getItem("clickedTimeStampForRunSlider"),
                    "DD.MM.YYYY, HH:mm:ss"
                  ).format("YYYY-MM-DDTHH:mm:ss")
              ) !== -1 &&
              value[0].dat[
                value[0].dat.findIndex(
                  (obj) =>
                    obj.ts ==
                    Moment(
                      sessionStorage.getItem("clickedTimeStampForRunSlider"),
                      "DD.MM.YYYY, HH:mm:ss"
                    ).format("YYYY-MM-DDTHH:mm:ss")
                )
              ].gvd !== 0
                ? value[0].dat[
                    value[0].dat.findIndex(
                      (obj) =>
                        obj.ts ==
                        Moment(
                          sessionStorage.getItem(
                            "clickedTimeStampForRunSlider"
                          ),
                          "DD.MM.YYYY, HH:mm:ss"
                        ).format("YYYY-MM-DDTHH:mm:ss")
                    )
                  ].pos[1]
                : sessionStorage.getItem("clickedTimeStampForRunSlider") !==
                    null &&
                  value[0].dat.findIndex(
                    (obj) =>
                      obj.ts ==
                      Moment(
                        sessionStorage.getItem("clickedTimeStampForRunSlider"),
                        "DD.MM.YYYY, HH:mm:ss"
                      ).format("YYYY-MM-DDTHH:mm:ss")
                  ) !== -1 &&
                  value[0].dat[
                    value[0].dat.findIndex(
                      (obj) =>
                        obj.ts ==
                        Moment(
                          sessionStorage.getItem(
                            "clickedTimeStampForRunSlider"
                          ),
                          "DD.MM.YYYY, HH:mm:ss"
                        ).format("YYYY-MM-DDTHH:mm:ss")
                    )
                  ].gvd === 0
                ? value[0].dat[nextValidGpsIndex].pos[1]
                : this.props.markerPosition.lat === 0 ||
                  this.props.markerPosition.lng === 0
                ? value[0].dat[0].gvd !== 0
                  ? value[0].dat[0].pos[1]
                  : this.props.markerPosition
                : this.props.markerPosition
            } //{this.props.markerPosition}
            positions={value[0].dat}
            basicFilter={this.props.basicFilter}
          />
        ) : (
          <div>
            <div className="nodatanotificationcontainer">
              <Alert variant="danger" id="nodatanotification" dismissible>
                <p className="nodatanotificationcontent">
                  No data recorded on selected day.
                </p>
              </Alert>
            </div>
            <div className="disabledcontent">
              <NoDashbordMap
                googleMapURL={googleMapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `55vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

DetailViewMap.defaultProps = {};

DetailViewMap.propTypes = {
  mapPath: PropTypes.any,
};

export default DetailViewMap;
