import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./styles.scss";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";

Moment.locale("en");
momentLocalizer();

// let startDate = new Date(Moment().subtract(91, "days"));
// let endDate = new Date(Moment().subtract(1, "days"));
// const getDatesRangeArray = (startDate, endDate, interval) => {
//   //console.log(startDate, endDate, interval);
//   let cfg = { interval: interval || "days" };
//   let dateArray = [];
//   let currentDate = Moment(startDate);
//   while (currentDate <= endDate) {
//     dateArray.push({ date: currentDate.format("YYYY-MM-DD") });
//     currentDate = currentDate.add(1, cfg.interval);
//   }

//   return dateArray;
// };

// var days = getDatesRangeArray(startDate, endDate);
// console.log("date list", days);

// const list = getDatesRangeArray(startDate, endDate);

const MenuItem = ({ text, totalRuns, selected }) => {
  return (
    <div
      className={`menu-item ${
        selected ? "active" : totalRuns === "No runs" ? "no-run" : ""
      }`}
    >
      <span>
        {text}
        <br />
        <span className="runcount">{totalRuns}</span>
      </span>
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { dt, trs } = el;

    return <MenuItem text={dt} totalRuns={trs} key={dt} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

export const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
export const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

/*function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
let selectedValue;
let menu;*/
export default class DateSlider extends Component {
  /*getDatesRangeArray = (startDate, endDate, interval) => {
    let dateArray = [],
      cfg = { interval: interval || "days" },
      i = 0;
    let currentDate = new Date(startDate);
    const diffDays = dateDiffInDays(new Date(startDate), new Date(endDate));
    while (i <= diffDays) {
      dateArray.push({
        date: Moment(currentDate).format("DD-MM-YYYY")
      });
      currentDate = Moment(currentDate).add(1, cfg.interval);
      i++;
    }
    return dateArray;
  };*/

  constructor(props) {
    super(props);
    /*this.dateList = this.getDatesRangeArray(
      this.props.startDate.replace(/%3A/g, ":"),
      this.props.endDate.replace(/%3A/g, ":")
    );*/
    this.state = {
      dynamicDatesList: this.props.mapTotalRuns.dat,
      alignCenter: true,
      clickWhenDrag: false,
      dragging: true,
      hideArrows: true,
      hideSingleArrow: true,
      itemsCount: this.props.mapTotalRuns.dat.length,
      selected: this.props.mapTotalRuns.dat[
        this.props.mapTotalRuns.dat.length - 1
      ].dt,
      //this.props.mapTotalRuns.data[this.props.mapTotalRuns.data.length - 1].date
      translate: 0,
      transition: 0.3,
      wheel: true,
      //menuItems: Menu(this.dateList.slice(0, this.dateList.length))
      menuItems: Menu(this.props.mapTotalRuns.dat),
    };
    this.menu = null;
    // selectedValue =
    //   this.props.selectedDate != null && this.props.selectedDate != undefined
    //     ? this.props.selectedDate
    //     : this.props.mapTotalRuns.data[this.props.mapTotalRuns.data.length - 1]
    //         .date;
    // menu = Menu(this.props.mapTotalRuns.data, selectedValue);
  }

  //componentWillReceiveProps(nextProps) {
  /*if (
      this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate
    ) {
      let newDateList = this.getDatesRangeArray(
        nextProps.startDate.replace(/%3A/g, ":"),
        nextProps.endDate.replace(/%3A/g, ":")
      );
      this.setState({
        dynamicDatesList: this.props.mapTotalRuns.data,
        itemsCount: this.props.mapTotalRuns.data.length,

        selected: this.props.mapTotalRuns.data[
          this.props.mapTotalRuns.data.length - 1
        ].date,
        menuItems: Menu(this.props.mapTotalRuns.data)
      });
    }*/
  // selectedValue =
  //   this.props.selectedDate != null && this.props.selectedDate != undefined
  //     ? this.props.selectedDate
  //     : this.props.mapTotalRuns.data[this.props.mapTotalRuns.data.length - 1]
  //         .date;
  // menu = Menu(this.props.mapTotalRuns.data, selectedValue);
  //}

  // onUpdate = ({ translate }) => {
  //   console.log(`onUpdate: translate: ${translate}`);
  //   this.setState({ translate });
  // };

  /*onSelect = key => {
    console.log(`onSelect: ${key}`);
    this.setState({ selected: key });
  };*/

  // componentDidUpdate(prevProps, prevState) {
  //   const { alignCenter } = prevState;
  //   const { alignCenter: alignCenterNew } = this.state;

  //   this.menu.setInitial();
  // }

  // setItemsCount = ev => {
  //   const { itemsCount = this.dynamicDateList.length, selected } = this.state;
  //   const val = +ev.target.value;
  //   const itemsCountNew =
  //     !isNaN(val) && val <= this.dynamicDateList.length && val >= 0
  //       ? +ev.target.value
  //       : this.dynamicDateList.length;
  //   const itemsCountChanged = itemsCount !== itemsCountNew;

  //   if (itemsCountChanged) {
  //     this.menuItems = Menu(
  //       this.dynamicDateList.slice(0, itemsCountNew),
  //       selected
  //     );
  //     this.setState({
  //       itemsCount: itemsCountNew
  //     });
  //   }
  // };

  // setSelected = ev => {
  //   const { value } = ev.target;
  //   this.setState({ selected: String(value) });
  // };

  render() {
    const {
      alignCenter,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      itemsCount,
      selected,
      translate,
      transition,
      wheel,
    } = this.state;

    //menu = Menu(this.props.mapTotalRuns.data, selectedValue); //this.state.menuItems;
    const menu = Menu(this.props.mapTotalRuns.dat);

    return (
      <div className="dateSlider">
        <ScrollMenu
          ref={(el) => (this.menu = el)}
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          hideArrows={false}
          hideSingleArrow="false"
          transition={+transition}
          // onUpdate={this.onUpdate}
          onSelect={this.props.onMapDateSelect}
          selected={
            this.props.selectedDate != null
              ? this.props.selectedDate
              : sessionStorage.getItem("clickedTimeStamp") === null
              ? this.props.mapTotalRuns.dat[
                  this.props.mapTotalRuns.dat.length - 1
                ].dt
              : Moment(
                  sessionStorage.getItem("clickedTimeStamp"),
                  "DD.MM.YYYY"
                ).format("D-M-YYYY")
          }
          translate={translate}
          alignCenter={false}
          scrollToSelected={true}
          scrollBy={2}
          //dragging={dragging}
          //clickWhenDrag={clickWhenDrag}
          // wheel={wheel}
        />
        {/* Selected Date : {this.state.selected} */}
      </div>
    );
  }
}
