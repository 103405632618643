//Library
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  loadingSelector,
  loginresponceSelector,
  logincheckSelector,
  loadingchangepassSelector,
  changepassresponceSelector
} from "../Login/selectors";
import Header from "../../components/Header/Header";
import { loggedIn, changePassword } from "../Login/actions";
import { AESEncrypt } from "../Login/aes-encryption";

class HeaderPage extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showmodal: false,
      currentpass: "",
      newpassword: "",
      repeatepass: "",
      currentPassError: "",
      newPassError: "",
      repeatenewPassError: "",
      apiResponseMessage: "",
      changePassRemainder: 0
    };
  }

  // componentDidMount() {
  //   if (
  //     this.props.loginResponce != undefined &&
  //     this.props.loginResponce.isFirstTimeLogin === true
  //   ) {
  //     alert();
  //     this.setState({
  //       showmodal: true
  //     });
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.changePassResponce != undefined &&
      nextProps.changePassResponce.responseMessage
    ) {
      this.setState({
        apiResponseMessage: nextProps.changePassResponce.responseMessage
      });
    }
    if (
      this.props.loginResponce != undefined &&
      this.props.loginResponce.isFirstTimeLogin === true &&
      this.state.changePassRemainder === 0
    ) {
      this.setState({
        showmodal: true,
        changePassRemainder: 1
      });
    }
    // if (
    //   this.props.changePassResponce != undefined &&
    //   this.props.changePassResponce.isFirstTimeLogin === false
    // ) {
    //   this.setState({
    //     showmodal: false
    //   });
    // }
  }

  logout = () => {
    window.location.href = "/";
    this.props.loggedIn(false);
    //sessionStorage.setItem("token", AESEncrypt(false));
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("givenname");
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
    localStorage.removeItem("startDateCalender");
    localStorage.removeItem("endDateCalender");
  };

  redirectToHome = () => {
    this.props.history.push("/Dashboard");
  };

  showChangePasswordmodal = show => {
    this.setState({
      showmodal: show,
      currentpass: "",
      newpassword: "",
      repeatepass: "",
      currentPassError: "",
      newPassError: "",
      repeatenewPassError: "",
      apiResponseMessage: ""
    });
  };

  handleInputChange = e => {
    if (e !== undefined) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  };

  changePasswordClick = () => {
    if (this.state.currentpass === "") {
      this.setState({
        currentPassError: "Current password is required",
        newPassError: "",
        repeatenewPassError: ""
      });
    } else if (this.state.newpassword === "") {
      this.setState({
        currentPassError: "",
        newPassError: "New password is required",
        repeatenewPassError: ""
      });
    } else if (this.state.newpassword.length < 5) {
      this.setState({
        currentPassError: "",
        newPassError: "Password must be between 5 and 30 characters",
        repeatenewPassError: ""
      });
    } else if (this.state.repeatepass === "") {
      this.setState({
        currentPassError: "",
        newPassError: "",
        repeatenewPassError: "Repeate new password is required"
      });
    } else if (this.state.repeatepass !== this.state.newpassword) {
      this.setState({
        currentPassError: "",
        newPassError: "",
        repeatenewPassError:
          "Repeate new password should be same as new password"
      });
    } else {
      //send the values to the backend
      //also reset both nameError and ageError here
      // const encryptedCurrentPass = md5(this.state.currentpass);
      // const encryptedNewPass = md5(this.state.newpassword);
      // console.log(
      //   "checking encrypted value",
      //   encryptedCurrentPass,
      //   encryptedNewPass
      // );

      // this.props.changePassword(encryptedCurrentPass, encryptedNewPass);  encrypted password
      if (this.props.loginResponce !== null) {
        this.props.changePassword(
          this.props.loginResponce.userName,
          AESEncrypt(this.state.currentpass),
          AESEncrypt(this.state.newpassword)
        );

        this.setState({
          currentpass: "",
          newpassword: "",
          repeatepass: "",
          currentPassError: "",
          newPassError: "",
          repeatenewPassError: ""
        });
      } else {
        this.setState({
          currentpass: "",
          newpassword: "",
          repeatepass: "",
          currentPassError: "",
          newPassError: "",
          repeatenewPassError:
            "Opps something went wrong please login again and then try to change password."
        });
      }
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.changePasswordClick();
    }
  };
  render() {
    console.log("pass change responce", this.props.loginResponce);
    const { location } = this.props;
    if (
      location.pathname.match("/login") ||
      location.pathname.match("/networkError") ||
      location.pathname.match("/serverError")
    ) {
      return null;
    }
    return (
      <Header
        handleLogoutClick={this.logout}
        loginResponce={this.props.loginResponce}
        redirectToHome={this.redirectToHome}
        showChangePasswordmodal={this.showChangePasswordmodal}
        showmodal={this.state.showmodal}
        changePasswordClick={this.changePasswordClick}
        handleInputChange={this.handleInputChange}
        changePassResponce={this.props.changePassResponce}
        currentPassError={this.state.currentPassError}
        newPassError={this.state.newPassError}
        repeatenewPassError={this.state.repeatenewPassError}
        apiResponseMessage={this.state.apiResponseMessage}
        formValues={this.state}
        loading={this.props.loading}
        handleKeyPress={this.handleKeyPress}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: loadingchangepassSelector(),
  loginResponce: loginresponceSelector(),
  // logincheck: logincheckSelector(),
  changePassResponce: changepassresponceSelector()
});
const mapDispatchToProps = {
  loggedIn: loggedIn,
  changePassword: changePassword
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderPage)
);
