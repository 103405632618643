import React from "react";
import { Row, Col, Button } from "react-bootstrap";
// Not found page component
export default class NotFound extends React.Component {
  // render
  render() {
    return (
      <div>
        <Col md={12}>
          <div className="page-content-div">
            <h1> Page not found. </h1>
          </div>
        </Col>
      </div>
    );
  }
}
