// /*global google*/
import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
} from "react-google-maps";
import PropTypes from "prop-types";
import {
  googleMapURL,
  googleMapCustomStyle,
} from "../Googlemapkey/dashboardMapConfig";

const DashbordMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      // defaultCenter={
      //   props.mapPath[0].locomotiveRuns[0].data[
      //     Math.round(props.mapPath[0].locomotiveRuns[0].data.length / 2)
      //   ]
      // }
      defaultCenter={{ lat: 46.94809, lng: 7.44744 }}
      disableDefaultUI={true}
      mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
        draggableCursor: "move",
      }}
    >
      {props.mapPath.map((object, i) =>
        object.rns.map((routeposition, i) => (
          <Polyline
            key={i}
            path={routeposition.dat}
            onMouseOver={() => props.onHover(routeposition.nm, true)}
            onMouseOut={() => props.onHoverOut()}
            options={{
              strokeColor:
                props.routeSeleted == routeposition.nm
                  ? props.routeColorHover
                  : props.routeColor,
              strokeWeight: 4,
              zIndex: props.routeSeleted == routeposition.nm ? 10 : 1,
            }}
          />
        ))
      )}
    </GoogleMap>
  ))
);

const NoDashbordMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 46.948393, lng: 7.435987 }}
      // mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
      }}
    />
  ))
);

class DashboardMap extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     lineColor: "#fff",
  //     lineColorHover: "#6eb0f3",
  //     lineSeleted: ""
  //   };
  // }

  // onMouseOverEvent = currentLine => {
  //   this.setState({ lineSeleted: currentLine });
  // };

  // onMouseOutEvent = () => {
  //   this.setState({ lineSeleted: "" });
  // };
  excludeInvalidGps = () => {
    let filteredArray = this.props.mapPath
      .filter((element) =>
        element.data.some((subElement) => subElement.gpsValid == 1)
      )
      .map((element) => {
        return Object.assign({}, element, {
          data: element.data.filter((subElement) => subElement.gpsValid == 1),
        });
      });

    return filteredArray;
  };

  render() {
    //console.log("gps data in map view", this.excludeInvalidGps());
    return (
      <div>
        {this.props.mapPath !== undefined &&
        this.props.mapPath !== null &&
        this.props.mapPath.length > 0 ? (
          <DashbordMap
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `75vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onHover={this.props.onHover}
            onHoverOut={this.props.onHoverOut}
            routeColor={this.props.routeColor}
            routeColorHover={this.props.routeColorHover}
            routeSeleted={this.props.routeSeleted}
            mapPath={
              this.props.mapPath !== undefined && this.props.mapPath !== null
                ? this.props.mapPath
                : ""
            }
          />
        ) : (
          <div>
            <NoDashbordMap
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `75vh` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        )}
      </div>
    );
  }
}

DashboardMap.defaultProps = {};

DashboardMap.propTypes = {
  mapPath: PropTypes.any,
};
export default DashboardMap;
