import React, { Component } from "react";
import "./styles.scss";
import Col from "react-bootstrap/Col";
import { Slider, Rail, Handles } from "react-compound-slider";
import { Row } from "react-bootstrap";
import Moment from "moment";

export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  mapData,
  basicFilter,
  unit,
}) {
  //let leftMargin = percent === 100 ? 99.5427 : percent; //temp(Want to remove after time arrow icon change)
  //let changeiconmargin = percent >= 100 ? "changeiconmargin" : "";
  let changetextdirection = percent >= 80 ? "timearrowtext" : "";
  let km = mapData.dat[value];
  km = basicFilter.bogie == 3 ? km.ava : km.avg;
  let formaTdate = Moment.utc(mapData.dat[value].ts).format(
    "DD.MM.YYYY HH:mm:ss"
  );

  return (
    <div
      className="dragIcon"
      style={{
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    >
      <div
        className="selectedInterval disable-selection"
        id={changetextdirection}
      >
        {formaTdate + " " + km + "" + unit}
      </div>
    </div>
  );
}
export default class DetailViewMapSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInterval: 0,
    };
  }
  componentDidMount() {
    console.log(
      "mila",
      this.props.mapData.dat.findIndex(
        (obj) =>
          obj.ts ==
          Moment(
            sessionStorage.getItem("clickedTimeStampForRunSlider"),
            "DD.MM.YYYY, HH:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss")
      )
    );
    if (
      sessionStorage.getItem("clickedTimeStampForRunSlider") !== null &&
      this.props.mapData.dat.findIndex(
        (obj) =>
          obj.ts ==
          Moment(
            sessionStorage.getItem("clickedTimeStampForRunSlider"),
            "DD.MM.YYYY, HH:mm:ss"
          ).format("YYYY-MM-DDTHH:mm:ss")
      ) !== -1
    ) {
      this.setState({
        selectedInterval: this.props.mapData.dat.findIndex(
          (obj) =>
            obj.ts ==
            Moment(
              sessionStorage.getItem("clickedTimeStampForRunSlider"),
              "DD.MM.YYYY, HH:mm:ss"
            ).format("YYYY-MM-DDTHH:mm:ss")
        ),
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.disValue === true &&
      sessionStorage.getItem("clickedTimeStampForRunSlider") === null
    ) {
      this.setState({ selectedInterval: 0 });
    }
  }
  onChange = (value) => {
    this.setState({
      selectedInterval: value[0],
    });
  };

  render() {
    const {
      selectedRun,
      mapData,
      onUpdate,
      basicFilter,
      unit,
      disValue,
    } = this.props;
    const min = 0,
      max = this.props.mapData.dat.length - 1;
    console.log(
      "checking state",
      this.state.selectedInterval,
      this.props.mapData.dat
    );
    return (
      <div className="detailviewmapslidercontainer">
        <Row>
          <Col md={2}>
            <div className="text">
              {selectedRun}
              <div className="runTime">
                {Moment.utc(mapData.dat[0].ts).format("HH:mm") + " - "}
                {Moment.utc(mapData.dat[mapData.dat.length - 1].ts).format(
                  "HH:mm"
                )}
              </div>
            </div>
          </Col>
          <Col md={10}>
            <span>
              <Slider
                className="sliderStyle"
                domain={[min, max]}
                step={1}
                mode={2}
                values={[this.state.selectedInterval]}
                onUpdate={onUpdate}
                onChange={this.onChange}
                disabled={disValue}
              >
                <Rail>
                  {({ getRailProps }) => {
                    let colorArea = 100 / mapData.dat.length;
                    let currentColor, operation;
                    return (
                      <div className="colorarea">
                        {mapData.dat.map(
                          (item) => (
                            (currentColor =
                              basicFilter.bogie === 3 ? item.cla : item.clr),
                            (currentColor === "green" &&
                              (currentColor = "#0CA919"),
                            currentColor === "yellow" &&
                              (currentColor = "#FFD800"),
                            currentColor === "red" &&
                              (currentColor = "#F03040"),
                            currentColor === "gray" &&
                              (currentColor = "#545454")),
                            (operation =
                              basicFilter.operation &&
                              !basicFilter.operation.find(
                                (val) => val == item.ftr.opm
                              )
                                ? "LightGray"
                                : ""),
                            (operation != "" || item.gvd === 0) &&
                              (currentColor = "LightGray"),
                            (
                              <div
                                style={{
                                  width: colorArea + "%",
                                  backgroundColor: "#ABD4FD",
                                  /*mapData.data.length > 300
                                      ? "LightGray"
                                      : currentColor*/
                                }}
                                className="railStyle"
                                id={mapData.dat.length}
                              />
                            )
                          )
                        )}
                      </div>
                    );
                  }}
                </Rail>
                <Handles>
                  {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                      {handles.map((handle) => (
                        <Handle
                          key={handle.id}
                          handle={handle}
                          getHandleProps={getHandleProps}
                          mapData={mapData}
                          basicFilter={basicFilter}
                          unit={unit}
                        />
                      ))}
                    </div>
                  )}
                </Handles>
              </Slider>
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
