import debug from 'debug';
export const ERROR_401 = 'RcmMetricUI/utils/ERROR_401';

export const returnData = (axiosResponse) => { // eslint-disable-line
  return axiosResponse.data.payload ? axiosResponse.data.payload : axiosResponse.data;
};

export const handleError = (error, failedAction) => {
  debug('dev')('Failed Action:', failedAction);
  const { error401, otherError } = parseError(error);
  if (error401) {
    return {
      type: ERROR_401,
    };
  }
  return failedAction(otherError);
};

export const parseError = (err) => {
  debug('dev')('parseError', err); // eslint-disable-line no-console
  if (err.config) {
    debug('dev')('parseError:url', err.config.url); // eslint-disable-line no-console
  }
  if (err && err.status === 401) return { error401: true };
  return (err instanceof Error) ? {
    otherError: {
      jsError: err.toString(),
    },
  } : {
    otherError: {
      ...err,
    },
  };
};
