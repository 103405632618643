import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./containers/DashBoard/";
import NotFound from "./components/NotFound";
import NetworkError from "./containers/ErrorPages/NetworkError";
import ServerError from "./containers/ErrorPages/ServerError";
import DetailTrainPage from "./containers/DetailTrainPage";
import TrainViewPage from "./containers/TrainViewPage";
import LoginPage from "./containers/Login";
import HeaderPage from "./containers/Header";
import { logincheckSelector } from "./containers/Login/selectors";

// build the router
class Routes extends React.Component {
  render() {
    console.log("in router", this.props.logincheck);
    return (
      <Router>
        <Fragment>
          <header>
            <HeaderPage />
          </header>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <PrivateRoute
              exact
              path="/dashboard"
              component={Dashboard}
              isAuthenticated={this.props.logincheck}
            />
            <Route exact path="/networkError" component={NetworkError} />
            <Route exact path="/serverError" component={ServerError} />
            <PrivateRoute
              exact
              path="/TrainViewPage"
              component={TrainViewPage}
              isAuthenticated={this.props.logincheck}
            />
            <PrivateRoute
              exact
              path="/DetailTrainPage"
              component={DetailTrainPage}
              isAuthenticated={this.props.logincheck}
            />
            <PrivateRoute exact path="/" component={LoginPage} />
            <Route path="**" component={NotFound} />
          </Switch>
        </Fragment>
      </Router>
    );
  }
}

// export
const mapStateToProps = createStructuredSelector({
  logincheck: logincheckSelector()
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);

//export default Routes;
