import { createSelector } from "reselect";

const selectDetailView = () => (state) => state.get("detailViewData");

const selectCommonDetails = () =>
  createSelector(selectDetailView(), (state) => state.get("reducerCommon"));

const selectMapDetails = () =>
  createSelector(selectDetailView(), (state) => state.get("reducerMap"));

const selectTimetrendDetails = () =>
  createSelector(selectDetailView(), (state) => state.get("reducerTimetrend"));

const basicFilterSelector = () =>
  createSelector(selectCommonDetails(), (state) => state.get("filter"));

const advancedFilterSelector = () =>
  createSelector(selectCommonDetails(), (state) => state.get("filterAdvanced"));

const loadingTimetrendSelector = () =>
  createSelector(selectTimetrendDetails(), (state) => state.get("loading"));

const selectedMainTrendSelector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("selectedMainTrend")
  );

const selectedOverlaysTrendSelector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("selectedOverlaysTrend")
  );

const timetrendSlip1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSlip1")
  );

const timetrendSlip2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSlip2")
  );

const timetrendSpeed1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSpeed1")
  );

const timetrendSpeed2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSpeed2")
  );

const timetrendEffort1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffort1")
  );

const timetrendEffort2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffort2")
  );

const timetrendEffortDriving1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortDriving1")
  );

const timetrendEffortDriving2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortDriving2")
  );

const timetrendEffortBraking1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBraking1")
  );

const timetrendEffortBraking2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBraking2")
  );

const timetrendEffortBrakingMech1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBrakingMech1")
  );

const timetrendEffortBrakingMech2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBrakingMech2")
  );

const ttDrivingTrailerVel1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataDrivingTrailerVel1")
  );
const ttEffortReference1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataEffortReference1")
  );
const ttEffortReference2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataEffortReference2")
  );
const ttSlipPower1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataSlipPower1")
  );
const ttSlipPower2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataSlipPower2")
  );
const ttPowerBrakingMechanical1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataPowerBrakingMechanical1")
  );
const ttPowerBrakingMechanical2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataPowerBrakingMechanical2")
  );
const ttAirPressureBrakeCylinder1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataAirPressureBrakeCylinder1")
  );
const ttAirPressureBrakeCylinder2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataAirPressureBrakeCylinder2")
  );
const ttAmplitudeTorsionalOscillation1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataAmplitudeTorsionalOscillation1")
  );
const ttAmplitudeTorsionalOscillation2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataAmplitudeTorsionalOscillation2")
  );

const timetrendSelector = () =>
  createSelector(
    selectedMainTrendSelector(),
    selectedOverlaysTrendSelector(),
    timetrendSlip1Selector(),
    timetrendSlip2Selector(),
    timetrendSpeed1Selector(),
    timetrendSpeed2Selector(),
    timetrendEffort1Selector(),
    timetrendEffort2Selector(),
    timetrendEffortDriving1Selector(),
    timetrendEffortDriving2Selector(),
    timetrendEffortBraking1Selector(),
    timetrendEffortBraking2Selector(),
    timetrendEffortBrakingMech1Selector(),
    timetrendEffortBrakingMech2Selector(),
    ttDrivingTrailerVel1Selector(),
    ttEffortReference1Selector(),
    ttEffortReference2Selector(),
    ttSlipPower1Selector(),
    ttSlipPower2Selector(),
    ttPowerBrakingMechanical1Selector(),
    ttPowerBrakingMechanical2Selector(),
    ttAirPressureBrakeCylinder1Selector(),
    ttAirPressureBrakeCylinder2Selector(),
    ttAmplitudeTorsionalOscillation1Selector(),
    ttAmplitudeTorsionalOscillation2Selector(),
    (
      selectedMain,
      selectedOverlays,
      slip1,
      slip2,
      speed1,
      speed2,
      effort1,
      effort2,
      effortDriving1,
      effortDriving2,
      effortBraking1,
      effortBraking2,
      effortBrakingMech1,
      effortBrakingMech2,
      drivingTrailerVel1,
      effortReference1,
      effortReference2,
      slipPower1,
      slipPower2,
      powerBrakingMechanical1,
      powerBrakingMechanical2,
      airPressureBrakeCylinder1,
      airPressureBrakeCylinder2,
      amplitudeTorsionalOscillation1,
      amplitudeTorsionalOscillation2
    ) => {
      let chartValues = [];
      if (
        slip1 != null &&
        slip2 != null &&
        speed1 != null &&
        speed2 != null &&
        effort1 != null &&
        effort2 != null &&
        /*effortDriving1 != null &&
        effortDriving2 != null &&
        effortBraking1 != null &&
        effortBraking2 != null &&*/
        effortBrakingMech1 != null &&
        effortBrakingMech2 != null &&
        drivingTrailerVel1 != null &&
        effortReference1 != null &&
        effortReference2 != null &&
        slipPower1 != null &&
        slipPower2 != null &&
        powerBrakingMechanical1 != null &&
        powerBrakingMechanical2 != null &&
        airPressureBrakeCylinder1 != null &&
        airPressureBrakeCylinder2 != null &&
        amplitudeTorsionalOscillation1 != null &&
        amplitudeTorsionalOscillation2 != null
      ) {
        chartValues.push({
          config: {
            label: "Slip B1",
            unit: slip1.cfg.unt,
            samptime: slip1.rsi,
          },
          data: slip1.dat,
        });
        chartValues.push({
          config: {
            label: "Slip B2",
            unit: slip2.cfg.unt,
            samptime: slip2.rsi,
          },
          data: slip2.dat,
        });
        chartValues.push({
          config: {
            label: "Speed at Wheel B1",
            unit: speed1.cfg.unt,
            samptime: speed1.rsi,
          },
          data: speed1.dat,
        });
        chartValues.push({
          config: {
            label: "Speed at Wheel B2",
            unit: speed2.cfg.unt,
            samptime: speed2.rsi,
          },
          data: speed2.dat,
        });

        chartValues.push({
          config: {
            label: "Effort B1",
            unit: effort1.cfg.unt,
            samptime: effort1.rsi,
          },
          data: effort1.dat,
        });
        chartValues.push({
          config: {
            label: "Effort B2",
            unit: effort2.cfg.unt,
            samptime: effort2.rsi,
          },
          data: effort2.dat,
        });

        /*chartValues.push({
          config: {
            label: "EffortDriving B1",
            unit: effortDriving1.cfg.unt,
            samptime: effortDriving1.rsi,
          },
          data: effortDriving1.dat,
        });
        chartValues.push({
          config: {
            label: "EffortDriving B2",
            unit: effortDriving2.cfg.unt,
            samptime: effortDriving2.rsi,
          },
          data: effortDriving2.dat,
        });
        chartValues.push({
          config: {
            label: "EffortBraking B1",
            unit: effortBraking1.cfg.unt,
            samptime: effortBraking1.rsi,
          },
          data: effortBraking1.dat,
        });
        chartValues.push({
          config: {
            label: "EffortBraking B2",
            unit: effortBraking2.cfg.unt,
            samptime: effortBraking2.rsi,
          },
          data: effortBraking2.dat,
        });*/
        chartValues.push({
          config: {
            label: "Effort Mech. Brake B1",
            unit: effortBrakingMech1.cfg.unt,
            samptime: effortBrakingMech1.rsi,
          },
          data: effortBrakingMech1.dat,
        });
        chartValues.push({
          config: {
            label: "Effort Mech. Brake B2",
            unit: effortBrakingMech2.cfg.unt,
            samptime: effortBrakingMech2.rsi,
          },
          data: effortBrakingMech2.dat,
        });

        chartValues.push({
          config: {
            label: "Speed Driving Trailer B1",
            unit: drivingTrailerVel1.cfg.unt,
            samptime: drivingTrailerVel1.rsi,
          },
          data: drivingTrailerVel1.dat,
        });
        chartValues.push({
          config: {
            label: "Speed Driving Trailer B2",
            unit: drivingTrailerVel1.cfg.unt,
            samptime: drivingTrailerVel1.rsi,
          },
          data: drivingTrailerVel1.dat,
        });
        chartValues.push({
          config: {
            label: "Effort Reference B1",
            unit: effortReference1.cfg.unt,
            samptime: effortReference1.rsi,
          },
          data: effortReference1.dat,
        });
        chartValues.push({
          config: {
            label: "Effort Reference B2",
            unit: effortReference2.cfg.unt,
            samptime: effortReference2.rsi,
          },
          data: effortReference2.dat,
        });
        chartValues.push({
          config: {
            label: "Power Slip B1",
            unit: slipPower1.cfg.unt,
            samptime: slipPower1.rsi,
          },
          data: slipPower1.dat,
        });
        chartValues.push({
          config: {
            label: "Power Slip B2",
            unit: slipPower2.cfg.unt,
            samptime: slipPower2.rsi,
          },
          data: slipPower2.dat,
        });
        chartValues.push({
          config: {
            label: "Power Mechanical Brake B1",
            unit: powerBrakingMechanical1.cfg.unt,
            samptime: powerBrakingMechanical1.rsi,
          },
          data: powerBrakingMechanical1.dat,
        });
        chartValues.push({
          config: {
            label: "Power Mechanical Brake B2",
            unit: powerBrakingMechanical2.cfg.unt,
            samptime: powerBrakingMechanical2.rsi,
          },
          data: powerBrakingMechanical2.dat,
        });
        chartValues.push({
          config: {
            label: "Pressure Brake Cyl. B1",
            unit: airPressureBrakeCylinder1.cfg.unt,
            samptime: airPressureBrakeCylinder1.rsi,
          },
          data: airPressureBrakeCylinder1.dat,
        });
        chartValues.push({
          config: {
            label: "Pressure Brake Cyl. B2",
            unit: airPressureBrakeCylinder2.cfg.unt,
            samptime: airPressureBrakeCylinder2.rsi,
          },
          data: airPressureBrakeCylinder2.dat,
        });
        chartValues.push({
          config: {
            label: "Torsional Osc. Ampl. B1",
            unit: amplitudeTorsionalOscillation1.cfg.unt,
            samptime: amplitudeTorsionalOscillation1.rsi,
          },
          data: amplitudeTorsionalOscillation1.dat,
        });
        chartValues.push({
          config: {
            label: "Torsional Osc. Ampl. B2",
            unit: amplitudeTorsionalOscillation2.cfg.unt,
            samptime: amplitudeTorsionalOscillation2.rsi,
          },
          data: amplitudeTorsionalOscillation2.dat,
        });
      } else {
        let result = [];
        selectedMain !== null && result.push(selectedMain);
        selectedOverlays !== null && result.push(...selectedOverlays);

        result.map((value) => {
          switch (value) {
            case "Slip":
              if (slip1 !== null && slip1 !== undefined && slip1.length !== 0) {
                chartValues.push({
                  config: {
                    label: "Slip B1",
                    unit: slip1.cfg.unt,
                    samptime: slip1.rsi,
                  },
                  data: slip1.dat,
                });
              }
              if (slip2 !== null && slip2 !== undefined && slip2.length !== 0) {
                chartValues.push({
                  config: {
                    label: "Slip B2",
                    unit: slip2.cfg.unt,
                    samptime: slip2.rsi,
                  },
                  data: slip2.dat,
                });
              }
              break;

            case "Speed at Wheel":
              if (
                speed1 !== null &&
                speed1 !== undefined &&
                speed1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Speed at Wheel B1",
                    unit: speed1.cfg.unt,
                    samptime: speed1.rsi,
                  },
                  data: speed1.dat,
                });
              }
              if (
                speed2 !== null &&
                speed2 !== undefined &&
                speed2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Speed at Wheel B2",
                    unit: speed2.cfg.unt,
                    samptime: speed2.rsi,
                  },
                  data: speed2.dat,
                });
              }
              break;

            case "Effort":
              if (
                effort1 !== null &&
                effort1 !== undefined &&
                effort1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort B1",
                    unit: effort1.cfg.unt,
                    samptime: effort1.rsi,
                  },
                  data: effort1.dat,
                });
              }
              if (
                effort2 !== null &&
                effort2 !== undefined &&
                effort2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort B2",
                    unit: effort2.cfg.unt,
                    samptime: effort2.rsi,
                  },
                  data: effort2.dat,
                });
              }
              break;

            /*case "EffortDriving":
              if (
                effortDriving1 !== null &&
                effortDriving1 !== undefined &&
                effortDriving1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "EffortDriving B1",
                    unit: effortDriving1.cfg.unt,
                    samptime: effortDriving1.rsi,
                  },
                  data: effortDriving1.dat,
                });
              }
              if (
                effortDriving2 !== null &&
                effortDriving2 !== undefined &&
                effortDriving2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "EffortDriving B2",
                    unit: effortDriving2.cfg.unt,
                    samptime: effortDriving2.rsi,
                  },
                  data: effortDriving2.dat,
                });
              }
              break;

            case "EffortBraking":
              if (
                effortBraking1 !== null &&
                effortBraking1 !== undefined &&
                effortBraking1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "EffortBraking B1",
                    unit: effortBraking1.cfg.unt,
                    samptime: effortBraking1.rsi,
                  },
                  data: effortBraking1.dat,
                });
              }
              if (
                effortBraking2 !== null &&
                effortBraking2 !== undefined &&
                effortBraking2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "EffortBraking B2",
                    unit: effortBraking2.cfg.unt,
                    samptime: effortBraking2.rsi,
                  },
                  data: effortBraking2.dat,
                });
              }
              break;*/

            case "Effort Mech. Brake":
              if (
                effortBrakingMech1 !== null &&
                effortBrakingMech1 !== undefined &&
                effortBrakingMech1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort Mech. Brake B1",
                    unit: effortBrakingMech1.cfg.unt,
                    samptime: effortBrakingMech1.rsi,
                  },
                  data: effortBrakingMech1.dat,
                });
              }
              if (
                effortBrakingMech2 !== null &&
                effortBrakingMech2 !== undefined &&
                effortBrakingMech2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort Mech. Brake B2",
                    unit: effortBrakingMech2.cfg.unt,
                    samptime: effortBrakingMech2.rsi,
                  },
                  data: effortBrakingMech2.dat,
                });
              }
              break;

            case "Speed Driving Trailer":
              if (
                drivingTrailerVel1 !== null &&
                drivingTrailerVel1 !== undefined &&
                drivingTrailerVel1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Speed Driving Trailer B1",
                    unit: drivingTrailerVel1.cfg.unt,
                    samptime: drivingTrailerVel1.rsi,
                  },
                  data: drivingTrailerVel1.dat,
                });
              }
              if (
                drivingTrailerVel1 !== null &&
                drivingTrailerVel1 !== undefined &&
                drivingTrailerVel1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Speed Driving Trailer B2",
                    unit: drivingTrailerVel1.cfg.unt,
                    samptime: drivingTrailerVel1.rsi,
                  },
                  data: drivingTrailerVel1.dat,
                });
              }
              break;

            case "Effort Reference":
              if (
                effortReference1 !== null &&
                effortReference1 !== undefined &&
                effortReference1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort Reference B1",
                    unit: effortReference1.cfg.unt,
                    samptime: effortReference1.rsi,
                  },
                  data: effortReference1.dat,
                });
              }
              if (
                effortReference2 !== null &&
                effortReference2 !== undefined &&
                effortReference2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Effort Reference B2",
                    unit: effortReference2.cfg.unt,
                    samptime: effortReference2.rsi,
                  },
                  data: effortReference2.dat,
                });
              }
              break;

            case "Power Slip":
              if (
                slipPower1 !== null &&
                slipPower1 !== undefined &&
                slipPower1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Power Slip B1",
                    unit: slipPower1.cfg.unt,
                    samptime: slipPower1.rsi,
                  },
                  data: slipPower1.dat,
                });
              }
              if (
                slipPower2 !== null &&
                slipPower2 !== undefined &&
                slipPower2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Power Slip B2",
                    unit: slipPower2.cfg.unt,
                    samptime: slipPower2.rsi,
                  },
                  data: slipPower2.dat,
                });
              }
              break;

            case "Power Mechanical Brake":
              if (
                powerBrakingMechanical1 !== null &&
                powerBrakingMechanical1 !== undefined &&
                powerBrakingMechanical1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Power Mechanical Brake B1",
                    unit: powerBrakingMechanical1.cfg.unt,
                    samptime: powerBrakingMechanical1.rsi,
                  },
                  data: powerBrakingMechanical1.dat,
                });
              }
              if (
                powerBrakingMechanical2 !== null &&
                powerBrakingMechanical2 !== undefined &&
                powerBrakingMechanical2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Power Mechanical Brake B2",
                    unit: powerBrakingMechanical2.cfg.unt,
                    samptime: powerBrakingMechanical2.rsi,
                  },
                  data: powerBrakingMechanical2.dat,
                });
              }
              break;
            case "Pressure Brake Cyl.":
              if (
                airPressureBrakeCylinder1 !== null &&
                airPressureBrakeCylinder1 !== undefined &&
                airPressureBrakeCylinder1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Pressure Brake Cyl. B1",
                    unit: airPressureBrakeCylinder1.cfg.unt,
                    samptime: airPressureBrakeCylinder1.rsi,
                  },
                  data: airPressureBrakeCylinder1.dat,
                });
              }
              if (
                airPressureBrakeCylinder2 !== null &&
                airPressureBrakeCylinder2 !== undefined &&
                airPressureBrakeCylinder2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Pressure Brake Cyl. B2",
                    unit: airPressureBrakeCylinder2.cfg.unt,
                    samptime: airPressureBrakeCylinder2.rsi,
                  },
                  data: airPressureBrakeCylinder2.dat,
                });
              }
              break;

            case "Torsional Osc. Ampl.":
              if (
                amplitudeTorsionalOscillation1 !== null &&
                amplitudeTorsionalOscillation1 !== undefined &&
                amplitudeTorsionalOscillation1.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Torsional Osc. Ampl. B1",
                    unit: amplitudeTorsionalOscillation1.cfg.unt,
                    samptime: amplitudeTorsionalOscillation1.rsi,
                  },
                  data: amplitudeTorsionalOscillation1.dat,
                });
              }
              if (
                amplitudeTorsionalOscillation2 !== null &&
                amplitudeTorsionalOscillation2 !== undefined &&
                amplitudeTorsionalOscillation2.length !== 0
              ) {
                chartValues.push({
                  config: {
                    label: "Torsional Osc. Ampl. B2",
                    unit: amplitudeTorsionalOscillation2.cfg.unt,
                    samptime: amplitudeTorsionalOscillation2.rsi,
                  },
                  data: amplitudeTorsionalOscillation2.dat,
                });
              }
              break;

            default:
              break;
          }
        });
      }

      return chartValues;
    }
  );

const filteredTimetrendSelector = () =>
  createSelector(
    timetrendSelector(),
    selectedMainTrendSelector(),
    selectedOverlaysTrendSelector(),
    basicFilterSelector(),
    advancedFilterSelector(),
    (graphData, selectedMain, selectedOverlays, filter, advancedFilter) => {
      let chartValues = [];
      if (filter.bogie === 1 || filter.bogie === 3) {
        graphData &&
          chartValues.push(
            ...graphData.filter(
              (item) => item.config.label === selectedMain + " B1"
            )
          );
      }

      if (filter.bogie === 2 || filter.bogie === 3) {
        graphData &&
          chartValues.push(
            ...graphData.filter(
              (item) => item.config.label === selectedMain + " B2"
            )
          );
      }

      selectedOverlays &&
        selectedOverlays.map(
          (value) => (
            (filter.bogie === 1 || filter.bogie === 3) &&
              chartValues.push(
                ...graphData.filter(
                  (item) => item.config.label === value + " B1"
                )
              ),
            (filter.bogie === 2 || filter.bogie === 3) &&
              chartValues.push(
                ...graphData.filter(
                  (item) => item.config.label === value + " B2"
                )
              )
          )
        );

      chartValues = JSON.parse(JSON.stringify(chartValues));

      //for (let j = 0; j < chartValues.length; j += 1) {
      //for (let i = 0; i < chartValues[j].data.length; i += 1) {
      chartValues.map((value, j) => {
        chartValues[j].data.map((value, i) => {
          //Operation filter
          let flag = 0;
          filter.operation.map((value, index) => {
            if (chartValues[j].data[i].ftr.opm === value && flag === 0) {
              flag = 1;
            }
          });
          if (flag === 0) {
            chartValues[j].data[i].min = null;
            chartValues[j].data[i].max = null;
            chartValues[j].data[i].avg = null;
          }
          //Temperature filter
          flag = 0;
          if (advancedFilter.temperature) {
            advancedFilter.temperature.map((value, index) => {
              if (chartValues[j].data[i].ftr.tmp === value && flag === 0) {
                flag = 1;
              }
            });
            if (flag === 0) {
              chartValues[j].data[i].min = null;
              chartValues[j].data[i].max = null;
              chartValues[j].data[i].avg = null;
            }

            //DrivingTrailerVelocity filter
            if (advancedFilter.drivingtrailervelocity === 0) {
              if (chartValues[j].data[i].ftr.dtv === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.drivingtrailervelocity === 1) {
              if (chartValues[j].data[i].ftr.dtv === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }

            //Sandar filter
            if (advancedFilter.sander === 0) {
              if (chartValues[j].data[i].ftr.sdr === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.sander === 1) {
              if (chartValues[j].data[i].ftr.sdr === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }

            //TOprotection filter(Bogie)
            let value = chartValues[j].data[i].ftr.top;
            if (filter.bogie === 3) value = chartValues[j].data[i].ftr.toa;

            if (advancedFilter.toprotection === 0) {
              if (value === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.toprotection === 1) {
              if (value === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }

            //AdhesionControl filter(Bogie)
            /*value = chartValues[j].data[i].ftr.ac;
            if (filter.bogie === 3) value = chartValues[j].data[i].ftr.ac;

            if (advancedFilter.adhesioncontrol === 0) {
              if (value === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.adhesioncontrol === 1) {
              if (value === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }*/

            //SlingBrake filter(Bogie)
            /*value = chartValues[j].data[i].ftr.sb;
            if (filter.bogie === 3)
              value = chartValues[j].data[i].ftr.sba;

            if (advancedFilter.slingbrake === 0) {
              if (value === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.slingbrake === 1) {
              if (value === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }*/
            //Anti slip brake filter(Bogie)
            value = chartValues[j].data[i].ftr.asb;
            if (filter.bogie === 3) value = chartValues[j].data[i].ftr.asa;

            if (advancedFilter.antislipbrake === 0) {
              if (value === 1) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
            if (advancedFilter.antislipbrake === 1) {
              if (value === 0) {
                chartValues[j].data[i].min = null;
                chartValues[j].data[i].max = null;
                chartValues[j].data[i].avg = null;
              }
            }
          }
        });
      });

      return chartValues;
    }
  );

const loadingSelector = () =>
  createSelector(selectCommonDetails(), (state) => state.get("loading"));
const loadingScatterB2Selector = () =>
  createSelector(selectCommonDetails(), (state) =>
    state.get("loadingScatterB2")
  );

const scatterplotSelector = () =>
  createSelector(selectCommonDetails(), (state) =>
    state.get("scatterplotData")
  );

//--------------------------
const scatterplot2Selector = () =>
  createSelector(selectCommonDetails(), (state) =>
    state.get("scatterplotData2")
  );

const scatterplotBothBogieSelector = () =>
  createSelector(
    scatterplotSelector(),
    scatterplot2Selector(),
    (scatterplotData1, scatterplotData2) => {
      let scatterData = [];

      if (
        scatterplotData1 !== null &&
        scatterplotData2 !== null &&
        scatterplotData1.length !== 0 &&
        scatterplotData2.length !== 0
      ) {
        scatterData.push(scatterplotData1[0]);
        scatterData.push(scatterplotData2[0]);
      }
      return scatterData;
    }
  );

const filteredScatterSelector = () =>
  createSelector(
    scatterplotBothBogieSelector(),
    basicFilterSelector(),
    advancedFilterSelector(),
    (graphData, filter, advancedFilter) => {
      let chartValues = [],
        bogie1,
        bogie2;
      if (graphData.length !== 0 && advancedFilter.temperature) {
        let confg = graphData[0].cfg;
        let docType = graphData[0].doc;
        let resampleInterval = graphData[0].rsi;

        bogie1 = graphData[0].dat.filter((item) => {
          return (
            filter.operation.includes(item.ftr.opm) &&
            advancedFilter.temperature.includes(item.ftr.tmp) &&
            (advancedFilter.drivingtrailervelocity === item.ftr.dtv ||
              advancedFilter.drivingtrailervelocity === 2) &&
            (advancedFilter.sander === item.ftr.sdr ||
              advancedFilter.sander === 2) &&
            (filter.bogie === 3
              ? advancedFilter.toprotection === item.ftr.toa ||
                advancedFilter.toprotection === 2
              : advancedFilter.toprotection === item.ftr.top ||
                advancedFilter.toprotection === 2) &&
            (filter.bogie === 3
              ? advancedFilter.antislipbrake === item.ftr.asa ||
                advancedFilter.antislipbrake === 2
              : advancedFilter.antislipbrake === item.ftr.asb ||
                advancedFilter.antislipbrake === 2)
            /*(filter.bogie === 3
              ? advancedFilter.adhesioncontrol === item.ftr.aca ||
                advancedFilter.adhesioncontrol === 2
              : advancedFilter.adhesioncontrol === item.ftr.ac ||
                advancedFilter.adhesioncontrol === 2) &&
            (filter.bogie === 3
              ? advancedFilter.slingbrake === item.ftr.sba ||
                advancedFilter.slingbrake === 2
              : advancedFilter.slingbrake === item.ftr.sb ||
                advancedFilter.slingbrake === 2)*/
          );
        });
        bogie1 = {
          documentType: docType,
          resampleInterval: resampleInterval,
          config: confg,
          data: bogie1,
        };

        let confg2 = graphData[1].cfg;
        let docType2 = graphData[1].doc;
        let resampleInterval2 = graphData[1].rsi;

        bogie2 = graphData[1].dat.filter((item) => {
          return (
            filter.operation.includes(item.ftr.opm) &&
            advancedFilter.temperature.includes(item.ftr.tmp) &&
            (advancedFilter.drivingtrailervelocity === item.ftr.dtv ||
              advancedFilter.drivingtrailervelocity === 2) &&
            (advancedFilter.sander === item.ftr.sdr ||
              advancedFilter.sander === 2) &&
            (filter.bogie === 3
              ? advancedFilter.toprotection === item.ftr.toa ||
                advancedFilter.toprotection === 2
              : advancedFilter.toprotection === item.ftr.top ||
                advancedFilter.toprotection === 2) &&
            (filter.bogie === 3
              ? advancedFilter.antislipbrake === item.ftr.asa ||
                advancedFilter.antislipbrake === 2
              : advancedFilter.antislipbrake === item.ftr.asb ||
                advancedFilter.antislipbrake === 2)
            /*(filter.bogie === 3
              ? advancedFilter.adhesioncontrol === item.ftr.aca ||
                advancedFilter.adhesioncontrol === 2
              : advancedFilter.adhesioncontrol === item.ftr.ac ||
                advancedFilter.adhesioncontrol === 2) &&
            (filter.bogie === 3
              ? advancedFilter.slingbrake === item.ftr.sba ||
                advancedFilter.slingbrake === 2
              : advancedFilter.slingbrake === item.ftr.sb ||
                advancedFilter.slingbrake === 2)*/
          );
        });
        bogie2 = {
          documentType: docType2,
          resampleInterval: resampleInterval2,
          config: confg2,
          data: bogie2,
        };

        if (filter.bogie === 1) {
          chartValues.push(bogie1);
        } else if (filter.bogie === 2) {
          chartValues.push(bogie2);
        } else if (filter.bogie === 3) {
          chartValues.push(bogie1);
          chartValues.push(bogie2);
        }
      }

      return chartValues;
    }
  );

//-----------------------------

const selectedMainScatterSelector = () =>
  createSelector(selectCommonDetails(), (state) =>
    state.get("selectedMainScatter")
  );

const histogramSelector = () =>
  createSelector(selectCommonDetails(), (state) => state.get("histogramData"));

//-----------------------------

const loadingMapSelector = () =>
  createSelector(selectMapDetails(), (state) => state.get("loading"));

const mapTotalRunsSelector = () =>
  createSelector(selectMapDetails(), (state) => state.get("totalRuns"));

const mapSlip1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlip1"));

const mapSlip2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlip2"));

const mapSpeed1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSpeed1"));

const mapSpeed2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSpeed2"));

const mapEffort1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffort1"));

const mapEffort2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffort2"));

const mapEffortDriving1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortDriving1"));

const mapEffortDriving2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortDriving2"));

const mapEffortBraking1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortBraking1"));

const mapEffortBraking2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortBraking2"));

const mapEffortBrakingMech1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortBrakingMech1")
  );

const mapEffortBrakingMech2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortBrakingMech2")
  );

const mapDrivingTrailerVel1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapDrivingTrailerVel1")
  );

const mapEffortReference1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortReference1")
  );

const mapEffortReference2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortReference2")
  );

const mapSlipPower1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlipPower1"));

const mapSlipPower2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlipPower2"));

const mapPowerBrakingMechanical1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapPowerBrakingMechanical1")
  );

const mapPowerBrakingMechanical2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapPowerBrakingMechanical2")
  );

const mapAirPressureBrakeCylinder1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapAirPressureBrakeCylinder1")
  );

const mapAirPressureBrakeCylinder2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapAirPressureBrakeCylinder2")
  );

const mapAmplitudeTorsionalOscillation1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapAmplitudeTorsionalOscillation1")
  );

const mapAmplitudeTorsionalOscillation2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapAmplitudeTorsionalOscillation2")
  );

const mapSelector = () =>
  createSelector(
    selectedMainTrendSelector(),
    basicFilterSelector(),
    mapSlip1Selector(),
    mapSlip2Selector(),
    mapSpeed1Selector(),
    mapSpeed2Selector(),
    mapEffort1Selector(),
    mapEffort2Selector(),
    mapEffortDriving1Selector(),
    mapEffortDriving2Selector(),
    mapEffortBraking1Selector(),
    mapEffortBraking2Selector(),
    mapEffortBrakingMech1Selector(),
    mapEffortBrakingMech2Selector(),
    mapDrivingTrailerVel1Selector(),
    mapEffortReference1Selector(),
    mapEffortReference2Selector(),
    mapSlipPower1Selector(),
    mapSlipPower2Selector(),
    mapPowerBrakingMechanical1Selector(),
    mapPowerBrakingMechanical2Selector(),
    mapAirPressureBrakeCylinder1Selector(),
    mapAirPressureBrakeCylinder2Selector(),
    mapAmplitudeTorsionalOscillation1Selector(),
    mapAmplitudeTorsionalOscillation2Selector(),
    (
      selectedMain,
      filter,
      slip1,
      slip2,
      speed1,
      speed2,
      effort1,
      effort2,
      effortDriving1,
      effortDriving2,
      effortBraking1,
      effortBraking2,
      effortBrakingMech1,
      effortBrakingMech2,
      drivingTrailerVel1,
      effortReference1,
      effortReference2,
      slipPower1,
      slipPower2,
      powerBrakingMechanical1,
      powerBrakingMechanical2,
      airPressureBrakeCylinder1,
      airPressureBrakeCylinder2,
      amplitudeTorsionalOscillation1,
      amplitudeTorsionalOscillation2
    ) => {
      switch (selectedMain) {
        case "Slip":
          if (filter.bogie === 1) {
            if (slip1 !== null) return slip1;
          }
          if (filter.bogie === 2) {
            if (slip2 !== null) return slip2;
          }
          if (filter.bogie === 3) {
            if (slip1 !== null) return slip1;
            if (slip2 !== null) return slip2;
          }
          break;
        case "Speed at Wheel":
          if (filter.bogie === 1) {
            if (speed1 !== null) return speed1;
          }
          if (filter.bogie === 2) {
            if (speed2 !== null) return speed2;
          }
          if (filter.bogie === 3) {
            if (speed1 !== null) return speed1;
            if (speed2 !== null) return speed2;
          }
          break;

        case "Effort":
          if (filter.bogie === 1) {
            if (effort1 !== null) return effort1;
          }
          if (filter.bogie === 2) {
            if (effort2 !== null) return effort2;
          }
          if (filter.bogie === 3) {
            if (effort1 !== null) return effort1;
            if (effort2 !== null) return effort2;
          }
          break;

        /*case "EffortDriving":
          if (filter.bogie === 1) {
            if (effortDriving1 !== null) return effortDriving1;
          }
          if (filter.bogie === 2) {
            if (effortDriving2 !== null) return effortDriving2;
          }
          if (filter.bogie === 3) {
            if (effortDriving1 !== null) return effortDriving1;
            if (effortDriving2 !== null) return effortDriving2;
          }
          break;

        case "EffortBraking":
          if (filter.bogie === 1) {
            if (effortBraking1 !== null) return effortBraking1;
          }
          if (filter.bogie === 2) {
            if (effortBraking2 !== null) return effortBraking2;
          }
          if (filter.bogie === 3) {
            if (effortBraking1 !== null) return effortBraking1;
            if (effortBraking2 !== null) return effortBraking2;
          }
          break;*/

        case "Effort Mech. Brake":
          if (filter.bogie === 1) {
            if (effortBrakingMech1 !== null) return effortBrakingMech1;
          }
          if (filter.bogie === 2) {
            if (effortBrakingMech2 !== null) return effortBrakingMech2;
          }
          if (filter.bogie === 3) {
            if (effortBrakingMech1 !== null) return effortBrakingMech1;
            if (effortBrakingMech2 !== null) return effortBrakingMech2;
          }
          break;

        case "Speed Driving Trailer":
          if (filter.bogie === 1) {
            if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
          }
          if (filter.bogie === 2) {
            if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
          }
          if (filter.bogie === 3) {
            if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
            if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
          }
          break;
        case "Effort Reference":
          if (filter.bogie === 1) {
            if (effortReference1 !== null) return effortReference1;
          }
          if (filter.bogie === 2) {
            if (effortReference2 !== null) return effortReference2;
          }
          if (filter.bogie === 3) {
            if (effortReference1 !== null) return effortReference1;
            if (effortReference2 !== null) return effortReference2;
          }
          break;
        case "Power Slip":
          if (filter.bogie === 1) {
            if (slipPower1 !== null) return slipPower1;
          }
          if (filter.bogie === 2) {
            if (slipPower2 !== null) return slipPower2;
          }
          if (filter.bogie === 3) {
            if (slipPower1 !== null) return slipPower1;
            if (slipPower2 !== null) return slipPower2;
          }
          break;
        case "Power Mechanical Brake":
          if (filter.bogie === 1) {
            if (powerBrakingMechanical1 !== null)
              return powerBrakingMechanical1;
          }
          if (filter.bogie === 2) {
            if (powerBrakingMechanical2 !== null)
              return powerBrakingMechanical2;
          }
          if (filter.bogie === 3) {
            if (powerBrakingMechanical1 !== null)
              return powerBrakingMechanical1;
            if (powerBrakingMechanical2 !== null)
              return powerBrakingMechanical2;
          }
          break;
        case "Pressure Brake Cyl.":
          if (filter.bogie === 1) {
            if (airPressureBrakeCylinder1 !== null)
              return airPressureBrakeCylinder1;
          }
          if (filter.bogie === 2) {
            if (airPressureBrakeCylinder2 !== null)
              return airPressureBrakeCylinder2;
          }
          if (filter.bogie === 3) {
            if (airPressureBrakeCylinder1 !== null)
              return airPressureBrakeCylinder1;
            if (airPressureBrakeCylinder2 !== null)
              return airPressureBrakeCylinder2;
          }
          break;
        case "Torsional Osc. Ampl.":
          if (filter.bogie === 1) {
            if (amplitudeTorsionalOscillation1 !== null)
              return amplitudeTorsionalOscillation1;
          }
          if (filter.bogie === 2) {
            if (amplitudeTorsionalOscillation2 !== null)
              return amplitudeTorsionalOscillation2;
          }
          if (filter.bogie === 3) {
            if (amplitudeTorsionalOscillation1 !== null)
              return amplitudeTorsionalOscillation1;
            if (amplitudeTorsionalOscillation2 !== null)
              return amplitudeTorsionalOscillation2;
          }
          break;

        default:
          break;
        //return null;
      }
    }
  );

const filteredMapSelector = () =>
  createSelector(
    mapSelector(),
    selectedMainTrendSelector(),
    basicFilterSelector(),
    (mapData, selectedMain, filter) => {
      let graphValues = [];

      return mapData;
    }
  );

export {
  loadingSelector,
  timetrendSelector,
  scatterplotSelector,
  basicFilterSelector,
  advancedFilterSelector,
  selectedMainScatterSelector,
  filteredTimetrendSelector,
  histogramSelector,
  loadingTimetrendSelector,
  selectedMainTrendSelector,
  selectedOverlaysTrendSelector,
  timetrendSlip1Selector,
  timetrendSlip2Selector,
  timetrendSpeed1Selector,
  timetrendSpeed2Selector,
  timetrendEffort1Selector,
  timetrendEffort2Selector,
  timetrendEffortDriving1Selector,
  timetrendEffortDriving2Selector,
  timetrendEffortBraking1Selector,
  timetrendEffortBraking2Selector,
  timetrendEffortBrakingMech1Selector,
  timetrendEffortBrakingMech2Selector,
  ttDrivingTrailerVel1Selector,
  ttEffortReference1Selector,
  ttEffortReference2Selector,
  ttSlipPower1Selector,
  ttSlipPower2Selector,
  ttPowerBrakingMechanical1Selector,
  ttPowerBrakingMechanical2Selector,
  ttAirPressureBrakeCylinder1Selector,
  ttAirPressureBrakeCylinder2Selector,
  ttAmplitudeTorsionalOscillation1Selector,
  ttAmplitudeTorsionalOscillation2Selector,
  scatterplotBothBogieSelector,
  filteredScatterSelector,
  loadingScatterB2Selector,
  loadingMapSelector,
  mapTotalRunsSelector,
  mapSlip1Selector,
  mapSlip2Selector,
  mapSpeed1Selector,
  mapSpeed2Selector,
  mapEffort1Selector,
  mapEffort2Selector,
  mapEffortDriving1Selector,
  mapEffortDriving2Selector,
  mapEffortBraking1Selector,
  mapEffortBraking2Selector,
  mapEffortBrakingMech1Selector,
  mapEffortBrakingMech2Selector,
  mapDrivingTrailerVel1Selector,
  mapEffortReference1Selector,
  mapEffortReference2Selector,
  mapSlipPower1Selector,
  mapSlipPower2Selector,
  mapPowerBrakingMechanical1Selector,
  mapPowerBrakingMechanical2Selector,
  mapAirPressureBrakeCylinder1Selector,
  mapAirPressureBrakeCylinder2Selector,
  mapAmplitudeTorsionalOscillation1Selector,
  mapAmplitudeTorsionalOscillation2Selector,
  filteredMapSelector,
};
