import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import {
  GET_TRAINVIEW_MAP,
  GET_TRAINVIEW_MAPSLIP1_SUCCESS,
  GET_TRAINVIEW_MAPSLIP2_SUCCESS,
  GET_TRAINVIEW_MAPSPEED1_SUCCESS,
  GET_TRAINVIEW_MAPSPEED2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORT1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORT2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS,
  GET_TRAINVIEW_MAP_FAILURE,
  CLEAR_TRAINVIEW_MAP,
  GET_TRAINVIEW_HISTOGRAM,
  GET_TRAINVIEW_HISTOGRAM_SUCCESS,
  GET_TRAINVIEW_HISTOGRAM_FAILURE,
  CLEAR_TRAINVIEW_HISTOGRAM,
  SET_TAB,
} from "./constants";

const initialState = fromJS({
  // loading: false,
  // mapData: null,
  // graphData: null,
  // error: null,
  selectedTab: "MAP_TYPE",
  //tabloading: false
  loadingHistogram: false,
  histogramData: null,
});

function reducerCommon(state = initialState, action) {
  switch (action.type) {
    /*case GET_TRAINVIEW_MAPDATA:
      return state.set("loading", true).set("error", null);
    case GET_TRAINVIEW_MAPDATA_SUCCESS:
      return state.set("loading", false).set("mapData", action.Mapdata);
    case GET_TRAINVIEW_MAPDATA_FAILURE:
      return state
        .set("loading", false)
        .set("mapData", null)
        .set("error", action.error);
    case GET_TRAINVIEW_GRAPHDATA:
      return state.set("loading", true).set("error", null);
    case GET_TRAINVIEW_GRAPHDATA_SUCCESS:
      return state.set("loading", false).set("graphData", action.Graphdata);
    case GET_TRAINVIEW_GRAPHDATA_FAILURE:
      return state
        .set("loading", false)
        .set("graphData", null)
        .set("error", action.error);*/

    case SET_TAB:
      return state.set("selectedTab", action.payload);
    case GET_TRAINVIEW_HISTOGRAM:
      return state
        .set("loadingHistogram", true)
        .set("error", null)
        .set("histogramData", null);
    case GET_TRAINVIEW_HISTOGRAM_SUCCESS:
      return state
        .set("loadingHistogram", false)
        .set("histogramData", action.payload);
    case GET_TRAINVIEW_HISTOGRAM_FAILURE:
      return state
        .set("loadingHistogram", false)
        .set("histogramData", null)
        .set("error", action.payload);
    case CLEAR_TRAINVIEW_HISTOGRAM:
      return state.set("histogramData", null);
    default:
      return state;
  }
}

const mapInitialState = fromJS({
  loading: false,
  error: null,
  mapSlip1: null,
  mapSlip2: null,
  mapSpeed1: null,
  mapSpeed2: null,
  mapEffort1: null,
  mapEffort2: null,
  mapEffortDriving1: null,
  mapEffortDriving2: null,
  mapEffortBraking1: null,
  mapEffortBraking2: null,
  mapEffortBrakingMech1: null,
  mapEffortBrakingMech2: null,
  mapDrivingTrailerVel1: null,
  selectedMainMap: null,
});

function reducerMap(state = mapInitialState, action) {
  console.log("Action: ", action.type);
  switch (action.type) {
    case GET_TRAINVIEW_MAP:
      return state.set("loading", true).set("error", null);
    case GET_TRAINVIEW_MAPSLIP1_SUCCESS:
      return state.set("loading", false).set("mapSlip1", action.payload);
    case GET_TRAINVIEW_MAPSLIP2_SUCCESS:
      return state.set("loading", false).set("mapSlip2", action.payload);
    case GET_TRAINVIEW_MAPSPEED1_SUCCESS:
      return state.set("loading", false).set("mapSpeed1", action.payload);
    case GET_TRAINVIEW_MAPSPEED2_SUCCESS:
      return state.set("loading", false).set("mapSpeed2", action.payload);
    case GET_TRAINVIEW_MAPEFFORT1_SUCCESS:
      return state.set("loading", false).set("mapEffort1", action.payload);
    case GET_TRAINVIEW_MAPEFFORT2_SUCCESS:
      return state.set("loading", false).set("mapEffort2", action.payload);
    case GET_TRAINVIEW_MAPEFFORTDRIVING1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortDriving1", action.payload);
    case GET_TRAINVIEW_MAPEFFORTDRIVING2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortDriving2", action.payload);
    case GET_TRAINVIEW_MAPEFFORTBRAKING1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBraking1", action.payload);
    case GET_TRAINVIEW_MAPEFFORTBRAKING2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBraking2", action.payload);
    case GET_TRAINVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBrakingMech1", action.payload);
    case GET_TRAINVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBrakingMech2", action.payload);
    case GET_TRAINVIEW_MAPDRIVINGTRAILERVEL1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapDrivingTrailerVel1", action.payload);
    case GET_TRAINVIEW_MAP_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    case CLEAR_TRAINVIEW_MAP:
      return state
        .set("mapSlip1", null)
        .set("mapSlip2", null)
        .set("mapSpeed1", null)
        .set("mapSpeed2", null)
        .set("mapEffort1", null)
        .set("mapEffort2", null)
        .set("mapEffortDriving1", null)
        .set("mapEffortDriving2", null)
        .set("mapEffortBraking1", null)
        .set("mapEffortBraking2", null)
        .set("mapEffortBrakingMech1", null)
        .set("mapEffortBrakingMech2", null)
        .set("mapDrivingTrailerVel1", null);
    default:
      return state;
  }
}

const trainViewReducer = combineReducers({
  reducerCommon: reducerCommon,
  reducerMap: reducerMap,
});

export default trainViewReducer;
