import React, { Component } from "react";
import "./styles.scss";
import Col from "react-bootstrap/Col";
import { Slider, Rail, Handles } from "react-compound-slider";
import { Row } from "react-bootstrap";
import SelectorLine from "./rangeLine";
import Moment from "moment";

export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  mapData,
  basicFilter,
  unit
}) {
  //let leftMargin = percent === 100 ? 99.5427 : percent; //temp(Want to remove after time arrow icon change)
  //let changeiconmargin = percent >= 100 ? "changeiconmargin" : "";
  let changetextdirection = percent >= 80 ? "timearrowtext" : "";
  let km = mapData[value];
  km = basicFilter.bogie == 3 ? km.ava : km.avg;
  let formaTdate = Moment.utc(mapData[value].ts).format(
    "DD.MM.YYYY HH:mm:ss"
  );

  return (
    <div
      className="dragIcon"
      style={{
        left: `${percent}%`
      }}
      {...getHandleProps(id)}
    >
      <div className="selectedInterval" id={changetextdirection}>
        {formaTdate + " " + km + "" + unit}
      </div>
    </div>
  );
}

export default class DistanceSelector extends Component {
  render() {
    const { selectedTrain, mapData, onUpdate, basicFilter, unit } = this.props;
    const min = 0,
      max = this.props.mapData.length - 1;
    return (
      <Col md={12} className="containerdistanceselector">
        <Row>
          <Col md={3}>
            <img
              src={require("../../assets/images/subway.svg")}
              className="icontrain"
            />
            <span className="text">{selectedTrain}</span>
          </Col>
          <Col md={9}>
            <div
            // style={{
            //   width: (100 / mapData.data.length) * mapData.data.length + "%"
            // }}
            >
              <Slider
                className="sliderStyle"
                domain={[min, max]}
                step={1}
                mode={2}
                values={[min]}
                onUpdate={onUpdate}
              >
                <Rail>
                  {({ getRailProps }) => {
                    let colorArea = 100 / mapData.length;
                    let currentColor, operation;
                    return (
                      <div className="colorarea">
                        {mapData.map(
                          item => (
                            /*<SelectorLine
                            colorArea={colorArea}
                            currentColor={
                              basicFilter.bogie === 3
                                ? item.colorAll
                                : item.color
                            }
                            operation={
                              basicFilter.operation &&
                              !basicFilter.operation.find(
                                val => val == item.filter.operationMode
                              )
                                ? "LightGray"
                                : ""
                            }
                            gpsValid={item.gpsValid}
                            railProps={{ ...getRailProps() }}
                          />*/
                            (currentColor =
                              basicFilter.bogie === 3
                                ? item.cla
                                : item.clr),
                            (currentColor === "green" &&
                              (currentColor = "#0CA919"),
                            currentColor === "yellow" &&
                              (currentColor = "#FFD800"),
                            currentColor === "red" &&
                              (currentColor = "#F03040"),
                            currentColor === "gray" &&
                              (currentColor = "#545454")),
                            (operation =
                              basicFilter.operation &&
                              !basicFilter.operation.find(
                                val => val == item.ftr.opm
                              )
                                ? "LightGray"
                                : ""),
                            (operation != "" || item.gvd === 0) &&
                              (currentColor = "LightGray"),
                            (
                              <div
                                style={{
                                  width: colorArea + "%",
                                  backgroundColor: "#0C74DA"
                                    /*mapData.length > 300
                                      ? "LightGray"
                                      : currentColor*/
                                }}
                                className="railStyle"
                                id={mapData.length}
                              />
                            )
                          )
                        )}
                      </div>
                    );
                  }}
                </Rail>
                <Handles>
                  {({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                      {handles.map(handle => (
                        <Handle
                          key={handle.id}
                          handle={handle}
                          getHandleProps={getHandleProps}
                          mapData={mapData}
                          basicFilter={basicFilter}
                          unit={unit}
                        />
                      ))}
                    </div>
                  )}
                </Handles>
              </Slider>
            </div>
          </Col>
        </Row>
      </Col>
    );
  }
}
