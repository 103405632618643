import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { reducers } from "./reducers/index";
import { rootSaga } from "./sagas/index";
import { composeWithDevTools } from "remote-redux-devtools";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// create a redux store with our reducer above and middleware
const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;
