//Library
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { createStructuredSelector } from "reselect";
import Moment from "moment";
//Components
import "./styles.scss";
import messages from "./messages";
import TrainView from "./TrainView";
import Breadcrumb from "../../components/Breadcrumb";
import Datepicker from "../../components/DatePicker";
import SideMenu from "../../components/SideMenu";
import Loading from "../../components/Loading";
//Actions
import {
  setTab,
  getTrainViewMap,
  clearTrainViewMap,
  getTrainViewHistogram,
  clearTrainViewHistogram,
} from "./actions";
import {
  fetchMapData,
  fetchTrainList,
  setSelectedTrain,
  setDate,
} from "../DashBoard/actions";
import {
  setBasicFilter,
  setMainTrendSignal,
  getDetailViewTimetrend,
  setLoading,
  setTimetrendLoading,
  clearDetailViewMap,
  clearDetailViewMapDateSlider,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
  getDetailViewScatterplot,
  getDetailViewHistogram,
  clearBasicFilter,
} from "../DetailTrainPage/actions";
//Selectors
import {
  loadingSelector,
  loadingMapSelector,
  loadingTimetrendSelector,
  filteredMapSelector,
  filteredMapSliderSelector,
  filteredTimetrendSelector,
  scatterplotSelector,
  selectedTabSelector,
  timetrendSlip1Selector,
  timetrendSlip2Selector,
  timetrendSpeed1Selector,
  timetrendSpeed2Selector,
  timetrendEffort1Selector,
  timetrendEffort2Selector,
  timetrendEffortDriving1Selector,
  timetrendEffortDriving2Selector,
  timetrendEffortBraking1Selector,
  timetrendEffortBraking2Selector,
  timetrendEffortBrakingMech1Selector,
  timetrendEffortBrakingMech2Selector,
  ttDrivingTrailerVel1Selector,
  mapSlip1Selector,
  mapSlip2Selector,
  mapSpeed1Selector,
  mapSpeed2Selector,
  mapEffort1Selector,
  mapEffort2Selector,
  mapEffortDriving1Selector,
  mapEffortDriving2Selector,
  mapEffortBraking1Selector,
  mapEffortBraking2Selector,
  mapEffortBrakingMech1Selector,
  mapEffortBrakingMech2Selector,
  mapDrivingTrailerVel1Selector,
  loadingScatterSelector,
  filteredScatterSelector,
  loadingScatterB2Selector,
  loadingHistogramSelector,
  histogramSelector,
} from "./selectors";
import {
  startDateSelector,
  endDateSelector,
  selectedTrainSelector,
  fleetDataSelector,
} from "../DashBoard/selectors";
import {
  basicFilterSelector,
  selectedMainTrendSelector,
  //histogramSelector,
} from "../DetailTrainPage/selectors";

let xAxisSignalScatter = "Speed";
let yAxisSignalScatter = "Slip";

class TrainViewPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.selectedMainTrend == null &&
      this.props.setMainTrendSignal("Speed Driving Trailer");

    this.props.basicFilter.operation == undefined &&
      this.props.setBasicFilter({
        weather: "All",
        operation: [0, 1, 2, 3, 4, 5],
        bogie: 1,
      });

    this.state = {
      xAxisSignal: "Speed",
      yAxisSignal: "Effort",
      zAxisSignal: "Occurrence",
    };
  }

  componentDidMount() {
    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    if (this.props.trainList === null) {
      this.props.fetchTrainList(startDate, endDate);
    }
    this.props.setLoading(true);
    //this.onClickTab(this.props.selectedMainTrend);
    this.callTimetrendMap(this.props.selectedMainTrend);
    /*this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "1"
    );
    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "2"
    );
    this.props.getDetailViewHistogram(
      this.props.selectedTrain,
      startDate,
      endDate,
      this.state.xAxisSignal,
      this.state.yAxisSignal,
      this.state.zAxisSignal
    );*/
  }

  applyDateChange = async (start_Date, end_Date, trainSelect = true) => {
    if (trainSelect)
      if (
        new Date(start_Date).valueOf() ==
          new Date(this.props.startDate.replace(/%3A/g, ":")).valueOf() &&
        new Date(end_Date).valueOf() ==
          new Date(this.props.endDate.replace(/%3A/g, ":")).valueOf()
      ) {
        return false;
      }
    await this.props.setDate(start_Date, end_Date);

    await this.props.clearTrainViewMap();
    await this.props.clearTrainViewHistogram();
    await this.props.clearDetailViewMap();
    await this.props.clearDetailViewMapDateSlider();
    await this.props.clearDetailViewTimetrend();
    await this.props.clearDetailViewScatterplot();
    await this.props.clearDetailViewHistogram();

    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    this.props.fetchTrainList(startDate, endDate);

    this.props.setLoading(true);
    //this.onClickTab(this.props.selectedMainTrend);
    this.callTimetrendMap(this.props.selectedMainTrend);
    /*this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "1"
    );
    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "2"
    );
    this.props.getDetailViewHistogram(
      this.props.selectedTrain,
      startDate,
      endDate,
      this.state.xAxisSignal,
      this.state.yAxisSignal,
      this.state.zAxisSignal
    );*/
  };

  clearBasicFilter = () => {
    this.props.clearBasicFilter();
  };

  onTrainSelect = async (e) => {
    await this.props.setSelectedTrain(e.target.id);
    let startDate = new Date(this.props.startDate.replace(/%3A/g, ":")),
      endDate = new Date(this.props.endDate.replace(/%3A/g, ":"));

    this.applyDateChange(startDate, endDate, false);
  };

  changeBasicFilter = (e) => {
    let currentTargetValue = Number(e.currentTarget.value);
    if (isNaN(currentTargetValue)) currentTargetValue = e.currentTarget.value;

    if (e.target.type !== "checkbox") {
      this.props.setBasicFilter({
        ...this.props.basicFilter,
        [e.target.name]: currentTargetValue,
      });
    } else if (e.target.type === "checkbox") {
      if (e.target.name === "operation") {
        if (e.currentTarget.checked) {
          let operationValue = [currentTargetValue];

          this.props.basicFilter.operation != null &&
            operationValue.push(...this.props.basicFilter.operation);

          this.props.setBasicFilter({
            ...this.props.basicFilter,
            [e.target.name]: operationValue,
          });
        } else if (!e.currentTarget.checked) {
          let operationValue = [];

          this.props.basicFilter.operation != null &&
            (operationValue = this.props.basicFilter.operation);

          var index = operationValue.indexOf(currentTargetValue);
          index > -1 && operationValue.splice(index, 1);

          this.props.setBasicFilter({
            ...this.props.basicFilter,
            [e.target.name]: operationValue,
          });
        }
      }
    }
  };

  onClickGraph = (tab) => {
    this.props.setTab(tab);
    this.props.history.push("/DetailTrainPage");
  };

  onClickRedirectMap = () => {
    this.props.setTab("MAP_TYPE");
    this.props.history.push("/DetailTrainPage");
  };

  onClickTab = async (tab) => {
    this.props.setMainTrendSignal(tab);

    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    this.props.setTimetrendLoading(true);
    //await this.props.clearTrainViewMap();
    //await this.props.clearDetailViewTimetrend();

    switch (tab) {
      case "Slip":
        this.props.mapSlip1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "1"
          );
        this.props.mapSlip2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "2"
          );

        this.props.timetrendSlip1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "1"
          );
        this.props.timetrendSlip2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "2"
          );
        break;

      case "Speed":
        this.props.mapSpeed1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "1"
          );
        this.props.mapSpeed2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "2"
          );

        this.props.timetrendSpeed1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "1"
          );
        this.props.timetrendSpeed2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "2"
          );
        break;

      case "Effort":
        this.props.mapEffort1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "1"
          );
        this.props.mapEffort2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "2"
          );

        this.props.timetrendEffort1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "1"
          );
        this.props.timetrendEffort2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "2"
          );
        break;

      case "EffortDriving":
        this.props.mapEffortDriving1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "1"
          );
        this.props.mapEffortDriving2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "2"
          );

        this.props.timetrendEffortDriving1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "1"
          );
        this.props.timetrendEffortDriving2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "2"
          );
        break;

      case "EffortBraking":
        this.props.mapEffortBraking1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "1"
          );
        this.props.mapEffortBraking2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "2"
          );

        this.props.timetrendEffortBraking1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "1"
          );
        this.props.timetrendEffortBraking2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "2"
          );
        break;

      case "EffortBrakingMechanical":
        this.props.mapEffortBrakingMech1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "1"
          );
        this.props.mapEffortBrakingMech2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "2"
          );

        this.props.timetrendEffortBrakingMech1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "1"
          );
        this.props.timetrendEffortBrakingMech2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "2"
          );
        break;

      default:
        break;
    }
    this.handleLoading();
  };

  callTimetrendMap = async (tab) => {
    this.props.setMainTrendSignal(tab);

    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    this.props.setTimetrendLoading(true);
    //await this.props.clearTrainViewMap();
    if (sessionStorage.getItem("isTrendZoomed") === "true")
      await this.props.clearDetailViewTimetrend();

    /*switch (tab) {
      case "Slip":
        this.props.mapSlip1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "1"
          );
        this.props.mapSlip2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "2"
          );

        this.props.timetrendSlip1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "1"
          );
        this.props.timetrendSlip2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Slip",
            "2"
          );
        break;

      case "Speed":
        this.props.mapSpeed1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "1"
          );
        this.props.mapSpeed2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "2"
          );

        this.props.timetrendSpeed1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "1"
          );
        this.props.timetrendSpeed2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Speed",
            "2"
          );
        break;

      case "Effort":
        this.props.mapEffort1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "1"
          );
        this.props.mapEffort2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "2"
          );

        this.props.timetrendEffort1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "1"
          );
        this.props.timetrendEffort2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "Effort",
            "2"
          );
        break;

      case "EffortDriving":
        this.props.mapEffortDriving1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "1"
          );
        this.props.mapEffortDriving2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "2"
          );

        this.props.timetrendEffortDriving1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "1"
          );
        this.props.timetrendEffortDriving2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortDriving",
            "2"
          );
        break;

      case "EffortBraking":
        this.props.mapEffortBraking1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "1"
          );
        this.props.mapEffortBraking2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "2"
          );

        this.props.timetrendEffortBraking1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "1"
          );
        this.props.timetrendEffortBraking2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBraking",
            "2"
          );
        break;

      case "EffortBrakingMechanical":
        this.props.mapEffortBrakingMech1 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "1"
          );
        this.props.mapEffortBrakingMech2 === null &&
          this.props.getTrainViewMap(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "2"
          );

        this.props.timetrendEffortBrakingMech1 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "1"
          );
        this.props.timetrendEffortBrakingMech2 === null &&
          this.props.getDetailViewTimetrend(
            this.props.selectedTrain,
            startDate,
            endDate,
            "EffortBrakingMechanical",
            "2"
          );
        break;

      default:
        break;
    }*/
    this.props.mapDrivingTrailerVel1 === null &&
      this.props.getTrainViewMap(
        this.props.selectedTrain,
        startDate,
        endDate,
        "DrivingTrailerVel",
        "1"
      );

    this.props.ttDrivingTrailerVel1 === null &&
      this.props.getDetailViewTimetrend(
        this.props.selectedTrain,
        startDate,
        endDate,
        "DrivingTrailerVel",
        "1"
      );

    this.handleLoading();

    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "1",
      true
    );

    this.props.histogramdata === null &&
      this.props.getTrainViewHistogram(
        this.props.selectedTrain,
        startDate,
        endDate,
        this.state.xAxisSignal,
        this.state.yAxisSignal,
        this.state.zAxisSignal
      );

    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "2",
      true
    );
  };

  handleLoading = () => {
    this.props.ttDrivingTrailerVel1 !== null &&
      this.props.setTimetrendLoading(false);
  };

  render() {
    const {
      loadingTimetrend,
      loadingMap,
      startDate,
      endDate,
      selectedTrain,
      mapData,
      mapSliderData,
      timeTrendData,
      scatterplotData,
      basicFilter,
      selectedMainTrend,
      selectedTab,
      histogramdata,
      loadingScatter,
      filteredScatterData,
    } = this.props;
    this.handleLoading();
    return (
      <div>
        <Breadcrumb
          BreadcrumbIcon={
            <img
              src={require("../../assets/images/home.svg")}
              className={""}
              alt="Detail Train View"
            />
          }
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          NumberofTrains={this.props.trainList && this.props.trainList.length}
        />
        <Row>
          <Col md={12} className="addMarginTop">
            <Datepicker
              onApplyClick={this.applyDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
        </Row>
        <Row className="addMarginTop">
          <Col md={3} className="sideMenucon">
            <SideMenu
              onChange={this.changeBasicFilter}
              clearBasicFilter={this.clearBasicFilter}
              onTrainSelect={this.onTrainSelect}
              advanceFilter={false}
              trainno={selectedTrain}
              basicFilter={basicFilter}
              trainList={this.props.trainList}
            />
          </Col>
          <Col md={9} className="detailViewRightinner">
            <TrainView
              mapData={mapData}
              mapSliderData={mapSliderData}
              loadingTimetrend={loadingTimetrend}
              loadingMap={loadingMap}
              timeTrendData={timeTrendData}
              loadingScatter={loadingScatter}
              scatterplotData={filteredScatterData}
              selectedTrain={selectedTrain}
              basicFilter={basicFilter}
              selectedMainTrend={selectedMainTrend}
              onClickGraph={this.onClickGraph}
              selectedTab={selectedTab}
              onClickTab={this.onClickTab}
              histogramdata={histogramdata}
              loadingScatterB2={this.props.loadingScatterB2}
              startDate={startDate}
              endDate={endDate}
              onClickRedirectMap={this.onClickRedirectMap}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

TrainViewPage.defaultProps = {
  selectedTrain: undefined,
  basicFilter: undefined,
  startTime: undefined,
  endTime: undefined,
  startTimeDate: undefined,
  endTimeDate: undefined,
  graphsData: undefined,
};

TrainViewPage.propTypes = {
  selectedTrain: PropTypes.object,
  basicFilter: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  startTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  graphsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setBasicFilter: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loadingTimetrend: loadingTimetrendSelector(),
  loadingMap: loadingMapSelector(),
  startDate: startDateSelector(),
  endDate: endDateSelector(),
  mapData: filteredMapSelector(),
  mapSliderData: filteredMapSliderSelector(),
  timeTrendData: filteredTimetrendSelector(),
  scatterplotData: scatterplotSelector(),
  selectedTrain: selectedTrainSelector(),
  basicFilter: basicFilterSelector(),
  selectedMainTrend: selectedMainTrendSelector(),
  selectedTab: selectedTabSelector(),
  timetrendSlip1: timetrendSlip1Selector(),
  timetrendSlip2: timetrendSlip2Selector(),
  timetrendSpeed1: timetrendSpeed1Selector(),
  timetrendSpeed2: timetrendSpeed2Selector(),
  timetrendEffort1: timetrendEffort1Selector(),
  timetrendEffort2: timetrendEffort2Selector(),
  timetrendEffortDriving1: timetrendEffortDriving1Selector(),
  timetrendEffortDriving2: timetrendEffortDriving2Selector(),
  timetrendEffortBraking1: timetrendEffortBraking1Selector(),
  timetrendEffortBraking2: timetrendEffortBraking2Selector(),
  timetrendEffortBrakingMech1: timetrendEffortBrakingMech1Selector(),
  timetrendEffortBrakingMech2: timetrendEffortBrakingMech2Selector(),
  ttDrivingTrailerVel1: ttDrivingTrailerVel1Selector(),
  mapSlip1: mapSlip1Selector(),
  mapSlip2: mapSlip2Selector(),
  mapSpeed1: mapSpeed1Selector(),
  mapSpeed2: mapSpeed2Selector(),
  mapEffort1: mapEffort1Selector(),
  mapEffort2: mapEffort2Selector(),
  mapEffortDriving1: mapEffortDriving1Selector(),
  mapEffortDriving2: mapEffortDriving2Selector(),
  mapEffortBraking1: mapEffortBraking1Selector(),
  mapEffortBraking2: mapEffortBraking2Selector(),
  mapEffortBrakingMech1: mapEffortBrakingMech1Selector(),
  mapEffortBrakingMech2: mapEffortBrakingMech2Selector(),
  mapDrivingTrailerVel1: mapDrivingTrailerVel1Selector(),
  histogramdata: histogramSelector(),
  loadingScatter: loadingScatterSelector(),
  filteredScatterData: filteredScatterSelector(),
  trainList: fleetDataSelector(),
  loadingScatterB2: loadingScatterB2Selector(),
  loadingHistogram: loadingHistogramSelector(),
});

const mapDispatchToProps = {
  getMapData: fetchMapData,
  setDate,
  setSelectedTrain,
  setBasicFilter,
  setMainTrendSignal,
  setTab,
  getTrainViewMap,
  getTrainViewHistogram,
  getDetailViewTimetrend,
  setLoading,
  getDetailViewHistogram,
  getDetailViewScatterplot,
  setTimetrendLoading,
  fetchTrainList,
  clearDetailViewMap,
  clearDetailViewMapDateSlider,
  clearTrainViewMap,
  clearTrainViewHistogram,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
  clearBasicFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainViewPage);
