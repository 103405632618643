/*
 *
 * ErrorPages
 *
 */

import React from "react";
//import { connect } from 'react-redux';
import { Row, Col, Button } from "react-bootstrap";
require("./styles.scss");

export class NetworkError extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    // this.onClickToGo = this.onClickToGo.bind(this);
    //  this.getMessage = this.getMessage.bind(this);
  }

  componentDidMount() {
    if (navigator.onLine) {
      window.location.pathname = "/";
    }
  }

  render() {
    return (
      <div>
        {/* <Row>
          <Col md={12}>
            <div className="page-header">
              <span> WWO Monitoring </span>
            </div>
          </Col>
        </Row> */}

        <Col md={12}>
          <div className="page-content-div">
            <h1> Network error </h1>
            <h4>
              Please check your network connectivity and try loading the page
              again.
            </h4>
            <Button
              bsStyle="link"
              className=" btn btn-primary btn-sm"
              onClick={() => (window.location.pathname = "/")}
            >
              Click here to try again
            </Button>
          </div>
        </Col>
      </div>
    );
  }
}

export default NetworkError;
