export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGGEDIN = "LOGGEDIN";

//  for change password

export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAILURE = "CHANGEPASSWORD_FAILURE";
