import { all } from "redux-saga/effects";

import {
  fetachMapDataSaga,
  fetachTrainSaga,
} from "../containers/DashBoard/sagas";
import {
  getDetailViewMapSaga,
  getDetailViewTimetrendSaga,
  getDetailViewScatterplotSaga,
  getDetailViewHistogramSaga,
  getDetailViewMapDateSliderSaga,
} from "../containers/DetailTrainPage/sagas";
import {
  getTrainViewMapSaga,
  getTrainViewHistogramSaga,
} from "../containers/TrainViewPage/sagas";
import { loginSaga, changePassSaga } from "../containers/Login/sagas";

export function* rootSaga() {
  yield all([
    fetachTrainSaga(),
    fetachMapDataSaga(),
    getTrainViewMapSaga(),
    getTrainViewHistogramSaga(),
    getDetailViewMapSaga(),
    getDetailViewTimetrendSaga(),
    getDetailViewScatterplotSaga(),
    getDetailViewHistogramSaga(),
    loginSaga(),
    changePassSaga(),
    getDetailViewMapDateSliderSaga(),
  ]);
}
