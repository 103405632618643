import { combineReducers } from "redux-immutable";
import { reducerDashboard } from "../containers/DashBoard/reducers";
import trainViewReducer from "../containers/TrainViewPage/reducers";
import detailViewReducer from "../containers/DetailTrainPage/reducers";
import { reducerLogin } from "../containers/Login/reducers";

export const reducers = combineReducers({
  dashboardData: reducerDashboard,
  trainViewData: trainViewReducer,
  detailViewData: detailViewReducer,
  loginData: reducerLogin
});
