/**
 *
 * Loading
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../../components/LoadingIndicator';
require('./styles.scss');
// import debug from 'debug';

function Loading(props) {
  // debug('dev')('Loading:props', props);
  return (
    <div className={props.disabled ? 'hidden' : ''}>
      <LoadingIndicator />
    </div>
  );
}
Loading.defaultProps = {
  disabled: false,
};
Loading.propTypes = {
  disabled: PropTypes.bool,
};

export default Loading;
