import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import Loading from "../Loading";
import { IconWheel } from "../Icons";
import { FLEETLIST } from "./messages";

class FleetView extends React.Component {
  render() {
    return (
      <div className="fleetListContainer">
        <Card>
          <Card.Header className="fleetTitle"> Fleet view </Card.Header>
          <Card.Body>
            {this.props.fetching === true ? (
              <Loading />
            ) : (
              <div className="traindetailcontainer">
                <Row>
                  {this.props.trainData !== undefined &&
                  this.props.trainData !== null
                    ? this.props.trainData.map((train) => {
                        return (
                          <Col
                            md={12}
                            className={
                              train.td == "0.0 km"
                                ? "noLeftpadding noRightpadding disabledcontent"
                                : "noLeftpadding noRightpadding"
                            }
                            onMouseOver={() => this.props.onHover(train.nm)}
                            onMouseOut={this.props.onHoverOut}
                          >
                            <NavLink
                              to={train.td == "0.0 km" ? "#" : "#"}
                              className={
                                train.td == "0.0 km" ? "disabledcontent" : ""
                              }
                            >
                              <Row
                                className={
                                  train.td == "0.0 km"
                                    ? "fleetList disabledlink"
                                    : train.nm == this.props.routeSeleted
                                    ? "fleetList active"
                                    : "fleetList"
                                }
                                id={train.nm}
                                onClick={() => {
                                  this.props.onTrainSelect(train.nm);
                                }}
                              >
                                <Col
                                  md={12}
                                  className="align-self-center leftpadding noRightpadding"
                                >
                                  <div className="fleet-name">
                                    <span className="fleetImg">
                                      <img
                                        src={require("../../assets/images/train.svg")}
                                        className={""}
                                        alt="Train Name"
                                      />
                                    </span>
                                    <span className="boldText">{train.nm}</span>
                                  </div>
                                  <hr />
                                </Col>
                                <Col
                                  md={5}
                                  className="trainlistmobile leftpadding noRightpadding"
                                >
                                  <div className="fleetText">
                                    {FLEETLIST.TOTAL_DISTANCE}
                                  </div>
                                </Col>
                                <Col
                                  md={7}
                                  className="trainlistmobile noLeftpadding noRightpadding"
                                >
                                  <div className="boldText"> {train.td}</div>
                                </Col>
                                <Col
                                  md={5}
                                  className="trainlistmobile leftpadding noRightpadding"
                                >
                                  <div className="fleetText">
                                    {FLEETLIST.WEAR_INDEX}
                                    {/* <span className="fleetIcons">
                                      <IconWheel fill="" />
                                    </span> */}
                                  </div>
                                </Col>
                                <Col
                                  md={7}
                                  className="trainlistmobile noLeftpadding noRightpadding"
                                >
                                  <div className="boldText">{train.wix}</div>
                                </Col>
                                <Col
                                  md={5}
                                  className="trainlistmobile leftpadding noRightpadding"
                                >
                                  <div className="fleetText">
                                    {FLEETLIST.ENERGY_CONSUMPTION}
                                    {/* <span className="fleetIcons">
                                      <img
                                        src={require("../../assets/images/voltage.svg")}
                                        className={""}
                                        alt="Wear Index"
                                      />
                                    </span> */}
                                  </div>
                                </Col>
                                <Col
                                  md={7}
                                  className="trainlistmobile noLeftpadding noRightpadding"
                                >
                                  <div className="boldText">{train.ec}</div>
                                </Col>
                                <Col
                                  md={5}
                                  className="trainlistmobile leftpadding noRightpadding"
                                >
                                  <div className="fleetText">
                                    {FLEETLIST.RUNNING_TIME}
                                  </div>
                                </Col>
                                <Col
                                  md={7}
                                  className="trainlistmobile noLeftpadding noRightpadding"
                                >
                                  <div className="boldText"> {train.rtm}</div>
                                </Col>
                              </Row>
                            </NavLink>
                          </Col>
                        );
                      })
                    : ""}
                </Row>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

FleetView.defaultProps = {
  fetching: true,
};

FleetView.propTypes = {
  fetching: PropTypes.bool,
  formatedData: PropTypes.any,
};

export default FleetView;
