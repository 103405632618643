import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import {
  GET_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS,
  GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS,
  GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS,
  GET_DV_TT_EFFORTREFERENCE1_SUCCESS,
  GET_DV_TT_EFFORTREFERENCE2_SUCCESS,
  GET_DV_TT_SLIPPOWER1_SUCCESS,
  GET_DV_TT_SLIPPOWER2_SUCCESS,
  GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS,
  GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS,
  GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS,
  GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS,
  GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  GET_DETAILVIEW_TIMETREND_FAILURE,
  CLEAR_DETAILVIEW_TIMETREND,
  GET_DETAILVIEW_MAP,
  GET_DETAILVIEW_MAPSLIP1_SUCCESS,
  GET_DETAILVIEW_MAPSLIP2_SUCCESS,
  GET_DETAILVIEW_MAPSPEED1_SUCCESS,
  GET_DETAILVIEW_MAPSPEED2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORT1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORT2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS,
  GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS,
  GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS,
  GET_DV_MAPEFFORTREFERENCE1_SUCCESS,
  GET_DV_MAPEFFORTREFERENCE2_SUCCESS,
  GET_DV_MAPSLIPPOWER1_SUCCESS,
  GET_DV_MAPSLIPPOWER2_SUCCESS,
  GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS,
  GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS,
  GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS,
  GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS,
  GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS,
  GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS,
  GET_DETAILVIEW_MAP_FAILURE,
  CLEAR_DETAILVIEW_MAP,
  GET_DETAILVIEW_SCATTERPLOT,
  GET_DETAILVIEW_SCATTERPLOT_SUCCESS,
  GET_DETAILVIEW_SCATTERPLOT_FAILURE,
  GET_DETAILVIEW_SCATTERPLOT2_SUCCESS,
  GET_DETAILVIEW_SCATTERPLOT2_FAILURE,
  CLEAR_DETAILVIEW_SCATTERPLOT,
  SET_FILTER,
  SET_FILTER_ADVANCED,
  SET_MAINTRENDSIGNAL,
  SET_MAINSCATTERSIGNAL,
  ADD_OVERLAY,
  CLEAR_BASICFILTER,
  CLEAR_ADVANCEDFILTER,
  SET_LOADING,
  GET_DETAILVIEW_HISTOGRAM,
  GET_DETAILVIEW_HISTOGRAM_SUCCESS,
  GET_DETAILVIEW_HISTOGRAM_FAILURE,
  CLEAR_DETAILVIEW_HISTOGRAM,
  SET_TIMETREND_LOADING,
  GET_DETAILVIEW_MAPDATESLIDER,
  GET_DETAILVIEW_MAPDATESLIDER_SUCCESS,
  GET_DETAILVIEW_MAPDATESLIDER_FAILURE,
  CLEAR_DETAILVIEW_MAPDATESLIDER,
} from "./constants";

const initialState = fromJS({
  loading: false,
  loadingScatterB2: false,
  error: null,
  filter: { weather: "All", operation: [0, 1, 2, 3, 4, 5], bogie: 3 },
  filterAdvanced: {
    temperature: [1, 2, 3],
    drivingtrailervelocity: 2,
    sander: 2,
    toprotection: 2,
    // adhesioncontrol: 2,
    // slingbrake: 2,
    antislipbrake: 2,
  },
  selectedMainScatter: null,
  scatterplotData: null,
  scatterplotData2: null,
  histogramData: null,
});

function reducerCommon(state = initialState, action) {
  console.log("Action: ", action.type);
  switch (action.type) {
    case SET_FILTER:
      return state.set("filter", {
        weather: action.payload.weather,
        operation: action.payload.operation,
        bogie: action.payload.bogie,
      });

    case CLEAR_BASICFILTER:
      return state.set("filter", {
        weather: "All",
        operation: [0, 1, 2, 3, 4, 5],
        bogie: 3,
      });

    case SET_FILTER_ADVANCED:
      return state.set("filterAdvanced", {
        temperature: action.payload.temperature,
        drivingtrailervelocity: action.payload.drivingtrailervelocity,
        sander: action.payload.sander,
        toprotection: action.payload.toprotection,
        // adhesioncontrol: action.payload.adhesioncontrol,
        // slingbrake: action.payload.slingbrake,
        antislipbrake: action.payload.antislipbrake,
      });

    case CLEAR_ADVANCEDFILTER:
      return state.set("filterAdvanced", {
        temperature: [1, 2, 3],
        drivingtrailervelocity: 2,
        sander: 2,
        toprotection: 2,
        // adhesioncontrol: 2,
        // slingbrake: 2,
        antislipbrake: 2,
      });

    //------------------------------

    case SET_LOADING:
      return state.set("loading", action.payload);

    case SET_MAINSCATTERSIGNAL:
      return state.set("selectedMainScatter", action.payload.scatter);

    case GET_DETAILVIEW_SCATTERPLOT:
      return state
        .set("loading", true)
        .set("loadingScatterB2", true)
        .set("error", null)
        .set("scatterplotData2", null)
        .set("scatterplotData", null);
    case GET_DETAILVIEW_SCATTERPLOT_SUCCESS:
      return state.set("loading", false).set("scatterplotData", action.payload);
    case GET_DETAILVIEW_SCATTERPLOT_FAILURE:
      return state
        .set("loading", false)
        .set("scatterplotData", null)
        .set("error", action.payload);

    case GET_DETAILVIEW_SCATTERPLOT2_SUCCESS:
      return state
        .set("loadingScatterB2", false)
        .set("scatterplotData2", action.payload);
    case GET_DETAILVIEW_SCATTERPLOT_FAILURE:
      return state
        .set("loading", false)
        .set("scatterplotData2", null)
        .set("error", action.payload);
    case CLEAR_DETAILVIEW_SCATTERPLOT:
      return state.set("scatterplotData", null).set("scatterplotData2", null);

    //---------------------

    case GET_DETAILVIEW_HISTOGRAM:
      return state
        .set("loading", true)
        .set("error", null)
        .set("histogramData", null);
    case GET_DETAILVIEW_HISTOGRAM_SUCCESS:
      return state.set("loading", false).set("histogramData", action.payload);
    case GET_DETAILVIEW_HISTOGRAM_FAILURE:
      return state
        .set("loading", false)
        .set("histogramData", null)
        .set("error", action.payload);
    case CLEAR_DETAILVIEW_HISTOGRAM:
      return state.set("histogramData", null);
    default:
      return state;
  }
}

const mapInitialState = fromJS({
  loading: false,
  error: null,
  mapSlip1: null,
  mapSlip2: null,
  mapSpeed1: null,
  mapSpeed2: null,
  mapEffort1: null,
  mapEffort2: null,
  mapEffortDriving1: null,
  mapEffortDriving2: null,
  mapEffortBraking1: null,
  mapEffortBraking2: null,
  mapEffortBrakingMech1: null,
  mapEffortBrakingMech2: null,
  mapDrivingTrailerVel1: null,
  mapEffortReference1: null,
  mapEffortReference2: null,
  mapSlipPower1: null,
  mapSlipPower2: null,
  mapPowerBrakingMechanical1: null,
  mapPowerBrakingMechanical2: null,
  mapAirPressureBrakeCylinder1: null,
  mapAirPressureBrakeCylinder2: null,
  mapAmplitudeTorsionalOscillation1: null,
  mapAmplitudeTorsionalOscillation2: null,
  selectedMainMap: null,
  totalRuns: null,
});

function reducerMap(state = mapInitialState, action) {
  console.log("Action: ", action.type);
  switch (action.type) {
    case GET_DETAILVIEW_MAP:
      return state.set("loading", true).set("error", null);
    case GET_DETAILVIEW_MAPSLIP1_SUCCESS:
      return state.set("loading", false).set("mapSlip1", action.payload);
    case GET_DETAILVIEW_MAPSLIP2_SUCCESS:
      return state.set("loading", false).set("mapSlip2", action.payload);
    case GET_DETAILVIEW_MAPSPEED1_SUCCESS:
      return state.set("loading", false).set("mapSpeed1", action.payload);
    case GET_DETAILVIEW_MAPSPEED2_SUCCESS:
      return state.set("loading", false).set("mapSpeed2", action.payload);
    case GET_DETAILVIEW_MAPEFFORT1_SUCCESS:
      return state.set("loading", false).set("mapEffort1", action.payload);
    case GET_DETAILVIEW_MAPEFFORT2_SUCCESS:
      return state.set("loading", false).set("mapEffort2", action.payload);
    case GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortDriving1", action.payload);
    case GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortDriving2", action.payload);
    case GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBraking1", action.payload);
    case GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBraking2", action.payload);
    case GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBrakingMech1", action.payload);
    case GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortBrakingMech2", action.payload);

    case GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapDrivingTrailerVel1", action.payload);
    case GET_DV_MAPEFFORTREFERENCE1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortReference1", action.payload);
    case GET_DV_MAPEFFORTREFERENCE2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapEffortReference2", action.payload);
    case GET_DV_MAPSLIPPOWER1_SUCCESS:
      return state.set("loading", false).set("mapSlipPower1", action.payload);
    case GET_DV_MAPSLIPPOWER2_SUCCESS:
      return state.set("loading", false).set("mapSlipPower2", action.payload);
    case GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapPowerBrakingMechanical1", action.payload);
    case GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapPowerBrakingMechanical2", action.payload);
    case GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapAirPressureBrakeCylinder1", action.payload);
    case GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapAirPressureBrakeCylinder2", action.payload);
    case GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS:
      return state
        .set("loading", false)
        .set("mapAmplitudeTorsionalOscillation1", action.payload);
    case GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS:
      return state
        .set("loading", false)
        .set("mapAmplitudeTorsionalOscillation2", action.payload);

    case GET_DETAILVIEW_MAP_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    case CLEAR_DETAILVIEW_MAP:
      return state
        .set("mapSlip1", null)
        .set("mapSlip2", null)
        .set("mapSpeed1", null)
        .set("mapSpeed2", null)
        .set("mapEffort1", null)
        .set("mapEffort2", null)
        .set("mapEffortDriving1", null)
        .set("mapEffortDriving2", null)
        .set("mapEffortBraking1", null)
        .set("mapEffortBraking2", null)
        .set("mapEffortBrakingMech1", null)
        .set("mapEffortBrakingMech2", null)
        .set("mapDrivingTrailerVel1", null)
        .set("mapEffortReference1", null)
        .set("mapEffortReference2", null)
        .set("mapSlipPower1", null)
        .set("mapSlipPower2", null)
        .set("mapPowerBrakingMechanical1", null)
        .set("mapPowerBrakingMechanical2", null)
        .set("mapAirPressureBrakeCylinder1", null)
        .set("mapAirPressureBrakeCylinder2", null)
        .set("mapAmplitudeTorsionalOscillation1", null)
        .set("mapAmplitudeTorsionalOscillation2", null);

    case GET_DETAILVIEW_MAPDATESLIDER:
      return state.set("loading", true).set("error", null);
    case GET_DETAILVIEW_MAPDATESLIDER_SUCCESS:
      return state.set("loading", false).set("totalRuns", action.payload);
    case GET_DETAILVIEW_MAPDATESLIDER_FAILURE:
      return state
        .set("loading", false)
        .set("totalRuns", null)
        .set("error", action.payload);
    case CLEAR_DETAILVIEW_MAPDATESLIDER:
      return state.set("totalRuns", null);

    default:
      return state;
  }
}

const timetrendInitialState = fromJS({
  loading: false,
  error: null,
  selectedMainTrend: "Speed Driving Trailer",
  selectedOverlaysTrend: null,
  timetrendDataSlip1: null,
  timetrendDataSlip2: null,
  timetrendDataSpeed1: null,
  timetrendDataSpeed2: null,
  timetrendDataEffort1: null,
  timetrendDataEffort2: null,
  timetrendDataEffortDriving1: null,
  timetrendDataEffortDriving2: null,
  timetrendDataEffortBraking1: null,
  timetrendDataEffortBraking2: null,
  timetrendDataEffortBrakingMech1: null,
  timetrendDataEffortBrakingMech2: null,
  //************* */
  ttDataDrivingTrailerVel1: null,
  ttDataEffortReference1: null,
  ttDataEffortReference2: null,
  ttDataSlipPower1: null,
  ttDataSlipPower2: null,
  ttDataPowerBrakingMechanical1: null,
  ttDataPowerBrakingMechanical2: null,
  ttDataAirPressureBrakeCylinder1: null,
  ttDataAirPressureBrakeCylinder2: null,
  ttDataAmplitudeTorsionalOscillation1: null,
  ttDataAmplitudeTorsionalOscillation2: null,
});

function reducerTimetrend(state = timetrendInitialState, action) {
  console.log("Action: ", action.type);
  switch (action.type) {
    case GET_DETAILVIEW_TIMETREND:
      return state.set("loading", true).set("error", null);
    case GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS:
      return state.set("timetrendDataSlip1", action.payload);
    case GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS:
      return state.set("timetrendDataSlip2", action.payload);
    case GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS:
      return state.set("timetrendDataSpeed1", action.payload);
    case GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS:
      return state.set("timetrendDataSpeed2", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS:
      return state.set("timetrendDataEffort1", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS:
      return state.set("timetrendDataEffort2", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS:
      return state.set("timetrendDataEffortDriving1", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS:
      return state.set("timetrendDataEffortDriving2", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS:
      return state.set("timetrendDataEffortBraking1", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS:
      return state.set("timetrendDataEffortBraking2", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS:
      return state.set("timetrendDataEffortBrakingMech1", action.payload);
    case GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS:
      return state.set("timetrendDataEffortBrakingMech2", action.payload);

    case GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS:
      return state.set("ttDataDrivingTrailerVel1", action.payload);
    case GET_DV_TT_EFFORTREFERENCE1_SUCCESS:
      return state.set("ttDataEffortReference1", action.payload);
    case GET_DV_TT_EFFORTREFERENCE2_SUCCESS:
      return state.set("ttDataEffortReference2", action.payload);
    case GET_DV_TT_SLIPPOWER1_SUCCESS:
      return state.set("ttDataSlipPower1", action.payload);
    case GET_DV_TT_SLIPPOWER2_SUCCESS:
      return state.set("ttDataSlipPower2", action.payload);
    case GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS:
      return state.set("ttDataPowerBrakingMechanical1", action.payload);
    case GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS:
      return state.set("ttDataPowerBrakingMechanical2", action.payload);
    case GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS:
      return state.set("ttDataAirPressureBrakeCylinder1", action.payload);
    case GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS:
      return state.set("ttDataAirPressureBrakeCylinder2", action.payload);
    case GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS:
      return state.set("ttDataAmplitudeTorsionalOscillation1", action.payload);
    case GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS:
      return state.set("ttDataAmplitudeTorsionalOscillation2", action.payload);

    case GET_DETAILVIEW_TIMETREND_FAILURE:
      return (
        state
          .set("loading", false)
          //.set("timetrendDataSlip1", null)
          .set("error", action.payload)
      );

    case SET_MAINTRENDSIGNAL:
      return state.set("selectedMainTrend", action.payload.trend);

    case ADD_OVERLAY:
      return state.set("selectedOverlaysTrend", action.payload);

    case SET_TIMETREND_LOADING:
      return state.set("loading", action.payload);

    case CLEAR_DETAILVIEW_TIMETREND:
      return state
        .set("timetrendDataSlip1", null)
        .set("timetrendDataSlip2", null)
        .set("timetrendDataSpeed1", null)
        .set("timetrendDataSpeed2", null)
        .set("timetrendDataEffort1", null)
        .set("timetrendDataEffort2", null)
        .set("timetrendDataEffortDriving1", null)
        .set("timetrendDataEffortDriving2", null)
        .set("timetrendDataEffortBraking1", null)
        .set("timetrendDataEffortBraking2", null)
        .set("timetrendDataEffortBrakingMech1", null)
        .set("timetrendDataEffortBrakingMech2", null)

        .set("ttDataDrivingTrailerVel1", null)
        .set("ttDataEffortReference1", null)
        .set("ttDataEffortReference2", null)
        .set("ttDataSlipPower1", null)
        .set("ttDataSlipPower2", null)
        .set("ttDataPowerBrakingMechanical1", null)
        .set("ttDataPowerBrakingMechanical2", null)
        .set("ttDataAirPressureBrakeCylinder1", null)
        .set("ttDataAirPressureBrakeCylinder2", null)
        .set("ttDataAmplitudeTorsionalOscillation1", null)
        .set("ttDataAmplitudeTorsionalOscillation2", null);

    default:
      return state;
  }
}

const detailViewReducer = combineReducers({
  reducerCommon: reducerCommon,
  reducerMap: reducerMap,
  reducerTimetrend: reducerTimetrend,
});

export default detailViewReducer;
