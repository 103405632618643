import axios from "axios";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { returnData } from "./api-utils";
import auth from "./auth";
import apiConfig from "./api-config";
import { fleetData, mapData } from "../containers/DashBoard/mockData";
import { timetrend } from "../mockdata/detailtrainviewtimetrend";
import { scatterdata } from "../mockdata/detailtrainviewscatter";
import { trainviewMapData } from "../mockdata/trainviewmapdata";
import { trainviewGraphData } from "../mockdata/trainviewgraphdata";
//import { histogramdata } from "../mockdata/detailtrainviewhistogram";
import debug from "debug";

moment.locale("en");
momentLocalizer();

const { baseUrl } = apiConfig;

const config = () => {
  return {
    baseURL: baseUrl,
    headers: { "Content-Type": "application/json" },
  };
};

const catchError = (error) => {
  if (!error.response && !navigator.onLine) {
    return (window.location.pathname = "/networkError");
  } else if (!error.response && navigator.onLine) {
    //return (window.location.pathname = "/serverError");
  } else if (error.response) {
    return Promise.reject(error.response);
  }
  return Promise.reject(error);
};

//Dashboard
const fetchTrainListData = (startDate, endDate) =>
  axios
    .get(
      `/api/v2/fleetView?startDate=${startDate}&endDate=${endDate}`,
      config()
    )
    .then(returnData)
    .catch(catchError);

const fetchMapDataList = (startDate, endDate) =>
  axios
    // .get(`/api/v1/mapView?startDate=${startDate}&endDate=${endDate}`, config())
    .get(
      `/api/v1/dashBoardMapViewRunWise?startDate=${startDate}&endDate=${endDate}`,
      config()
    )
    .then(returnData)
    .catch(catchError);

//TrainView
const fetchTrainViewMapData = (id, startDate, endDate, docType, bogie) => {
  return axios
    .get(
      //`/api/v1/locomotivesGPSTimeArrowBogieSpecific?id=678910&docType=${docType}&bogie=${bogie}&startDate=2019-07-03T05%3A10%3A55&endDate=2019-07-03T06%3A26%3A15`,
      //`/api/v1/locomotivesGPSTimeArrowBogieSpecific?name=${id}&docType=${docType}&bogie=${bogie}&startDate=${startDate}&endDate=${endDate}`,
      `/api/v2/locomotiveRuns?name=${id}&docType=${docType}&bogie=${bogie}&startDate=${startDate}&endDate=${endDate}&filterRuns=false`,
      config()
    )
    .then(returnData)
    .catch(catchError);
  //return timetrend.payload;
};

const fetchTrainViewGraphData = (id, startDate, endDate) => {
  //return timetrend.payload; //trainviewGraphData;
  return axios
    .get(
      `/api/v1/locomotivesAggregatedSignals?id=${id}&startDate=${startDate}&endDate=${endDate}`,
      config()
    )
    .then(returnData)
    .catch(catchError);
};

//DetailView
const fetchDetailViewMapData = (id, startDate, endDate, docType, bogie) => {
  let myDate;
  if (sessionStorage.getItem("clickedTimeStamp") !== null) {
    myDate = moment(
      sessionStorage.getItem("clickedTimeStamp"),
      "DD.MM.YYYY, HH:mm:ss"
    )
      .format("YYYY-MM-DDTHH:mm:ss")
      .replace(/:/g, "%3A");
  } else {
    myDate = "0001-01-01T00%3A00%3A00";
  }
  return axios
    .get(
      //`/api/v1/locomotivesGPSTimeArrowBogieSpecific?id=678910&docType=${docType}&bogie=${bogie}&startDate=2019-07-03T05%3A10%3A55&endDate=2019-07-03T06%3A26%3A15`,
      //`/api/v1/locomotivesGPSTimeArrowBogieSpecific?id=678910&docType=${docType}&bogie=${bogie}&startDate=${startDate}&endDate=${endDate}`,
      `/api/v2/locomotiveRuns?name=${id}&docType=${docType}&bogie=${bogie}&startDate=${startDate}&endDate=${endDate}&clickedTimeStamp=${myDate}&filterRuns=true`,
      config()
    )
    .then(returnData)
    .catch(catchError);
  //return timetrend.payload;
};

const fetchDetailViewTimetrendData = (
  id,
  startDate,
  endDate,
  docType,
  bogie
) => {
  return axios
    .get(
      //`/api/v1/locomotivesSignalBogieSpecific?id=678910&startDate=2019-07-03T05%3A10%3A55&endDate=2019-07-03T06%3A26%3A15&docType=${docType}&bogie=${bogie}`,
      `/api/v2/locomotivesSignalBogieSpecific?name=${id}&startDate=${startDate}&endDate=${endDate}&docType=${docType}&bogie=${bogie}`,
      config()
    )
    .then(returnData)
    .catch(catchError);
  //return timetrend.payload;
};

const fetchDetailViewScatterplotData = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  bogie,
  scatterplotAvg
) => {
  return axios
    .get(
      `/api/v3/locomotivesScatterBogieSpecific?name=${id}&startDate=${startDate}&endDate=${endDate}&xAxisSignal=${xAxisSignal}&yAxisSignal=${yAxisSignal}&bogie=${bogie}&avg=${scatterplotAvg}`,
      config()
    )
    .then(returnData)
    .catch(catchError);
};

//------------------------------------------------date diff
const getDateDiff = (StartDate, EndDate) => {
  if (StartDate && EndDate) {
    let duration = moment.duration(
      moment(EndDate.replace(/%3A/g, ":")).diff(
        moment(StartDate.replace(/%3A/g, ":"))
      )
    );
    let totaldays = duration.asDays();
    let TotalHours = duration.asHours();
    console.log("datediffinapijs", totaldays);
    return { totaldays, TotalHours };
  }

  return "";
};

const fetchDetailViewHistogramData = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  zAxisSignal,
  filter
) => {
  /*let { totaldays, TotalHours } = getDateDiff(startDate, endDate);
  if (totaldays > 3) {
  return {
    locomotiveName: null,
    signalConfig: null,
    documentType: null,
    filterType: null,
    startDate: "0001-01-01T00:00:00",
    endDate: "0001-01-01T00:00:00",
    binInterval: 0,
    data: []
  };
  }*/
  let filterToSend = `B1B2|operationmode:[${[1, 2, 3, 4, 5]}]`;
  if (filter !== undefined) {
    if (filter[0].bogie === 3) {
      filterToSend = `B1B2|operationmode:[${[1, 2, 3, 4, 5]}]`;
    } else if (filter[0].bogie === 1) {
      filterToSend = `B1|operationmode:[${[1, 2, 3, 4, 5]}]`;
    } else if (filter[0].bogie === 2) {
      filterToSend = `B2|operationmode:[${[1, 2, 3, 4, 5]}]`;
    }
  }
  //return histogramdata;
  return axios
    .get(
      `/api/v2/locomotiveHistogram?name=${id}&startDate=${startDate}&endDate=${endDate}&xAxisSignal=${xAxisSignal}&yAxisSignal=${yAxisSignal}&zAxisSignal=${zAxisSignal}&filterType=${filterToSend}&isTrainView=false`,
      config()
    )
    .then(returnData)
    .catch(catchError);
};

const fetchTrainViewHistogramData = (
  id,
  startDate,
  endDate,
  xAxisSignal,
  yAxisSignal,
  zAxisSignal,
  filter
) => {
  let filterToSend = `B1|operationmode:[${[1, 2, 3, 4, 5]}]`;
  return axios
    .get(
      `/api/v2/locomotiveHistogram?name=${id}&startDate=${startDate}&endDate=${endDate}&xAxisSignal=${xAxisSignal}&yAxisSignal=${yAxisSignal}&zAxisSignal=${zAxisSignal}&filterType=${filterToSend}&isTrainView=true`,
      config()
    )
    .then(returnData)
    .catch(catchError);
};

const fetchDetailViewMapDateSliderData = (id, startDate, endDate) => {
  return axios
    .get(
      `/api/v2/locomotiveRunsCountDateWise?name=${id}&startDate=${startDate}&endDate=${endDate}&filterRuns=true`,
      config()
    )
    .then(returnData)
    .catch(catchError);
};
// const checkLogin = (username, password) => {
//   if (username === "admin" && password === "admin") {
//     return true;
//   }
//   return false;
// };

const checkLogin = (username, password) =>
  axios
    .get(`/api/v1/validate?userName=${username}&password=${password}`, config())
    .then(returnData)
    .catch(catchError);

const changePassword = (userName, oldpassword, newpassword) => {
  const data = {
    userName: userName,
    oldPassword: oldpassword,
    newPassword: newpassword,
  };
  return axios
    .put("/api/v1/resetCredential", data, config())
    .then(returnData)
    .catch(catchError);
};
const api = {
  fetchTrainListData,
  fetchMapDataList,
  fetchTrainViewMapData,
  fetchTrainViewHistogramData,
  fetchTrainViewGraphData,
  fetchDetailViewMapData,
  fetchDetailViewTimetrendData,
  fetchDetailViewScatterplotData,
  fetchDetailViewHistogramData,
  checkLogin,
  changePassword,
  fetchDetailViewMapDateSliderData,
};

export default api;
