import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGGEDIN,
  CHANGEPASSWORD,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAILURE
} from "./constants";

//Login
export const logIn = (username, password) => ({
  type: LOGIN,
  payload: { username, password }
});

export const logInSucess = loginresponce => ({
  type: LOGIN_SUCCESS,
  loginresponce
});

export const logInFailure = error => ({
  type: LOGIN_FAILURE,
  error
});

export const loggedIn = isloggedIn => ({
  type: LOGGEDIN,
  isloggedIn: isloggedIn
});

//  for change password

export const changePassword = (userName, oldpassword, newpassword) => ({
  type: CHANGEPASSWORD,
  payload: { userName, oldpassword, newpassword }
});

export const changePassSucess = changepassresponce => ({
  type: CHANGEPASSWORD_SUCCESS,
  changepassresponce
});

export const changePassFailure = error => ({
  type: CHANGEPASSWORD_FAILURE,
  error
});
