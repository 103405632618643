import React from "react";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { Row, Col, Button } from "react-bootstrap";
import DateDiffText from "../DateDiffText";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import "./styles.scss";
import messages from "./messages";

Moment.locale("en");
momentLocalizer();

export default class Datepicker extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      startDate: null,
      endDate: null
    };
  }

  componentDidMount() {
    this.state.startDate =
      this.state.startDate != null
        ? new Date(this.state.startDate)
        : new Date(this.props.startDate.replace(/%3A/g, ":"));
    this.state.endDate =
      this.state.endDate != null
        ? new Date(this.state.endDate)
        : new Date(this.props.endDate.replace(/%3A/g, ":"));
  }

  handleDatePickerChange = (name, value) => {
    this.setState({ [name]: value });
  };

  checkDateValidation = () => {
    // check the dates
    const diff = Moment(this.state.endDate).diff(Moment(this.state.startDate));
    const d = Moment.duration(diff);

    const days = Math.floor(d.asHours() / 24);
    const hours = Math.floor(d.asHours() - days * 24);
    const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
    let msg = days > 0 ? ` ${days} days` : "";
    msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : "";
    msg += minutes > 0 ? ` ${minutes} minutes` : "";

    if (days === 0 && hours === 0 && minutes === 0) {
      return false;
    } else if (days < 0 || hours < 0 || minutes < 0) {
      return false;
    } else if (days > 90) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const isValid = this.checkDateValidation();
    return (
      <Row className="datePickerContainer">
        <Col
          xs={12}
          sm={12}
          md={3}
          className="selectmonitoringrange noLeftpadding noRightpadding boldText"
        >
          Select monitoring period
        </Col>
        <Col xs={12} sm={6} md={3} className="datePickerTextbox">
          <DateTimePicker
            //step={5}
            max={
              new Date(
                Moment()
                  .subtract(1, "days")
                  .endOf("day")
              )
            }
            // min={new Date(Moment().subtract(91, "days"))}
            format={"DD.MM.YYYY, HH:mm"}
            timeFormat={"HH:mm"}
            //defaultValue={new Date(this.props.startDate.replace(/%3A/g, ":"))}
            value={
              this.state.startDate != null
                ? new Date(this.state.startDate)
                : new Date(this.props.startDate.replace(/%3A/g, ":"))
            }
            onChange={value => this.handleDatePickerChange("startDate", value)}
            inputProps={{
              component: props => <input {...props} readOnly />
            }}
          />
          <div className="error-message-time">
            {!isValid ? <FormattedMessage {...messages.problem} /> : ""}
          </div>
        </Col>
        To
        <Col
          xs={12}
          sm={6}
          md={3}
          className=" noRightpadding datePickerTextbox"
        >
          <DateTimePicker
            //step={10}
            // min={new Date(Moment().subtract(91, "days"))}
            max={
              new Date(
                Moment()
                  .subtract(1, "days")
                  .endOf("day")
              )
            }
            format={"DD.MM.YYYY, HH:mm"}
            timeFormat={"HH:mm"}
            //defaultValue={new Date(this.props.endDate.replace(/%3A/g, ":"))}

            value={
              this.state.endDate != null
                ? new Date(this.state.endDate)
                : new Date(this.props.endDate.replace(/%3A/g, ":"))
            }
            onChange={value => this.handleDatePickerChange("endDate", value)}
            inputProps={{
              component: props => <input {...props} readOnly />
            }}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          className=" datediff  noRightpadding boldText"
        >
          <DateDiffText
            startdate={
              this.state.startDate != null
                ? this.state.startDate
                : this.props.startDate.replace(/%3A/g, ":")
            }
            enddate={
              this.state.endDate != null
                ? this.state.endDate
                : this.props.endDate.replace(/%3A/g, ":")
            }
          />
          <Button
            className="smallButton"
            variant="primary"
            size="sm"
            onClick={() =>
              this.props.onApplyClick(this.state.startDate, this.state.endDate)
            }
            disabled={!isValid}
          >
            Apply
          </Button>
        </Col>
      </Row>
    );
  }
}
