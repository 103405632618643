import Highcharts from "highcharts/highstock";
//import { histogramdata } from "../../mockdata/detailtrainviewhistogram";
import exportChart from "../../assets/images/exportChart.svg";
import debug from "debug";
import Moment from "moment";

export let xMin, xMax;
export const colors = [
  "#5abfae", //Teal 40
  "#ED5739", //  Orange 50
  "#005CA3", //Blue 70
  "#d98f45", //Gold 40
  "#1e6119", //Green 70
  "#a30005", // Red 80
  "#686868", //Grey 60
  "#753B00", //Gold 70
  "#E64997", // Magenta 50
  "#b5aa0d", //Yellow 40
];

export function checkChatValues(chartValues) {
  let isVisible = "";
  for (let i = 0; i < chartValues.length - 1; i++) {
    if (
      chartValues[i].config.label.indexOf("B1") !== -1 &&
      chartValues[i + 1].config.label.indexOf("B2") !== -1
    ) {
      isVisible = false;
    } else {
      isVisible = true;
    }
  }
  return isVisible;
}

export function getSingleTrendData(
  selectedOverlays,
  chart,
  selectedMain,
  filter,
  advanced_filter,
  loadMoreFunc
) {
  const ranges = [];
  const average = [];
  const configs = [];
  const colors = [
    "#5abfae", //Teal 40
    "#ED5739", //  Orange 50
    "#005CA3", //Blue 70
    "#d98f45", //Gold 40
    "#1e6119", //Green 70
    "#a30005", // Red 80
    "#686868", //Grey 60
    "#753B00", //Gold 70
    "#E64997", // Magenta 50
    "#b5aa0d", //Yellow 40
  ];
  let rangedata = [],
    averages = [],
    xaxisvalue = [],
    seriesValues = [],
    xAxis,
    yAxis = [];

  console.log(
    "Filter: ",
    filter.operation,
    " Chart: ",
    chart,
    " SelectedMain: ",
    selectedMain
  );

  let chartValues = chart;
  console.log("hideB2Y", checkChatValues(chartValues), chartValues);
  /*chartValues.push(...chart.filter(item => item.config.label === selectedMain));
  selectedOverlays &&
    selectedOverlays.map(value =>
      chartValues.push(...chart.filter(item => item.config.label === value))
    );*/

  for (let j = 0; j < chartValues.length; j += 1) {
    rangedata = [];
    averages = [];
    xaxisvalue = [];
    for (let i = 0; i < chartValues[j].data.length; i += 1) {
      const item = chartValues[j].data[i];
      // if (!item.value) {
      let formatedDate = Moment.utc(item.ts).format("DD.MM.YYYY HH:mm:ss");
      xaxisvalue.push([formatedDate]);
      averages.push([formatedDate, item.avg]);
      rangedata.push([formatedDate, item.min, item.max]);
      // } else {
      //   averages.push([item.timestamp, item.value]);
      // }
    }
    let current, previous, next, rangecurrent, rangeprevious, rangenext;
    averages.forEach(function (item, index) {
      current = item[1];
      // console.log("Current: " + item[1]);
      if (index > 0) {
        previous = averages[index - 1][1];
        // console.log("Previous: " + averages[index - 1][1]);
      }
      if (index < averages.length - 1) {
        next = averages[index + 1][1];
        // console.log("Next: " + averages[index + 1][1]);
      }
      if (current !== null && previous === null && next === null) {
        averages[index] = {
          y: item[1],
          marker: {
            enabled: true,
          },
        };
      }
    });
    rangedata.forEach(function (item, index) {
      rangecurrent = item[1];
      // console.log("Current: " + item[1]);
      if (index > 0) {
        rangeprevious = rangedata[index - 1][1];
        // console.log("Previous: " + averages[index - 1][1]);
      }
      if (index < rangedata.length - 1) {
        rangenext = rangedata[index + 1][1];
        // console.log("Next: " + averages[index + 1][1]);
      }
      if (
        rangecurrent !== null &&
        rangeprevious === null &&
        rangenext === null
      ) {
        rangedata[index] = {
          low: item[1],
          high: item[2],
          marker: {
            enabled: true,
          },
        };
      }
    });
    seriesValues.push(
      {
        name: chartValues[j].config.label,
        type: "line",
        color: colors[j],
        data: averages,
        marker: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: " " + chartValues[j].config.unit,
        },
        yAxis: j,
        boostThreshold: 0,
        turboThreshold: Number.MAX_VALUE,
        zIndex: 1,
      },
      {
        name: chartValues[j].config.label,
        type: "arearange",
        data: rangedata,
        lineWidth: 0,
        linkedTo: ":previous",
        color: colors[j],
        fillOpacity: 0.3,
        zIndex: 0,
        marker: {
          enabled: false,
        },
        tooltip: {
          valueSuffix: " " + chartValues[j].config.unit,
        },
        yAxis: j,
        boostThreshold: 0,
        turboThreshold: Number.MAX_VALUE,
      }
    );

    yAxis.push({
      opposite: j !== 0,
      // startOnTick: false,
      min:
        chartValues[j].config.label.indexOf("Torsional Osc. Ampl.") !== -1
          ? 0
          : chartValues[j].config.label.indexOf("Power Mechanical Brake") !== -1
          ? -1500
          : chartValues[j].config.label.indexOf("Power Slip") !== -1
          ? -300
          : chartValues[j].config.label.indexOf("Slip") !== -1
          ? -15
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? -160
          : chartValues[j].config.label.indexOf("Speed at Wheel") !== -1
          ? 0
          : chartValues[j].config.label.indexOf("Speed Driving Trailer") !== -1
          ? 0
          : null,
      max:
        chartValues[j].config.label.indexOf("Torsional Osc. Ampl.") !== -1
          ? 100
          : chartValues[j].config.label.indexOf("Power Mechanical Brake") !== -1
          ? 1500
          : chartValues[j].config.label.indexOf("Power Slip") !== -1
          ? 300
          : chartValues[j].config.label.indexOf("Slip") !== -1
          ? 15
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? 160
          : chartValues[j].config.label.indexOf("Speed at Wheel") !== -1
          ? 225
          : chartValues[j].config.label.indexOf("Speed Driving Trailer") !== -1
          ? 225
          : null,
      tickInterval:
        chartValues[j].config.label.indexOf("Effort Mech. Brake") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("EffortDriving") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("EffortBraking") !== -1
          ? 27
          : chartValues[j].config.label.indexOf("Effort") !== -1
          ? 27
          : undefined,
      tickAmount: 7,
      visible:
        chartValues[j].config.label.indexOf("B2") !== -1 &&
        checkChatValues(chartValues) === false
          ? false
          : true,
      title: {
        enabled: false,
        text: `${chartValues[j].config.label} ${chartValues[j].config.unit}`,
        style: {
          color: j !== 0 ? colors[j] : colors[0],
        },
      },
      labels: {
        format: `{value} ${chartValues[j].config.unit}`,
        style: {
          color: j !== 0 ? colors[j] : colors[0],
        },
      },
      id: `${chartValues[j].config.label}`,
    });

    xAxis = [
      {
        categories: xaxisvalue,
        crosshair: true,
        gridLineWidth: 1,
        gridLineColor: "#e9e9e9",
        tickInterval: 10,
        crosshair: {
          width: 1,
          color: "#0b73c2",
        },
        minTickInterval: 5,
        events: {
          afterSetExtremes: function (event) {
            const currentChart = event.target;
            if (currentChart && currentChart.chart) {
              const { min, max } = currentChart.chart.xAxis[0].getExtremes();
              if (
                typeof event.trigger !== "undefined" &&
                event.trigger === "zoom"
              ) {
                xMin = min;
                xMax = max;
                if (
                  event.trigger === "zoom" &&
                  event.min === event.dataMin &&
                  event.max === event.dataMax
                ) {
                  // loadMoreFunc(
                  //   currentChart.chart.xAxis[0].categories[Math.floor(xMin)],
                  //   currentChart.chart.xAxis[0].categories[Math.ceil(xMax)],
                  //   false
                  // );
                } else {
                  loadMoreFunc(
                    currentChart.chart.xAxis[0].categories[Math.floor(xMin)],
                    currentChart.chart.xAxis[0].categories[Math.ceil(xMax)],
                    true
                  );
                }
              }
              // if (min != 0) {
              //   xMin = min;
              //   xMax = max;
              // }

              let resetbtn = document.getElementsByClassName(
                "highcharts-reset-zoom"
              );

              if (resetbtn.length > 0) {
                resetbtn[0].style.display = "none";
              }
            }
          },
        },
        labels: {
          // formatter: function() {
          //   return Highcharts.dateFormat(
          //     "%d.%m.%Y %H:%M:%S",
          //     this.value * 1000
          //   ); // milliseconds not seconds
          // }
        },
      },
    ];
  }

  return {
    xAxis,
    yAxis,
    series: seriesValues,
  };
}

export function getTrendChart(
  selectedOverlays,
  chart,
  selectedMain,
  filter,
  advanced_filter,
  loadMoreFunc
) {
  console.log("Timetrend Func()");
  const data = getSingleTrendData(
    selectedOverlays,
    chart,
    selectedMain,
    filter,
    advanced_filter,
    loadMoreFunc
  );
  debug("dev")("getTrendChart:data", data);
  let options = {
    chart: {
      name: "trendData",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: "x",
      panning: true,
      panKey: "shift",
      marginBottom: 60,
      // borderColor: "#f03040",
      // borderWidth: 1,
      height: 600,
      marginBottom: 100,
      events: {
        load: function () {
          document.getElementById("chartLoader").style.display = "none";
        },
      },
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    title: false,
    // {
    //   // text: `<span class="sampleinterval">(${chart[0].config.samptime} sampling)</span>`,
    //   // align: "right",
    //   // y: 35,

    // },
    subtitle: {
      text: `<span class="sampleinterval">(${chart[0].config.samptime} sampling)</span>`,
      align: "right",
      y: 5,
    },
    xAxis: data.xAxis,
    yAxis: data.yAxis,
    tooltip: {
      shared: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
        point: {
          events: {
            click: function () {
              console.log("Time: ", this, this.series.name);
              sessionStorage.setItem("clickedTimeStamp", this.category);
              sessionStorage.setItem(
                "clickedTimeStampForRunSlider",
                this.category
              );
              sessionStorage.setItem(
                "clickedSignal",
                this.series.name.replace(/B1|B2/gi, "")
              );
              if (
                sessionStorage.getItem("clickedTimeStampForRunSlider") !==
                  null &&
                sessionStorage.getItem("clickedTimeStamp") !== null &&
                sessionStorage.getItem("clickedSignal") !== null
              ) {
                document.getElementById("controlled-tab-tab-MAP_TYPE").click();
              }
            },
          },
        },
      },
      line: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        gapSize: 5,
      },
      arearange: {
        cursor: "pointer",
      },
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      padding: 0,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      //layout: "horizontal",
      x: 0,
      y: 0,
      //floating: true,
      symbolPadding: 10,
      symbolWidth: 50,
      itemStyle: {
        color: "#000000",
        fontWeight: "bold",
        fontSize: "16px",
      },
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
        "rgba(255,255,255,0.25)",
    },
    series: data.series,
    exporting: {
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 5,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getScatterSingleSignal(data, trendsParam, props, lang) {
  console.log("Scatterplot Func()");
  if (!data || !props || !props.selectedScatter) return null;
  debug("dev")("getScatterSingleSignal", data, trendsParam, props);
  const current = [];
  let config;
  let j = 1;
  let color = 0;
  data.forEach((c) => {
    // if (c.config.code.toUpperCase() === props.selectedScatter.toUpperCase()) {
    //   const color = c.color ? c.color : Highcharts.getOptions().colors[j];
    //   current.push({
    //     name: c.series[0].name,
    //     data: c.series[0].data,
    //     color,
    //     turboThreshold: 0,
    //     marker: {
    //       fillColor: color,
    //       radius: 2
    //     }
    //   });
    //   ({ config } = c);
    //   j += 1;
    // }

    current.push({
      name: c.config.bnm,
      color: colors[color],
      data: c.data,
      boostThreshold: 10000,
      turboThreshold: Number.MAX_VALUE,
      marker: {
        radius: 3,
      },
    });
    j += 1;
    color += 1;
  });

  console.log("check data for scatter", current);
  const options = {
    chart: {
      name: "scatterData",
      type: "scatter",
      marginTop: 70,
      enableMouseTracking: true,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      zoomType: "xy",
      shadow: false,
      resetZoomButton: {
        position: {
          align: "right",
          verticalAlign: "top",
          x: -60,
          y: 10,
        },
        relativeTo: "chart",
      },
      // borderColor: "#f03040",
      // borderWidth: 1,
      height: 600,
      events: {
        load: function () {
          document.getElementById("chartLoader").style.display = "none";
        },
      },
    },
    credits: {
      enabled: false,
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    //trends: "Slip vs Speed",
    title: {
      enabled: true,
      text: `<b>${data[0].documentType}</b><br><span class="sampleinterval">(${data[0].resampleInterval} sampling)</span>`,
    },
    xAxis: {
      className: "scatter-x-axis",
      title: {
        text: `<b>${data[0].config.xsn} [${data[0].config.xsu}]</b>`,
        align: "middle",
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        y: 25,
      },
    },
    yAxis: {
      className: "scatter-y-axis",
      title: {
        text: `<b>${data[0].config.ysn} [${data[0].config.ysu}]</b>`,
        align: "middle",
        // rotation: 0,
        // y: -15,
        // x: -5,
        // align: "high"
        // offset: 0
      },
    },
    legend: {
      enabled: true,
      layout: "horizontal",
      align: "left",
      verticalAlign: "top",
      //x: 150,
      y: 20,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      borderWidth: 0,
      itemStyle: {
        color: "#000000",
        fontWeight: "bold",
        fontSize: "16px",
      },
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        animation: false,
        point: {
          events: {
            click: function () {
              console.log("14se: ", this, data[0].config.ysn);
              sessionStorage.setItem(
                "clickedTimeStamp",
                Moment.utc(this.ts).format("DD.MM.YYYY HH:mm:ss")
              );
              sessionStorage.setItem(
                "clickedTimeStampForRunSlider",
                Moment.utc(this.ts).format("DD.MM.YYYY HH:mm:ss")
              );
              sessionStorage.setItem("clickedSignal", data[0].config.ysn);
              if (
                sessionStorage.getItem("clickedTimeStampForRunSlider") !==
                  null &&
                sessionStorage.getItem("clickedTimeStamp") !== null &&
                sessionStorage.getItem("clickedSignal") !== null
              ) {
                document.getElementById("controlled-tab-tab-MAP_TYPE").click();
              }
            },
          },
        },
      },
      scatter: {
        cursor: "pointer",
        states: {
          // inactive: {
          //   opacity: 1
          // }
        },
        /*tooltip: {
          headerFormat: "<b>{series.name}</b><br>",
          pointFormat: `${data[0].config.xSignalName}: <b>{point.x} ${
            data[0].config.xSignalUnit
          }</b><br />${data[0].config.ySignalName}: <b>{point.y}${
            data[0].config.ySignalUnit
          } </b><br />${"Date/Time"}: <b>{point.timeStamp}</b>`
        }*/
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.series.name}</b><br>
      ${data[0].config.xsn}: <b>${this.point.x} ${
          data[0].config.xsu
        }</b><br />${data[0].config.ysn}: <b>${this.point.y} ${
          data[0].config.ysu
        } </b><br />${"Date/Time"}:<b> ${Moment.utc(this.point.ts).format(
          "DD.MM.YYYY HH:mm:ss"
        )}</b>`;
      },
    },
    series: current,
    /* series: [
      {
        name: "Bogie1",
        //color: 'rgba(223, 83, 83, .5)',
        data: data[1].data
      }
    ],*/

    exporting: {
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 3,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}

export function getHistogramSingleSignal(seriesData) {
  // if (!chart || !props) return null;
  //debug('dev')('Histogram:', chart, props);
  console.log("Histogram Func()");
  let title;
  let unit;
  const options = {
    chart: {
      type: "heatmap",
      marginTop: 57,
      events: {
        load: function () {
          document.getElementById("chartLoader").style.display = "none";
        },
      },
      //inverted: true
      zoomType: "xy",
      panning: true,
      panKey: "shift",
      plotBorderWidth: 1,
      height: 600,
      events: {
        load: function () {
          document.getElementById("chartLoader").style.display = "none";
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `<b> ${seriesData.doc}</b>`,
    },
    xAxis: {
      // min: 0,
      // max: 225,
      title: {
        text: `<b> ${seriesData.cfg.xsn} [${seriesData.cfg.xsu}]</b>`,
      },
      minRange: 20,
      gridZIndex: 0,
      gridLineWidth: 0,
      gridLineColor: "#dbdbdb",
      // tickInterval: 10
    },
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },
    yAxis: {
      // min: -161,
      // max: 161,
      title: {
        text: `<b> ${seriesData.cfg.ysn} [${seriesData.cfg.ysu}]</b>`,
      },
      minPadding: 0,
      maxPadding: 0,
      startOnTick: false,
      endOnTick: false,
      //tickPositions: [0, 6, 12, 18, 24],
      minRange: 20,
      // tickInterval: 10,
      tickWidth: 1,
      gridZIndex: 0,
      gridLineWidth: 0,
      gridLineColor: "#dbdbdb",
      reversed: false,
    },
    colorAxis: {
      stops: [
        [0, "#ffffff"],
        [0.1, "#bfedbb"],
        [0.2, "#98d694"],
        [0.3, "#0ca919"],
        [0.4, "#d6c918"],
        [0.5, "#ffd800"],
        [0.6, "#ff7300"],
        [0.7, "#ff4754"],
        [0.8, "#f03040"],
        [0.9, "#e12e2e"],
        [1, "#cc0815"],
      ],
      reversed: false,
      // min: -6,
      //  max: 5,
      // minColor: "#dadada",
      // maxColor: "#c4463a",
      startOnTick: false,
      endOnTick: true,
      labels: false,
      // labels: {
      //   format: `{value} [s]`
      // }
    },
    series: [
      {
        // boostThreshold: 11653,
        //colsize: 10,
        //borderIndex: 0,
        //borderColor: "#e5e5e5",
        //borderWidth: 0,
        colsize: seriesData.bin,
        rowsize: seriesData.bin,
        turboThreshold: Number.MAX_VALUE,
        nullColor: "#ffffff",
        data: seriesData.dat,
        states: {
          hover: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
          color: "#000000",
        },
      },
    ],
    tooltip: {
      formatter: function () {
        return `${seriesData.cfg.xsn}: <b>${this.point.x} ${
          seriesData.cfg.xsu
        }</b><br />
      ${seriesData.cfg.ysn}: <b> ${this.point.y} ${
          seriesData.cfg.ysu
        }</b><br />${"Occurrence"}: <b>${this.point.nlv} ${"s"}</b>`;
      },
    },
    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "top",
      y: 40,

      symbolWidth: 22,
      symbolHeight: 485,
    },
    plotOptions: {
      heatmap: {
        cursor: "zoom-in",
      },
      series: {
        stickyTracking: false,
        animation: false,
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          _id: "custom_export",
          symbol: `url(${exportChart})`,
          symbolX: 25,
          symbolY: 30,
          align: "right",
          verticalAlign: "top",
          height: 28,
          width: 32,
          symbolSize: 22,
          y: 3,
          x: 0,
          theme: {
            states: {
              hover: {
                fill: "transparent",
              },
              pressed: {
                fill: "transparent",
              },
            },
          },
        },
      },
    },
  };

  return options;
}
