//export const GOOGLE_MAPS_API_KEY = "";
//export const GOOGLE_MAPS_API_KEY = "AIzaSyAEKVqg_a4NBG2w06CITsQV2KU8J9hxQfQ"; // with lucas account
export const GOOGLE_MAPS_API_KEY = "AIzaSyAxWU6jPK4Xte6JnC-R44i1uTHEAPajrMw"; // with vender account
export const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`;

export const googleMapCustomStyle = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#fcf9dd",
      },
      {
        lightness: 60,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#dedfc1",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
