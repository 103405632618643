export const SET_TIMETREND_LOADING = "SET_TIMETREND_LOADING";
export const GET_DETAILVIEW_TIMETREND = "GET_DETAILVIEW_TIMETREND";
export const GET_DETAILVIEW_TIMETREND_SUCCESS =
  "GET_DETAILVIEW_TIMETREND_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDSLIP1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDSLIP2_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDSPEED1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDSPEED2_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORT1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORT2_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTDRIVING1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTDRIVING2_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTBRAKING1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTBRAKING2_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH1_SUCCESS";
export const GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS =
  "GET_DETAILVIEW_TIMETRENDEFFORTBRAKINGMECH2_SUCCESS";
export const GET_DETAILVIEW_TIMETREND_FAILURE =
  "GET_DETAILVIEW_TIMETREND_FAILURE";
export const CLEAR_DETAILVIEW_TIMETREND = "CLEAR_DETAILVIEW_TIMETREND";
/**************new */
export const GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS =
  "GET_DV_TT_DRIVINGTRAILERVEL1_SUCCESS";
export const GET_DV_TT_EFFORTREFERENCE1_SUCCESS =
  "GET_DV_TT_EFFORTREFERENCE1_SUCCESS";
export const GET_DV_TT_EFFORTREFERENCE2_SUCCESS =
  "GET_DV_TT_EFFORTREFERENCE2_SUCCESS";
export const GET_DV_TT_SLIPPOWER1_SUCCESS = "GET_DV_TT_SLIPPOWER1_SUCCESS";
export const GET_DV_TT_SLIPPOWER2_SUCCESS = "GET_DV_TT_SLIPPOWER2_SUCCESS";
export const GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS =
  "GET_DV_TT_POWERBRAKINGMECHANICAL1_SUCCESS";
export const GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS =
  "GET_DV_TT_POWERBRAKINGMECHANICAL2_SUCCESS";
export const GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS =
  "GET_DV_TT_AIRPRESSUREBRAKECYLINDER1_SUCCESS";
export const GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS =
  "GET_DV_TT_AIRPRESSUREBRAKECYLINDER2_SUCCESS";
export const GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS =
  "GET_DV_TT_AMPLITUDETORSIONALOSCILLATION1_SUCCESS";
export const GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS =
  "GET_DV_TT_AMPLITUDETORSIONALOSCILLATION2_SUCCESS";
/**************new */
//-----------------------------------

export const GET_DETAILVIEW_MAP = "GET_DETAILVIEW_MAP";
export const GET_DETAILVIEW_MAPSLIP1_SUCCESS =
  "GET_DETAILVIEW_MAPSLIP1_SUCCESS";
export const GET_DETAILVIEW_MAPSLIP2_SUCCESS =
  "GET_DETAILVIEW_MAPSLIP2_SUCCESS";
export const GET_DETAILVIEW_MAPSPEED1_SUCCESS =
  "GET_DETAILVIEW_MAPSPEED1_SUCCESS";
export const GET_DETAILVIEW_MAPSPEED2_SUCCESS =
  "GET_DETAILVIEW_MAPSPEED2_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORT1_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORT1_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORT2_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORT2_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTDRIVING1_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTDRIVING2_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTBRAKING1_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTBRAKING2_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTBRAKINGMECH1_SUCCESS";
export const GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS =
  "GET_DETAILVIEW_MAPEFFORTBRAKINGMECH2_SUCCESS";

export const GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS =
  "GET_DV_MAPDRIVINGTRAILERVEL1_SUCCESS";
export const GET_DV_MAPEFFORTREFERENCE1_SUCCESS =
  "GET_DV_MAPEFFORTREFERENCE1_SUCCESS";
export const GET_DV_MAPEFFORTREFERENCE2_SUCCESS =
  "GET_DV_MAPEFFORTREFERENCE2_SUCCESS";
export const GET_DV_MAPSLIPPOWER1_SUCCESS = "GET_DV_MAPSLIPPOWER1_SUCCESS";
export const GET_DV_MAPSLIPPOWER2_SUCCESS = "GET_DV_MAPSLIPPOWER2_SUCCESS";
export const GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS =
  "GET_DV_MAPPOWERBRAKINGMECHANICAL1_SUCCESS";
export const GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS =
  "GET_DV_MAPPOWERBRAKINGMECHANICAL2_SUCCESS";
export const GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS =
  "GET_DV_MAPAIRPRESSUREBRAKECYLINDER1_SUCCESS";
export const GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS =
  "GET_DV_MAPAIRPRESSUREBRAKECYLINDER2_SUCCESS";
export const GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS =
  "GET_DV_MAPAMPLITUDETORSIONALOSCILLATION1_SUCCESS";
export const GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS =
  "GET_DV_MAPAMPLITUDETORSIONALOSCILLATION2_SUCCESS";

export const GET_DETAILVIEW_MAP_FAILURE = "GET_DETAILVIEW_MAP_FAILURE";
export const CLEAR_DETAILVIEW_MAP = "CLEAR_DETAILVIEW_MAP";

export const GET_DETAILVIEW_MAPDATESLIDER = "GET_DETAILVIEW_MAPDATESLIDER";
export const GET_DETAILVIEW_MAPDATESLIDER_SUCCESS =
  "GET_DETAILVIEW_MAPDATESLIDER_SUCCESS";
export const GET_DETAILVIEW_MAPDATESLIDER_FAILURE =
  "GET_DETAILVIEW_MAPDATESLIDER_FAILURE";
export const CLEAR_DETAILVIEW_MAPDATESLIDER = "CLEAR_DETAILVIEW_MAPDATESLIDER";

//-------------------------

export const GET_DETAILVIEW_SCATTERPLOT = "GET_DETAILVIEW_SCATTERPLOT";
export const GET_DETAILVIEW_SCATTERPLOT_SUCCESS =
  "GET_DETAILVIEW_SCATTERPLOT_SUCCESS";
export const GET_DETAILVIEW_SCATTERPLOT_FAILURE =
  "GET_DETAILVIEW_SCATTERPLOT_FAILURE";

export const GET_DETAILVIEW_SCATTERPLOT2_SUCCESS =
  "GET_DETAILVIEW_SCATTERPLOT2_SUCCESS";
export const GET_DETAILVIEW_SCATTERPLOT2_FAILURE =
  "GET_DETAILVIEW_SCATTERPLOT2_FAILURE";

export const CLEAR_DETAILVIEW_SCATTERPLOT = "CLEAR_DETAILVIEW_SCATTERPLOT";

//-----------------------------------

export const GET_DETAILVIEW_HISTOGRAM = "GET_DETAILVIEW_HISTOGRAM";
export const GET_DETAILVIEW_HISTOGRAM_SUCCESS =
  "GET_DETAILVIEW_HISTOGRAM_SUCCESS";
export const GET_DETAILVIEW_HISTOGRAM_FAILURE =
  "GET_DETAILVIEW_HISTOGRAM_FAILURE";
export const CLEAR_DETAILVIEW_HISTOGRAM = "CLEAR_DETAILVIEW_HISTOGRAM";

//-----------------------------------

export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_ADVANCED = "SET_FILTER_ADVANCED";
export const SET_MAINTRENDSIGNAL = "SET_MAINTRENDSIGNAL";
export const SET_MAINSCATTERSIGNAL = "SET_MAINSCATTERSIGNAL";
export const ADD_OVERLAY = "ADD_OVERLAY";
export const CLEAR_BASICFILTER = "CLEAR_BASICFILTER";
export const CLEAR_ADVANCEDFILTER = "CLEAR_ADVANCEDFILTER";
export const SET_LOADING = "SET_LOADING";
