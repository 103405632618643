import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_heatMap from "highcharts/modules/heatmap";
import HC_boost_canvas from "highcharts/modules/boost-canvas";
import HC_boost from "highcharts/modules/boost";
import highchartsMore from "highcharts/highcharts-more";

import "./styles.scss";
import {
  getHistogramSingleSignal,
  getScatterSingleSignal,
  getTrendChart,
  getMultiTrendChart,
  xMin,
  xMax,
} from "./function";

highchartsMore(Highcharts);
HC_exporting(Highcharts);
HC_heatMap(Highcharts);
HC_boost_canvas(Highcharts);
HC_boost(Highcharts);

const tempdata = [
  { x: -1, y: 161, value: -3.0 },
  { x: 0, y: 161, value: -3.0 },
  { x: 1, y: 161, value: -3.0 },
  { x: 2, y: 161, value: -3.0 },
  { x: 3, y: 161, value: -3.0 },
  { x: 4, y: 161, value: -3.0 },
  { x: 5, y: 161, value: -3.0 },
  { x: 6, y: 161, value: -3.0 },
  { x: 7, y: 161, value: -3.0 },
  { x: 8, y: 161, value: -3.0 },
  { x: 9, y: 161, value: -3.0 },
  { x: 10, y: 161, value: -3.0 },
  { x: 11, y: 161, value: -3.0 },
  { x: 12, y: 161, value: -3.0 },
  { x: 13, y: 161, value: -3.0 },
  { x: 14, y: 161, value: -3.0 },
  { x: 15, y: 161, value: -3.0 },
  { x: 16, y: 161, value: -3.0 },
  { x: 17, y: 161, value: -3.0 },
  { x: 18, y: 161, value: -3.0 },
];

export default class SingleGraph extends React.Component {
  constructor(props, context) {
    super(props, context);
    const options = this.initGraph(props);
    this.state = { options };
  }

  componentDidMount() {
    setTimeout(() => {
      this.draw(this.props);
    }, 0);
    //this.chart.reflow();
  }

  componentWillReceiveProps(nextProps) {
    //this.chart.destroy();
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.basicFilter != nextProps.basicFilter ||
      this.props.advancedFilter != nextProps.advancedFilter ||
      this.props.selectedMainTrend != nextProps.selectedMainTrend ||
      this.props.selectedOverlaysTrend != nextProps.selectedOverlaysTrend ||
      (this.props.chartType === "SCATTER_TYPE" &&
        this.props.data != nextProps.data) ||
      (this.props.chartType === "TREND_TYPE" &&
        this.props.data.length != nextProps.data.length)
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate() {
    //this.chart.destroy();
    const options = this.initGraph();
    this.state = {
      options,
    };
    this.draw(this.props);
    this.zoomIn();
  }

  componentWillUnmount() {
    if (this.chart !== undefined) {
      this.chart.zoomOut();
      console.log("componentunmountcalled");
    }
    //  this.chart.destroy();
    setTimeout(() => {
      this.chart.destroy();
    }, 0);
  }

  draw(props) {
    /* (function(Highcharts) {
      let each = Highcharts.each;

      Highcharts.wrap(Highcharts.Legend.prototype, "renderItem", function(
        proceed,
        item
      ) {
        proceed.call(this, item);

        let isPoint = !!item.series,
          collection = isPoint ? item.series.points : this.chart.series,
          groups = isPoint ? ["graphic"] : ["group", "markerGroup"],
          element = item.legendGroup.element;

        element.onmouseover = function() {
          each(collection, function(seriesItem) {
            if (seriesItem !== item) {
              each(groups, function(group) {
                seriesItem[group].animate(
                  {
                    opacity: 0.25
                  },
                  {
                    duration: 150
                  }
                );
              });
            }
          });
        };
        element.onmouseout = function() {
          each(collection, function(seriesItem) {
            if (seriesItem !== item) {
              each(groups, function(group) {
                seriesItem[group].animate(
                  {
                    opacity: 1
                  },
                  {
                    duration: 50
                  }
                );
              });
            }
          });
        };
      });
    })(Highcharts);*/
    this.chart = Highcharts.chart(this.container, { ...this.state.options });
    this.chart.reflow();
  }

  initGraph = (props) => {
    let options;
    if (this.props.chartType === "TREND_TYPE") {
      options = getTrendChart(
        this.props.selectedOverlaysTrend,
        this.props.data,
        this.props.selectedMainTrend,
        this.props.basicFilter,
        this.props.advancedFilter,
        this.props.onLoadMoreGraph
      );
    } else if (this.props.chartType === "SCATTER_TYPE") {
      options = getScatterSingleSignal(
        this.props.data,
        "trends",
        { selectedScatter: this.props.selectedMainScatter },
        { timestamp: "lang" }
      );
    } else if (this.props.chartType === "HISTOGRAM_TYPE") {
      options = getHistogramSingleSignal(this.props.data);
    }

    if (Highcharts.getOptions().exporting) {
      const contextButton = Highcharts.getOptions().exporting.buttons
        .contextButton; // eslint-disable-line
      contextButton.menuItems = contextButton.menuItems.filter((item) => {
        return item !== "downloadPDF";
      });
    }

    return options;
  };

  zoomIn = () => {
    console.log("chartzoom", xMin, xMax);
    if (this.chart && this.chart.options.chart.name === "trendData") {
      if (xMin != 0 && xMin != undefined) {
        this.chart.showResetZoom();
        this.chart.xAxis[0].setExtremes(xMin, xMax);
      }

      // const { min, max, dataMin, dataMax } = this.chart.xAxis[0].getExtremes();
      // console.log("min, max, dataMin, dataMax", min, max, dataMin, dataMax);
      // if (min === dataMin && max === dataMax) {
      //   console.log("min, max, dataMin, dataMax", min, max, dataMin, dataMax);
      //   this.chart.showResetZoom();
      // }
    }
  };

  onHistogramXaxisChange = (value) => {
    console.log("dropdown", this.chart.series);

    switch (value) {
      case "Slip":
        this.chart.series[0].setData(tempdata, false);
        this.chart.redraw();
        break;
      default:
        this.chart.series[0].setData(this.props.data, false);
        this.chart.redraw();
    }
  };
  // onHistogramYaxisChange = value => {
  //   this.chart.series[0].setData(tempdatatwo, true);
  // };

  render() {
    console.log("checking chart type-MainFunc(): ", this.props.data);

    //  const { chart, errorCharts } = this.props;
    /*if (errorCharts) {
      return (
        <ErrorComponent
          error={errorCharts}
          status={formatMessage({ id: "application.sorryText" })}
          statusText={formatMessage(
            { id: "application.backendError" },
            {
              code: errorCharts[0] ? errorCharts[0].code : errorCharts.status,
              detail: errorCharts[0]
                ? errorCharts[0].message
                : errorCharts.statusText
            }
          )}
        />
      );
    }*/

    return (
      <div
        id="single_chart_123"
        className="chart"
        ref={(ref) => (this.container = ref)}
        //onClick={}
      />
    );
  }
}

SingleGraph.defaultProps = {};

SingleGraph.propTypes = {
  chart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  overlayData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedOverlays: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedGraph: PropTypes.string,
  onLoadGraph: PropTypes.func.isRequired,
  startTimeDate: PropTypes.object,
  endTimeDate: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  errorCharts: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool.isRequired,
  chartType: PropTypes.number.isRequired,
};
