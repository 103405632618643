import { createSelector } from "reselect";
import Moment from "moment";

const dashboardData = () => state => state.get("dashboardData");

const loadingSelector = () =>
  createSelector(
    dashboardData(),
    state => state.get("loading")
  );

const loadingMapSelector = () =>
  createSelector(
    dashboardData(),
    state => state.get("loadingMap")
  );

const selectedTrainSelector = () =>
  createSelector(
    dashboardData(),
    state => state.get("selectedTrain")
  );

const mapDataSelector = () =>
  createSelector(
    dashboardData(),
    state => state.get("mapData")
  );

const fleetDataSelector = () =>
  createSelector(
    dashboardData(),
    state => state.get("fleetData")
  );

const startDateSelector = () =>
  createSelector(
    dashboardData(),
    state => {
      let startDate = state.get("startDate");
      let formatedStartDate = null;
      if (startDate != null) {
        formatedStartDate = Moment(startDate)
          .format("YYYY-MM-DDTHH:mm:ss")
          .replace(/:/g, "%3A");
      }
      return formatedStartDate;
    }
  );

const endDateSelector = () =>
  createSelector(
    dashboardData(),
    state => {
      let endDate = state.get("endDate");
      let formatedEndDate = null;
      if (endDate != null) {
        formatedEndDate = Moment(endDate)
          .format("YYYY-MM-DDTHH:mm:ss")
          .replace(/:/g, "%3A");
      }
      return formatedEndDate;
    }
  );

// const bretonlySelector = () => createSelector(
//   trainListSelector,
//   tabledata => {
//     console.log("hooo", tabledata.fleetData);

//     const arr = tabledata.fleetData;
//     if (arr) {
//       var filteredValue = arr.filter(function(item) {
//         return item.username === "Bret";
//       });

//       console.log("wowoo", filteredValue);
//     }
//   }
// );

export {
  loadingSelector,
  startDateSelector,
  endDateSelector,
  selectedTrainSelector,
  mapDataSelector,
  fleetDataSelector,
  loadingMapSelector
};
