import { createSelector } from "reselect";

const selectTrainView = () => (state) => state.get("trainViewData");
const selectDetailView = () => (state) => state.get("detailViewData");

const selectTrainViewCommonDetails = () =>
  createSelector(selectTrainView(), (state) => state.get("reducerCommon"));

const selectedTabSelector = () =>
  createSelector(selectTrainViewCommonDetails(), (state) =>
    state.get("selectedTab")
  );

const selectMapDetails = () =>
  createSelector(selectTrainView(), (state) => state.get("reducerMap"));

const selectTimetrendDetails = () =>
  createSelector(selectDetailView(), (state) => state.get("reducerTimetrend"));

const selectDetailViewCommonDetails = () =>
  createSelector(selectDetailView(), (state) => state.get("reducerCommon"));

const loadingMapSelector = () =>
  createSelector(selectMapDetails(), (state) => state.get("loading"));

const mapSlip1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlip1"));

const mapSlip2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSlip2"));

const mapSpeed1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSpeed1"));

const mapSpeed2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapSpeed2"));

const mapEffort1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffort1"));

const mapEffort2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffort2"));

const mapEffortDriving1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortDriving1"));

const mapEffortDriving2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortDriving2"));

const mapEffortBraking1Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortBraking1"));

const mapEffortBraking2Selector = () =>
  createSelector(selectMapDetails(), (state) => state.get("mapEffortBraking2"));

const mapEffortBrakingMech1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortBrakingMech1")
  );

const mapEffortBrakingMech2Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapEffortBrakingMech2")
  );

const mapDrivingTrailerVel1Selector = () =>
  createSelector(selectMapDetails(), (state) =>
    state.get("mapDrivingTrailerVel1")
  );

const mapSelector = () =>
  createSelector(
    selectedMainSignalSelector(),
    filterSelector(),
    mapSlip1Selector(),
    mapSlip2Selector(),
    mapSpeed1Selector(),
    mapSpeed2Selector(),
    mapEffort1Selector(),
    mapEffort2Selector(),
    mapEffortDriving1Selector(),
    mapEffortDriving2Selector(),
    mapEffortBraking1Selector(),
    mapEffortBraking2Selector(),
    mapEffortBrakingMech1Selector(),
    mapEffortBrakingMech2Selector(),
    mapDrivingTrailerVel1Selector(),
    (
      selectedMain,
      filter,
      slip1,
      slip2,
      speed1,
      speed2,
      effort1,
      effort2,
      effortDriving1,
      effortDriving2,
      effortBraking1,
      effortBraking2,
      effortBrakingMech1,
      effortBrakingMech2,
      drivingTrailerVel1
    ) => {
      /*switch (selectedMain) {
        case "Slip":
          if (filter.bogie === 1) {
            if (slip1 !== null) return slip1;
          }
          if (filter.bogie === 2) {
            if (slip2 !== null) return slip2;
          }
          if (filter.bogie === 3) {
            if (slip1 !== null) return slip1;
            if (slip2 !== null) return slip2;
          }
          break;
        case "Speed":
          if (filter.bogie === 1) {
            if (speed1 !== null) return speed1;
          }
          if (filter.bogie === 2) {
            if (speed2 !== null) return speed2;
          }
          if (filter.bogie === 3) {
            if (speed1 !== null) return speed1;
            if (speed2 !== null) return speed2;
          }
          break;

        case "Effort":
          if (filter.bogie === 1) {
            if (effort1 !== null) return effort1;
          }
          if (filter.bogie === 2) {
            if (effort2 !== null) return effort2;
          }
          if (filter.bogie === 3) {
            if (effort1 !== null) return effort1;
            if (effort2 !== null) return effort2;
          }
          break;

        case "EffortDriving":
          if (filter.bogie === 1) {
            if (effortDriving1 !== null) return effortDriving1;
          }
          if (filter.bogie === 2) {
            if (effortDriving2 !== null) return effortDriving2;
          }
          if (filter.bogie === 3) {
            if (effortDriving1 !== null) return effortDriving1;
            if (effortDriving2 !== null) return effortDriving2;
          }
          break;

        case "EffortBraking":
          if (filter.bogie === 1) {
            if (effortBraking1 !== null) return effortBraking1;
          }
          if (filter.bogie === 2) {
            if (effortBraking2 !== null) return effortBraking2;
          }
          if (filter.bogie === 3) {
            if (effortBraking1 !== null) return effortBraking1;
            if (effortBraking2 !== null) return effortBraking2;
          }
          break;

        case "EffortBrakingMechanical":
          if (filter.bogie === 1) {
            if (effortBrakingMech1 !== null) return effortBrakingMech1;
          }
          if (filter.bogie === 2) {
            if (effortBrakingMech2 !== null) return effortBrakingMech2;
          }
          if (filter.bogie === 3) {
            if (effortBrakingMech1 !== null) return effortBrakingMech1;
            if (effortBrakingMech2 !== null) return effortBrakingMech2;
          }
          break;

        default:
          return null;
      }*/
      if (filter.bogie === 1) {
        if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
      }
      if (filter.bogie === 2) {
        if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
      }
      if (filter.bogie === 3) {
        if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
        if (drivingTrailerVel1 !== null) return drivingTrailerVel1;
      }
    }
  );

const filteredMapSelector = () =>
  createSelector(
    mapSelector(),
    selectedMainSignalSelector(),
    filterSelector(),
    (mapData, selectedMain, filter) => {
      let graphValues = [];

      return mapData;
    }
  );

const filteredMapSliderSelector = () =>
  createSelector(mapSelector(), (mapData) => {
    let graphValues = [];
    if (mapData !== undefined && mapData !== null && mapData.rns != undefined)
      mapData.rns.map((obj, index) => {
        graphValues.push(...obj.dat);
      });
    return graphValues;
  });

const loadingTimetrendSelector = () =>
  createSelector(selectTimetrendDetails(), (state) => state.get("loading"));

const selectedMainSignalSelector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("selectedMainTrend")
  );

const timetrendSlip1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSlip1")
  );

const timetrendSlip2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSlip2")
  );

const timetrendSpeed1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSpeed1")
  );

const timetrendSpeed2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataSpeed2")
  );

const timetrendEffort1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffort1")
  );

const timetrendEffort2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffort2")
  );

const timetrendEffortDriving1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortDriving1")
  );

const timetrendEffortDriving2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortDriving2")
  );

const timetrendEffortBraking1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBraking1")
  );

const timetrendEffortBraking2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBraking2")
  );

const timetrendEffortBrakingMech1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBrakingMech1")
  );

const timetrendEffortBrakingMech2Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("timetrendDataEffortBrakingMech2")
  );

const ttDrivingTrailerVel1Selector = () =>
  createSelector(selectTimetrendDetails(), (state) =>
    state.get("ttDataDrivingTrailerVel1")
  );

const timetrendSelector = () =>
  createSelector(
    selectedMainSignalSelector(),
    timetrendSlip1Selector(),
    timetrendSlip2Selector(),
    timetrendSpeed1Selector(),
    timetrendSpeed2Selector(),
    timetrendEffort1Selector(),
    timetrendEffort2Selector(),
    timetrendEffortDriving1Selector(),
    timetrendEffortDriving2Selector(),
    timetrendEffortBraking1Selector(),
    timetrendEffortBraking2Selector(),
    timetrendEffortBrakingMech1Selector(),
    timetrendEffortBrakingMech2Selector(),
    ttDrivingTrailerVel1Selector(),
    (
      selectedMain,
      slip1,
      slip2,
      speed1,
      speed2,
      effort1,
      effort2,
      effortDriving1,
      effortDriving2,
      effortBraking1,
      effortBraking2,
      effortBrakingMech1,
      effortBrakingMech2,
      drivingTrailerVel1
    ) => {
      let chartValues = [];

      /*switch (selectedMain) {
        case "Slip":
          if (slip1 !== null && slip2 !== null) {
            chartValues.push({
              config: { label: "Slip B1", unit: slip1.cfg.unt },
              data: slip1.dat
            });
            chartValues.push({
              config: { label: "Slip B2", unit: slip2.cfg.unt },
              data: slip2.dat
            });
          }
          break;

        case "Speed":
          if (speed1 !== null && speed2 !== null) {
            chartValues.push({
              config: { label: "Speed B1", unit: speed1.cfg.unt },
              data: speed1.dat
            });
            chartValues.push({
              config: { label: "Speed B2", unit: speed2.cfg.unt },
              data: speed2.dat
            });
          }
          break;

        case "Effort":
          if (effort1 !== null && effort2 !== null) {
            chartValues.push({
              config: {
                label: "Effort B1",
                unit: effort1.cfg.unt
              },
              data: effort1.dat
            });
            chartValues.push({
              config: {
                label: "Effort B2",
                unit: effort2.cfg.unt
              },
              data: effort2.dat
            });
          }
          break;

        case "EffortDriving":
          if (effortDriving1 !== null && effortDriving2 !== null) {
            chartValues.push({
              config: {
                label: "EffortDriving B1",
                unit: effortDriving1.cfg.unt
              },
              data: effortDriving1.dat
            });
            chartValues.push({
              config: {
                label: "EffortDriving B2",
                unit: effortDriving2.cfg.unt
              },
              data: effortDriving2.dat
            });
          }
          break;

        case "EffortBraking":
          if (effortBraking1 !== null && effortBraking2 !== null) {
            chartValues.push({
              config: {
                label: "EffortBraking B1",
                unit: effortBraking1.cfg.unt
              },
              data: effortBraking1.dat
            });
            chartValues.push({
              config: {
                label: "EffortBraking B2",
                unit: effortBraking2.cfg.unt
              },
              data: effortBraking2.dat
            });
          }
          break;

        case "EffortBrakingMechanical":
          if (effortBrakingMech1 !== null && effortBrakingMech2 !== null) {
            chartValues.push({
              config: {
                label: "EffortBrakingMechanical B1",
                unit: effortBrakingMech1.cfg.unt
              },
              data: effortBrakingMech1.dat
            });
            chartValues.push({
              config: {
                label: "EffortBrakingMechanical B2",
                unit: effortBrakingMech2.cfg.unt
              },
              data: effortBrakingMech2.dat
            });
          }
          break;

        default:
          break;
      }*/
      if (
        drivingTrailerVel1 !== null &&
        drivingTrailerVel1 !== undefined &&
        drivingTrailerVel1.length !== 0
      ) {
        chartValues.push({
          config: {
            label: "Speed Driving Trailer B1",
            unit: drivingTrailerVel1.cfg.unt,
            samptime: drivingTrailerVel1.rsi,
          },
          data: drivingTrailerVel1.dat,
        });
      }
      if (
        drivingTrailerVel1 !== null &&
        drivingTrailerVel1 !== undefined &&
        drivingTrailerVel1.length !== 0
      ) {
        chartValues.push({
          config: {
            label: "Speed Driving Trailer B2",
            unit: drivingTrailerVel1.cfg.unt,
            samptime: drivingTrailerVel1.rsi,
          },
          data: drivingTrailerVel1.dat,
        });
      }
      console.log("Condition2 Selected- ", chartValues);

      return chartValues;
    }
  );

const filteredTimetrendSelector = () =>
  createSelector(
    timetrendSelector(),
    selectedMainSignalSelector(),
    filterSelector(),
    (graphData, selectedMain, filter) => {
      let chartValues = [];

      //Bogie filter
      if (filter.bogie === 1 || filter.bogie === 3) {
        graphData &&
          chartValues.push(
            ...graphData.filter(
              (item) => item.config.label === "Speed Driving Trailer" + " B1"
            )
          );
      }

      if (filter.bogie === 2 || filter.bogie === 3) {
        graphData &&
          chartValues.push(
            ...graphData.filter(
              (item) => item.config.label === "Speed Driving Trailer" + " B2"
            )
          );
      }

      chartValues = JSON.parse(JSON.stringify(chartValues));

      //for (let j = 0; j < chartValues.length; j += 1) {
      //for (let i = 0; i < chartValues[j].data.length; i += 1) {
      chartValues.map((value, j) => {
        chartValues[j].data.map((value, i) => {
          //Operation filter
          let flag = 0;
          filter.operation.map((value, index) => {
            if (chartValues[j].data[i].ftr.opm === value && flag === 0) {
              flag = 1;
            }
          });
          if (flag === 0) {
            chartValues[j].data[i].min = null;
            chartValues[j].data[i].max = null;
            chartValues[j].data[i].avg = null;
          }
        });
      });

      return chartValues;
    }
  );

const scatterplotSelector = () =>
  createSelector(selectDetailViewCommonDetails(), (state) =>
    state.get("scatterplotData")
  );

const loadingScatterSelector = () =>
  createSelector(selectDetailViewCommonDetails(), (state) =>
    state.get("loading")
  );

const loadingScatterB2Selector = () =>
  createSelector(selectDetailViewCommonDetails(), (state) =>
    state.get("loadingScatterB2")
  );
//--------------------------
const scatterplot2Selector = () =>
  createSelector(selectDetailViewCommonDetails(), (state) =>
    state.get("scatterplotData2")
  );

const scatterplotBothBogieSelector = () =>
  createSelector(
    scatterplotSelector(),
    scatterplot2Selector(),
    (scatterplotData1, scatterplotData2) => {
      let scatterData = [];
      if (scatterplotData1 !== null && scatterplotData2 !== null) {
        console.log("scatter data in selector", scatterplotData1[0]);
        scatterData.push(scatterplotData1[0]);
        scatterData.push(scatterplotData2[0]);
      }
      return scatterData;
    }
  );

const filteredScatterSelector = () =>
  createSelector(
    scatterplotBothBogieSelector(),
    filterSelector(),
    (graphData, filter) => {
      let chartValues = [],
        bogie1,
        bogie2;
      if (
        graphData.length !== 0 &&
        graphData[0] !== undefined &&
        graphData[1] !== undefined
      ) {
        let confg = graphData[0].cfg;
        let docType = graphData[0].doc;
        let resampleInterval = graphData[0].rsi;

        bogie1 = graphData[0].dat.filter((item) => {
          return filter.operation.includes(item.ftr.opm);
        });
        bogie1 = {
          documentType: docType,
          resampleInterval: resampleInterval,
          config: confg,
          data: bogie1,
        };

        let confg2 = graphData[1].cfg;
        let docType2 = graphData[1].doc;
        let resampleInterval2 = graphData[1].rsi;

        bogie2 = graphData[1].dat.filter((item) => {
          return filter.operation.includes(item.ftr.opm);
        });
        bogie2 = {
          documentType: docType2,
          resampleInterval: resampleInterval2,
          config: confg2,
          data: bogie2,
        };

        if (filter.bogie === 1) {
          chartValues.push(bogie1);
        } else if (filter.bogie === 2) {
          chartValues.push(bogie2);
        } else if (filter.bogie === 3) {
          chartValues.push(bogie1);
          chartValues.push(bogie2);
        }
      }

      return chartValues;
    }
  );

const loadingSelector = () =>
  createSelector(selectMapDetails(), (state) => state.get("loading"));

const filterSelector = () =>
  createSelector(selectDetailViewCommonDetails(), (state) =>
    state.get("filter")
  );

const loadingHistogramSelector = () =>
  createSelector(selectTrainViewCommonDetails(), (state) =>
    state.get("loadingHistogram")
  );

const histogramSelector = () =>
  createSelector(selectTrainViewCommonDetails(), (state) =>
    state.get("histogramData")
  );

export {
  loadingMapSelector,
  loadingTimetrendSelector,
  mapSlip1Selector,
  mapSlip2Selector,
  mapSpeed1Selector,
  mapSpeed2Selector,
  mapEffort1Selector,
  mapEffort2Selector,
  mapEffortDriving1Selector,
  mapEffortDriving2Selector,
  mapEffortBraking1Selector,
  mapEffortBraking2Selector,
  mapEffortBrakingMech1Selector,
  mapEffortBrakingMech2Selector,
  mapDrivingTrailerVel1Selector,
  filteredMapSelector,
  filteredMapSliderSelector,
  timetrendSlip1Selector,
  timetrendSlip2Selector,
  timetrendSpeed1Selector,
  timetrendSpeed2Selector,
  timetrendEffort1Selector,
  timetrendEffort2Selector,
  timetrendEffortDriving1Selector,
  timetrendEffortDriving2Selector,
  timetrendEffortBraking1Selector,
  timetrendEffortBraking2Selector,
  timetrendEffortBrakingMech1Selector,
  timetrendEffortBrakingMech2Selector,
  ttDrivingTrailerVel1Selector,
  loadingSelector,
  filteredTimetrendSelector,
  selectedTabSelector,
  scatterplotSelector,
  loadingScatterSelector,
  filteredScatterSelector,
  loadingScatterB2Selector,
  loadingHistogramSelector,
  histogramSelector,
};
