import React from "react";
import "./stylesheets/main.scss";
import Routes from "./router.js";
import Header from "./components/Header/Header";
import Container from "react-bootstrap/Container";
import VersionInfo from "./components/Footer/VersionInfo";

/* eslint-disable import/no-unresolved  */
/* eslint-disable import/no-webpack-loader-syntax */
import "!file-loader?name=[name].[ext]!./web.config";
/* import/no-webpack-loader-syntax */
/* eslint-enable import/no-unresolved  */
// app component
export default class App extends React.Component {
  // render
  render() {
    console.log("wwwww", window.location.origin.toString());
    if (
      window.location.origin.toString() ===
      "https://wwomonitoring.azurewebsites.net"
    ) {
      return (
        <div id="center">
          <div className="abblogoinlogin">
            <img src={require("./assets/images/abb-logo.svg")} alt="ABB Logo" />
          </div>
          <br />
          <h2>Our WWO site has moved!</h2>
          <h3>
            The new web address for this page is{" "}
            <div>https://re460monitoring.traction.abb.com</div>
          </h3>
        </div>
      );
    } else if (
      window.location.origin.toString() ===
      "https://wwomonitoring-staging.azurewebsites.net"
    ) {
      return (
        <div id="center">
          <div className="abblogoinlogin">
            <img src={require("./assets/images/abb-logo.svg")} alt="ABB Logo" />
          </div>
          <br />
          <h2>Our WWO site has moved!</h2>
          <h3>
            The new web address for this page is{" "}
            <div>https://stage.re460monitoring.traction.abb.com</div>
          </h3>
        </div>
      );
    }
    return (
      <div>
        {/* <header>
          {" "}
          <Header />
        </header> */}

        <main role="main" className="flex-shrink-0">
          <Container fluid style={{ paddingTop: 60 }}>
            <Routes />
          </Container>
        </main>
        <footer className="footer mt-auto py-3">
          <VersionInfo />
        </footer>
      </div>
    );
  }
}
