//Library
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { injectIntl, FormattedMessage } from "react-intl";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
//Components
import "../styles.scss";
import messages from "../messages";
import TrainViewMap from "../../../components/TrainViewMap";
import Graph from "../../../components/Graph";
import TitleBar from "../../../components/Title/TitleBar";
import DistanceSelector from "../../../components/DistanceSelector";
import {
  IconTimeTrend,
  IconScatterPlot,
  IconHistogram,
  IconGps,
} from "../../../components/Icons";
import Loading from "../../../components/Loading";
import FilterDetailsTrainview from "../../../components/Title/FilterDetailsTrainview";

class TrainView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "Slip",
      markerPosition: {
        lat: 0.0,
        lng: 0.0,
      },
      crosshairPosition: null,
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    /*this.props.mapData &&
      this.props.mapData.data.length !== 0 &&
      this.setState({
        markerPosition: this.props.mapData.data[0].positions[1]
      });*/
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.startDate !== nextProps.startDate ||
      this.props.endDate !== nextProps.endDate ||
      this.props.selectedTrain !== nextProps.selectedTrain ||
      this.props.selectedMainTrend !== nextProps.selectedMainTrend
    ) {
      this.setState({
        markerPosition: {
          lat: 0.0,
          lng: 0.0,
        },
      });
    }
  }
  // shouldComponentUpdate(nextProps) {
  //   if (this.state.markerPosition.lat === 0) return true;
  //   else return false;
  // }

  // componentDidUpdate() {
  //   this.props.mapData &&
  //     this.setState({
  //       markerPosition: this.props.mapData[0].positions[1]
  //     });
  // }

  /*onUpdate = ([position]) => {
    let filterValue = this.props.mapData.filter(
      item => item.timeStamp == position
    );
    this.setState({
      markerPosition: filterValue[0].positions[0],
      crosshairPosition: this.props.mapData.indexOf(filterValue[0])
    });
    this.child.current.showTooltip(this.state.crosshairPosition);
  };*/

  onUpdate = ([position]) => {
    let filterValue = this.props.mapSliderData[position];
    if (filterValue !== undefined) {
      if (
        filterValue.pos[0].lat >= 1 &&
        filterValue.pos[1].lat >= 1 &&
        filterValue.gvd === 1
      ) {
        this.setState({
          markerPosition: filterValue.pos[1],
          //crosshairPosition: this.props.mapData.indexOf(position)
        });
      }
      if (position === 0 && filterValue.gvd === 1) {
        this.setState({
          markerPosition: filterValue.pos[1],
        });
      }

      this.child.current.showTooltip(position);
      //console.log("markerPosition",this.state.markerPosition);
    }
  };

  saveAs = (uri, filename) => {
    var link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };

  takeScreenshot = () => {
    let googleMapsView = document.querySelector("#multicolormap");

    html2canvas(googleMapsView, { useCORS: true }).then((canvas) => {
      let imgData = canvas.toDataURL("image/png");
      imgData = imgData.replace("data:image/png;base64,", "");
      var finalImageSrc = "data:image/png;base64," + imgData;
      this.saveAs(canvas.toDataURL(), "file-name.png");
      const pdf = new jsPDF();
      pdf.addImage(finalImageSrc, "PNG", 0, 0);
      pdf.save("Trainviewmap.pdf");
    });
  };

  render() {
    const {
      mapData,
      mapSliderData,
      timeTrendData,
      scatterplotData,
      basicFilter,
      selectedTrain,
      onClickGraph,
      histogramdata,
    } = this.props;

    return (
      <div>
        <Row>
          <TitleBar
            trainno={this.props.selectedTrain}
            onClickRedirectMap={this.props.onClickRedirectMap}
            id="trainview"
          />
        </Row>
        <Row>
          <Col md={6} className="trainviewmaptitle">
            <IconGps />
            <span className=""> Map</span>
          </Col>
          <Col md={3} className="ml-auto noRightpadding mapRedirect">
            {/* <button
              title="Download map"
              onClick={
                () => {
                  // remove this function to enable download
                } //this.takeScreenshot
              }
              className="iconbtn"
            >
              <img src={require("../../../assets/images/exportChart.svg")} />
            </button> */}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <FilterDetailsTrainview basicFilter={this.props.basicFilter}/>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="tabtrainview">
            {this.props.mapData === null ||
            this.props.mapData === undefined ||
            this.props.loadingMap === true ? (
              <Col md={12}>
                <Loading />
              </Col>
            ) : (
              <TrainViewMap
                mapData={mapData.rns}
                markerPosition={this.state.markerPosition}
                basicFilter={basicFilter}
              />
            )}
            {/*<Tabs
              id="controlled-tab"
              activeKey={this.props.selectedMainTrend} //{this.state.key}
              onSelect={key => this.props.onClickTab(key)} //{key => this.setState({ key })}
            >
              {// Slip tab 
              }
              <Tab
                eventKey="Slip"
                title={<span className="tabtitle">Slip</span>}
              >
                {this.props.mapData === null ||
                this.props.mapData === undefined ||
                this.props.loadingMap === true ||
                this.props.selectedTab === "Slip" ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
              {// Effort tab 
              }
              <Tab
                eventKey="Effort"
                title={<span className="tabtitle">Effort</span>}
              >
                {this.props.mapData === null ||
                this.props.mapData === undefined ||
                this.props.loadingMap === true ||
                this.props.selectedTab === "Effort" ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
              {// Effort Driving tab 
              }
              <Tab
                eventKey="EffortDriving"
                title={<span className="tabtitle">Effort Driving</span>}
              >
                {this.props.mapData === null ||
                this.props.mapData === undefined ||
                this.props.loadingMap === true ||
                this.props.selectedTab === "Speed" ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
              {// Effort Braking (electrical) tab 
              }
              <Tab
                eventKey="EffortBraking"
                title={
                  <span className="tabtitle">Effort Braking (electrical)</span>
                }
              >
                {this.props.mapData === null ||
                this.props.mapData === undefined ||
                this.props.loadingMap === true ||
                this.props.selectedTab === "EffortBraking" ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
              {// Effort Braking (mechanical) tab 
              }
              <Tab
                eventKey="EffortBrakingMechanical"
                title={
                  <span className="tabtitle">Effort Braking (mechanical)</span>
                }
              >
                {this.props.mapData === null ||
                this.props.mapData === undefined ||
                this.props.loadingMap === true ||
                this.props.selectedTab === "EffortBrakingMechanical" ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
              {// Speed tab 
              }
              <Tab
                eventKey="Speed"
                title={<span className="tabtitle">Speed</span>}
              >
                {this.props.mapData === null ||
                this.props.loadingMap === true ||
                this.props.mapData === undefined ? (
                  <Col md={12}>
                    <Loading />
                  </Col>
                ) : (
                  <TrainViewMap
                    mapData={mapData.rns}
                    markerPosition={this.state.markerPosition}
                    basicFilter={basicFilter}
                  />
                )}
              </Tab>
                </Tabs>*/}
          </Col>
        </Row>
        <Row>
          {this.props.mapData === null ||
          this.props.mapData === undefined ||
          this.props.loadingMap === true ||
          this.props.loadingTimetrend === true ? (
            <Col md={12}></Col>
          ) : this.props.mapSliderData.length !== 0 ? (
            <DistanceSelector
              mapData={mapSliderData}
              onUpdate={this.onUpdate}
              selectedTrain={selectedTrain}
              basicFilter={basicFilter}
              unit={this.props.mapData.cfg.unt}
            />
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col md={12}>
            <Row className="trainviewcharts">
              <Col md={4} className="trainviewchart">
                <span className="tabIcon">
                  <IconTimeTrend fill="" />
                  <span className="tabtitle"> Time trend</span>
                </span>
                {this.props.timeTrendData === null ||
                this.props.timeTrendData === undefined ||
                this.props.timeTrendData === [] ||
                this.props.loadingTimetrend === true ? (
                  <Loading />
                ) : this.props.timeTrendData.length !== 0 ? (
                  <Graph
                    className="trainviewchartgraph"
                    data={timeTrendData}
                    selectedMainTrend={"DrivingTrailerVel"}
                    selectedOverlaysTrend={[]}
                    chartType={"TREND_TYPE"}
                    onClick={onClickGraph}
                    ref={this.child}
                    basicFilter={basicFilter}
                    crosshairPosition={this.state.crosshairPosition}
                    loadingTimetrend={this.props.loadingTimetrend}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">
                      No data found for this time interval !
                    </div>
                  </Col>
                )}
              </Col>

              <Col md={4} className="trainviewchart">
                <span className="tabIcon">
                  <IconScatterPlot />
                  <span className="tabtitle">Scatter plot</span>
                </span>
                {this.props.scatterplotData === null ||
                this.props.scatterplotData === undefined ||
                this.props.loadingScatter === true ||
                this.props.loadingScatterB2 === true ? (
                  <Loading />
                ) : this.props.scatterplotData.length !== 0 ? (
                  <Graph
                    className="trainviewchartgraph"
                    data={scatterplotData}
                    setMainScatterSignal={"Speed/Effort"}
                    // selectedOverlaysTrend={[]}
                    chartType={"SCATTER_TYPE"}
                    onClick={onClickGraph}
                    // basicFilter={basicFilter}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">
                      No data found for this time interval !
                    </div>
                  </Col>
                )}
              </Col>
              <Col md={4} className="trainviewchart">
                <span className="tabIcon">
                  <IconHistogram />
                  <span className="tabtitle">Histogram</span>
                </span>
                {this.props.histogramdata === null ||
                this.props.histogramdata === undefined ||
                this.props.histogramdata === [] ? (
                  <Loading />
                ) : this.props.histogramdata.dat.length !== 0 ? (
                  <Graph
                    className="trainviewchartgraph"
                    data={histogramdata}
                    chartType={"HISTOGRAM_TYPE"}
                    onClick={onClickGraph}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">
                      No data found for this time interval !
                    </div>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

TrainView.defaultProps = {
  selectedTrain: undefined,
  startTime: undefined,
  endTime: undefined,
  startTimeDate: undefined,
  endTimeDate: undefined,
  graphsData: undefined,
};

TrainView.propTypes = {
  selectedTrain: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  startTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  graphsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withRouter(TrainView);
