import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";

import "./styles.scss";
import {
  getTrendChart,
  getScatterSingleSignal,
  getHistogramSingleSignal
} from "./function";

highchartsMore(Highcharts);
HC_exporting(Highcharts);
let starting = 0;
export default class Graph extends React.Component {
  constructor(props, context) {
    super(props, context);

    const options = this.initGraph(props);
    this.state = { options };
  }

  componentDidMount() {
    this.draw(this.props);
    //this.chart.reflow();
  }

  componentWillReceiveProps(nextProps, nextState) {
    //this.chart.destroy();
    //this.draw(nextProps);
  }

  shouldComponentUpdate(nextProps) {
    //console.log("NextProps: ", nextProps);
    if (this.props.basicFilter != nextProps.basicFilter) {
      return true;
    } else if (this.props.data != nextProps.data) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate() {
    this.chart.destroy();
    const options = this.initGraph();
    this.state = {
      options
    };
    this.draw(this.props);
  }

  componentWillUnmount() {
    this.chart.destroy();
  }

  draw(props) {
    this.chart = Highcharts.chart(this.container, { ...this.state.options });
    this.chart.reflow();
  }

  initGraph = props => {
    let options;
    if (this.props.chartType === "TREND_TYPE") {
      options = getTrendChart(
        this.props.selectedOverlaysTrend,
        this.props.data,
        this.props.selectedMainTrend,
        this.props.basicFilter
      );
    } else if (this.props.chartType === "SCATTER_TYPE") {
      /*options = getScatterSingleSignal(
        this.props.data,
        "trends",
        { selectedScatter: this.props.selectedMainScatter },
        { timestamp: "lang" }
      );*/

      options = getScatterSingleSignal(this.props.data);
    } else if (this.props.chartType === "HISTOGRAM_TYPE") {
      options = getHistogramSingleSignal(this.props.data);
    }

    if (Highcharts.getOptions().exporting) {
      const contextButton = Highcharts.getOptions().exporting.buttons
        .contextButton; // eslint-disable-line
      contextButton.menuItems = contextButton.menuItems.filter(item => {
        return item !== "downloadPDF";
      });
    }

    return options;
  };

  showTooltip = (num = 0, e) => {
    let xAxis = this.chart.xAxis[0];
    let points = this.chart.series[0].points;
    this.chart.tooltip.hide();
    this.chart.tooltip.refresh(points[num % points.length]);
    xAxis.drawCrosshair(e, points[num % points.length]);
    // Highcharts.wrap((Highcharts.charts[0].tooltip.isHidden = true));
    Highcharts.wrap(Highcharts.Tooltip.prototype, "hide", function(proceed) {});

    console.log("calling child method", Highcharts.charts, Highcharts);
  };

  render() {
    /*if (this.chart) {
      if (this.props.loadingTimetrend) {
        this.chart.hideLoading();
      } else {
        this.chart.showLoading("Loading...");
      }
    }*/

    /*if (errorCharts) {
      return (
        <ErrorComponent
          error={errorCharts}
          status={formatMessage({ id: "application.sorryText" })}
          statusText={formatMessage(
            { id: "application.backendError" },
            {
              code: errorCharts[0] ? errorCharts[0].code : errorCharts.status,
              detail: errorCharts[0]
                ? errorCharts[0].message
                : errorCharts.statusText
            }
          )}
        />
      );
    }*/
    return (
      <div
        id="graph_chart"
        className="graphchart"
        ref={ref => (this.container = ref)}
        onClick={() => this.props.onClick(this.props.chartType)}
      />
    );
  }
}

Graph.defaultProps = {};

Graph.propTypes = {
  chart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  overlayData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedOverlays: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  selectedGraph: PropTypes.string,
  onLoadGraph: PropTypes.func.isRequired,
  startTimeDate: PropTypes.object,
  endTimeDate: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,

  errorCharts: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool.isRequired,
  chartType: PropTypes.number.isRequired
};
