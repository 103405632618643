import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import api from "../../utils/api";
import {
  getTrainViewMapSlip1Success,
  getTrainViewMapSlip2Success,
  getTrainViewMapSpeed1Success,
  getTrainViewMapSpeed2Success,
  getTrainViewMapEffort1Success,
  getTrainViewMapEffort2Success,
  getTrainViewMapEffortDriving1Success,
  getTrainViewMapEffortDriving2Success,
  getTrainViewMapEffortBraking1Success,
  getTrainViewMapEffortBraking2Success,
  getTrainViewMapEffortBrakingMech1Success,
  getTrainViewMapEffortBrakingMech2Success,
  getTrainViewMapDrivingTrailerVel1Success,
  getTrainViewMapFailure,
  getTrainViewHistogramSuccess,
  getTrainViewHistogramFailure,
} from "./actions";
import { GET_TRAINVIEW_MAP, GET_TRAINVIEW_HISTOGRAM } from "./constants";
import { handleError } from "../../utils/api-utils";

// export function* getTrainViewMapDataSaga() {
//   yield takeLatest(GET_TRAINVIEW_MAPDATA, gettingTrainViewMapDataSaga);
// }
// export function* gettingTrainViewMapDataSaga() {
//   try {
//     const response = yield call(api.fetchTrainViewMapData);
//     const MapData = response.payload.data;
//     yield put(getTrainViewMapDataSuccess(MapData));
//   } catch (error) {
//     yield put(handleError(error, getTrainViewMapDataFailure));
//   }
// }

export function* getTrainViewMapSaga() {
  yield takeEvery(GET_TRAINVIEW_MAP, gettingTrainViewMap);
}
export function* gettingTrainViewMap(action) {
  console.log("Saga for map: ", action);
  try {
    const { id, startDate, endDate, docType, bogie } = action.payload;

    const response = yield call(
      api.fetchTrainViewMapData,
      id,
      startDate,
      endDate,
      docType,
      bogie
    );
    let GraphData = response.dc[0];
    if (GraphData === undefined) GraphData = { data: [] };

    if (docType === "Slip" && bogie === "1") {
      yield put(getTrainViewMapSlip1Success(GraphData));
    }
    if (docType === "Slip" && bogie === "2") {
      yield put(getTrainViewMapSlip2Success(GraphData));
    }
    if (docType === "Speed" && bogie === "1") {
      yield put(getTrainViewMapSpeed1Success(GraphData));
    }
    if (docType === "Speed" && bogie === "2") {
      yield put(getTrainViewMapSpeed2Success(GraphData));
    }
    if (docType === "Effort" && bogie === "1") {
      yield put(getTrainViewMapEffort1Success(GraphData));
    }
    if (docType === "Effort" && bogie === "2") {
      yield put(getTrainViewMapEffort2Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "1") {
      yield put(getTrainViewMapEffortDriving1Success(GraphData));
    }
    if (docType === "EffortDriving" && bogie === "2") {
      yield put(getTrainViewMapEffortDriving2Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "1") {
      yield put(getTrainViewMapEffortBraking1Success(GraphData));
    }
    if (docType === "EffortBraking" && bogie === "2") {
      yield put(getTrainViewMapEffortBraking2Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "1") {
      yield put(getTrainViewMapEffortBrakingMech1Success(GraphData));
    }
    if (docType === "EffortBrakingMechanical" && bogie === "2") {
      yield put(getTrainViewMapEffortBrakingMech2Success(GraphData));
    }
    if (docType === "DrivingTrailerVel" && bogie === "1") {
      yield put(getTrainViewMapDrivingTrailerVel1Success(GraphData));
    }
  } catch (error) {
    yield put(handleError(error, getTrainViewMapFailure));
  }
}

/*export function* getTrainViewGraphDataSaga() {
  yield takeLatest(GET_TRAINVIEW_GRAPHDATA, gettingTrainViewGraphDataSaga);
}
export function* gettingTrainViewGraphDataSaga(action) {
  try {
    const { id, startDate, endDate } = action.payload;
    const response = yield call(
      api.fetchTrainViewGraphData,
      id,
      startDate,
      endDate
    );
    const GraphData = response.data_Constants[0];
    console.log("TrainViewGraphData: ", response);
    yield put(getTrainViewGraphDataSuccess(GraphData));
  } catch (error) {
    yield put(handleError(error, getTrainViewGraphDataFailure));
  }
}*/

//Histogram
export function* getTrainViewHistogramSaga() {
  yield takeLatest(GET_TRAINVIEW_HISTOGRAM, gettingTrainViewHistogramSaga);
}
export function* gettingTrainViewHistogramSaga(action) {
  try {
    const {
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      zAxisSignal,
      filter,
    } = action.payload;
    console.log("SagaInside");
    const response = yield call(
      api.fetchTrainViewHistogramData,
      id,
      startDate,
      endDate,
      xAxisSignal,
      yAxisSignal,
      zAxisSignal,
      filter
    );
    const GraphData = response; /*response;*/
    console.log("SagaInside", GraphData);
    yield put(getTrainViewHistogramSuccess(GraphData));
  } catch (error) {
    yield put(handleError(error, getTrainViewHistogramFailure));
  }
}
