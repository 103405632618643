export const overlaySignalOptions = [
  "Speed at Wheel",
"Speed Driving Trailer",
"Slip",
"Effort",
"Effort Reference",
"Effort Mech. Brake",
"Power Slip",
"Power Mechanical Brake",
"Pressure Brake Cyl.",
"Torsional Osc. Ampl.",
];

export const mainSignalTrendOptions = [
  "Speed at Wheel",
"Speed Driving Trailer",
"Slip",
"Effort",
"Effort Reference",
"Effort Mech. Brake",
"Power Slip",
"Power Mechanical Brake",
"Pressure Brake Cyl.",
"Torsional Osc. Ampl.",
];

export const mainSignalScatterOptions = [
  "Speed Driving Trailer / Slip",
"Speed Driving Trailer / Effort",
"Speed Driving Trailer / Effort Mech. Brake",
"Speed Driving Trailer / Torsional Osc. Ampl.",
"Slip / Effort",
"Slip / Torsional Osc. Ampl.",
"Slip / Effort Mech. Brake",
"Effort / Torsional Osc. Ampl.",
];
/*export const overlaySignalOptions = [
  "Slip",
  "Speed",
  "Effort",
  //"EffortDriving",
  //"EffortBraking",
  "EffortBrakingMechanical",
  "DrivingTrailerVel",
  "EffortReference",
  "SlipPower",
  "PowerBrakingMechanical",
  "AirPressureBrakeCylinder",
  "AmplitudeTorsionalOscillation",
];*/

/*export const mainSignalTrendOptions = [
  "Slip",
  "Speed",
  "Effort",
  //"EffortDriving",
  //"EffortBraking",
  "EffortBrakingMechanical",
  "DrivingTrailerVel",
  "EffortReference",
  "SlipPower",
  "PowerBrakingMechanical",
  "AirPressureBrakeCylinder",
  "AmplitudeTorsionalOscillation",
];*/

/*export const mainSignalScatterOptions = [
  "Speed/Slip",
  "Speed/Effort",
  "Speed/EffortBrakingMechanical",
  "Slip/Effort",
  "Speed/AmplitudeTorsionalOscillation",
  "Slip/AmplitudeTorsionalOscillation",
  "Effort/AmplitudeTorsionalOscillation",
  "Slip/EffortBrakingMechanical",
];*/

export const xAxisSignalHistogramOptions = [
  // "Slip",
  "Speed",
  "Effort",
  //"EffortBraking",
  //"EffortBrakingMechanical"
];
export const yAxisSignalHistogramOptions = [
  // "Slip",
  "Speed",
  "Effort",
  // "EffortBraking",
  // "EffortBrakingMechanical"
];
export const zAxisSignalHistogramOptions = [
  "Occurrence",
  // "SlipEnergy"
  //"Slip Power(Energy/Time)"
];
