/*global google*/
import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
  //InfoWindow
} from "react-google-maps";
import PropTypes from "prop-types";
import {
  googleMapURL,
  googleMapCustomStyle,
} from "../Googlemapkey/dashboardMapConfig";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
let prevPosition = undefined,
  prevPositionIsvalidFalse = undefined,
  gpsValid = 1;
let startPosition = null,
  startFlag = true,
  rows = [];

const TrainViewGoogleMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 46.94809, lng: 7.44744 }}
      /*defaultCenter={
        props.positions[0].data[Math.round(props.positions[0].data.length / 2)]
          .positions[0]
      }*/
      /*center={
        props.markerPosition.lat === 0 && startPosition !== null
          ? startPosition
          : props.markerPosition
      }*/
      mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
        draggableCursor: "move",
      }}
    >
      {/* <MarkerWithLabel
        position={props.positions[0].positionsin[0]}
        defaultAnimation={google.maps.Animation.DROP}
        options={{ icon: require("../../assets/images/Blue_dot.svg") }}
        labelStyle={{
          textAlign: "center",
          fontSize: "14px",
          color: "#fff",
          fontWeight: 800
        }}
        labelClass="map-label"
        labelAnchor={{ x: 4, y: 35 }}
      >
        <span>{"Place name"}</span>
      </MarkerWithLabel> */}
      {
        //(gpsValid = 1),
        ((startFlag = true), (startPosition = null))
        //(prevPosition = undefined),
        //(prevPositionIsvalidFalse = undefined)
      }
      {props.positions.map((obj1, index1) => {
        gpsValid = 1;
        //startFlag = true;
        //startPosition = null;
        prevPosition = undefined;
        prevPositionIsvalidFalse = undefined;
        rows = [];
        obj1.dat.map((obj, index) => {
          if (index !== 0 && obj.gvd === 1) {
            //&& props.positions.length - 1 !== index
            /*if (gpsValid === 0) {
              gpsValid = 2;
              return;
            }*/
            if (gpsValid === 0 || gpsValid === 2) {
              gpsValid = 1;
              if (
                prevPositionIsvalidFalse !== undefined &&
                prevPositionIsvalidFalse[0].lat !== 0
              ) {
                //let currentColor = "#C8C8C8"; //LightGray
                prevPositionIsvalidFalse[1].lat = obj.pos[1].lat;
                prevPositionIsvalidFalse[1].lng = obj.pos[1].lng;
                rows.push(
                  <Polyline
                    path={prevPositionIsvalidFalse}
                    options={{
                      strokeColor: "#2e92fa", //LightGray
                      strokeWeight: 4,
                    }}
                  />
                );
                return;
              } else {
                return;
              }
            }

            let currentColor =
              props.basicFilter.bogie === 3 ? obj.cla : obj.clr;

            if (currentColor === "green") currentColor = "#0CA919";
            else if (currentColor === "yellow") currentColor = "#FFD800";
            else if (currentColor === "red") currentColor = "#F03040";
            else if (currentColor === "gray") currentColor = "#545454";

            if (currentColor && obj.pos) {
              if (
                props.basicFilter.operation &&
                !props.basicFilter.operation.find((item) => item == obj.ftr.opm)
              )
                currentColor = "#C8C8C8"; //LightGray
              /*
            //without any logic for testing
            return (
              <Polyline
                path={obj.positions}
                options={{
                  strokeColor: currentColor,
                  strokeWeight: 3
                }}
              />
            );*/
              if (obj.pos[0].lat !== 0 && obj.pos[1].lat !== 0) {
                prevPositionIsvalidFalse = obj.pos;
                prevPosition = undefined;
                if (startFlag) {
                  startPosition = obj.pos[0];
                  startFlag = false;
                }
                rows.push(
                  <Polyline
                    path={obj.pos}
                    options={{
                      strokeColor: "#2e92fa",
                      strokeWeight: 4,
                    }}
                  />
                );
                return;
              } else if (obj.pos[0].lat !== 0 && obj.pos[1].lat === 0) {
                prevPosition = obj.pos;
                prevPositionIsvalidFalse = undefined;
                console.log("10");
              } else if (obj.pos[0].lat === 0 && obj.pos[1].lat !== 0) {
                if (prevPosition !== undefined) {
                  prevPosition[1].lat = obj.pos[1].lat;
                  prevPosition[1].lng = obj.pos[1].lng;
                  console.log("01");
                  rows.push(
                    <Polyline
                      path={prevPosition}
                      options={{
                        strokeColor: "#2e92fa",
                        strokeWeight: 4,
                      }}
                    />
                  );
                  return;
                }
              }
            }
          } else if (index !== 0 && obj.gvd === 0) {
            gpsValid = 0;
          }
        });
        return rows;
      })}
      <Marker
        position={
          props.markerPosition.lat === 0 && startPosition !== null
            ? startPosition
            : props.markerPosition
        }
        defaultAnimation={google.maps.Animation.DROP}
        // options={{
        //   icon: {
        //     url: require("../../assets/images/Arrow_direction.png"),
        //     scaledSize: { width: 40, height: 40 },
        //     anchor: { x: 20, y: 20 },
        //   }
        // }}
        // draggable={true}
        options={{
          icon: {
            url: require("../../assets/images/Google_Train_icon.svg"),
            scale: 5,
            fillColor: "#2e92fa",
            fillOpacity: 0.9,
            strokeWeight: 0,
            anchor: { x: 15, y: 15 },
            //rotation: -45
          },
        }}
      ></Marker>
    </GoogleMap>
  ))
);

const NoDashbordMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 46.948393, lng: 7.435987 }}
      // mapTypeId={"terrain"}
      options={{
        styles: googleMapCustomStyle,
        streetViewControl: false,
      }}
    />
  ))
);

class TrainViewMap extends Component {
  constructor(props) {
    super(props);
    gpsValid = 1;
    prevPositionIsvalidFalse = undefined;
    prevPosition = undefined;
  }
  componentWillReceiveProps(nextProps) {
    gpsValid = 1;
    prevPositionIsvalidFalse = undefined;
    prevPosition = undefined;
  }

  render() {
    return (
      <div id="multicolormap">
        {this.props.mapData !== undefined &&
        this.props.mapData !== null &&
        this.props.mapData.length > 0 ? (
          <TrainViewGoogleMap
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `55vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            markerPosition={
              this.props.markerPosition.lat === 0 ||
              this.props.markerPosition.lng === 0
                ? this.props.mapData[0].dat[0].gvd !== 0
                  ? this.props.mapData[0].dat[0].pos[1]
                  : this.props.markerPosition
                : this.props.markerPosition
            } //{this.props.markerPosition}
            positions={this.props.mapData}
            basicFilter={this.props.basicFilter}
          />
        ) : (
          <div>
            <div className="nodatanotificationcontainer">
              <Alert variant="danger" id="nodatanotification" dismissible>
                <p className="nodatanotificationcontent">
                  No data recorded on selected day.
                </p>
              </Alert>
            </div>
            <div className="disabledcontent">
              <NoDashbordMap
                googleMapURL={googleMapURL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `55vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

TrainViewMap.defaultProps = {};

TrainViewMap.propTypes = {
  mapPath: PropTypes.any,
};

export default TrainViewMap;
