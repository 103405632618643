//Library
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { createStructuredSelector } from "reselect";
import Moment from "moment";
//Components
import "./styles.scss";
import messages from "./messages";
import SingleGraphPage from "./SingleGraphPage";
import Loading from "../../components/Loading";
import Breadcrumb from "../../components/Breadcrumb";
import Datepicker from "../../components/DatePicker";
import SideMenu from "../../components/SideMenu";
//Actions
import {
  getDetailViewMap,
  getDetailViewTimetrend,
  getDetailViewScatterplot,
  setBasicFilter,
  setAdvancedFilter,
  setMainTrendSignal,
  setMainScatterSignal,
  addOverlay,
  clearBasicFilter,
  clearAdvancedFilter,
  getDetailViewHistogram,
  setTimetrendLoading,
  clearDetailViewMap,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
  getDetailViewMapDateSlider,
  clearDetailViewMapDateSlider,
} from "./actions";
import {
  setTab,
  clearTrainViewMap,
  clearTrainViewHistogram,
} from "../TrainViewPage/actions";
import {
  setSelectedTrain,
  fetchTrainList,
  setDate,
} from "../DashBoard/actions";
//Selectors
import {
  loadingSelector,
  loadingMapSelector,
  loadingTimetrendSelector,
  scatterplotSelector,
  scatterplotBothBogieSelector,
  filteredScatterSelector,
  basicFilterSelector,
  advancedFilterSelector,
  selectedMainTrendSelector,
  selectedOverlaysTrendSelector,
  selectedMainScatterSelector,
  filteredTimetrendSelector,
  histogramSelector,
  timetrendSlip1Selector,
  timetrendSlip2Selector,
  timetrendSpeed1Selector,
  timetrendSpeed2Selector,
  timetrendEffort1Selector,
  timetrendEffort2Selector,
  timetrendEffortDriving1Selector,
  timetrendEffortDriving2Selector,
  timetrendEffortBraking1Selector,
  timetrendEffortBraking2Selector,
  timetrendEffortBrakingMech1Selector,
  timetrendEffortBrakingMech2Selector,
  ttDrivingTrailerVel1Selector,
  ttEffortReference1Selector,
  ttEffortReference2Selector,
  ttSlipPower1Selector,
  ttSlipPower2Selector,
  ttPowerBrakingMechanical1Selector,
  ttPowerBrakingMechanical2Selector,
  ttAirPressureBrakeCylinder1Selector,
  ttAirPressureBrakeCylinder2Selector,
  ttAmplitudeTorsionalOscillation1Selector,
  ttAmplitudeTorsionalOscillation2Selector,
  loadingScatterB2Selector,
  mapSlip1Selector,
  mapSlip2Selector,
  mapSpeed1Selector,
  mapSpeed2Selector,
  mapEffort1Selector,
  mapEffort2Selector,
  mapEffortDriving1Selector,
  mapEffortDriving2Selector,
  mapEffortBraking1Selector,
  mapEffortBraking2Selector,
  mapEffortBrakingMech1Selector,
  mapEffortBrakingMech2Selector,
  mapDrivingTrailerVel1Selector,
  mapEffortReference1Selector,
  mapEffortReference2Selector,
  mapSlipPower1Selector,
  mapSlipPower2Selector,
  mapPowerBrakingMechanical1Selector,
  mapPowerBrakingMechanical2Selector,
  mapAirPressureBrakeCylinder1Selector,
  mapAirPressureBrakeCylinder2Selector,
  mapAmplitudeTorsionalOscillation1Selector,
  mapAmplitudeTorsionalOscillation2Selector,
  filteredMapSelector,
  mapTotalRunsSelector,
} from "./selectors";
import { selectedTabSelector } from "../TrainViewPage/selectors";
import {
  startDateSelector,
  endDateSelector,
  selectedTrainSelector,
  fleetDataSelector,
} from "../DashBoard/selectors";
import { overlaySignalOptions } from "./SingleGraphPage/constants";

import MomentLocalizer from "react-widgets-moment";

Moment.locale("en");
MomentLocalizer();

let xAxisSignalScatter = "Speed";
let yAxisSignalScatter = "Slip";
let mapStartDate, mapEndDate, selectedDate;
let zoomHistory = [];

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

class DetailTrainView extends React.Component {
  constructor(props) {
    super(props);

    this.props.selectedMainTrend == null &&
      this.props.setMainTrendSignal("Speed Driving Trailer");

    this.props.setMainScatterSignal("Speed/Effort");

    this.props.basicFilter.operation == undefined &&
      this.props.setBasicFilter({
        weather: "All",
        operation: [0, 1, 2, 3, 4, 5],
        bogie: 1,
      });

    this.props.advancedFilter.temperature == undefined &&
      this.props.setAdvancedFilter({
        temperature: [1, 2, 3],
        drivingtrailervelocity: 2,
        sander: 2,
        toprotection: 2,
        // adhesioncontrol: 2,
        // slingbrake: 2,
        antislipbrake: 2,
      });

    this.state = {
      xAxisSignal: "Speed",
      yAxisSignal: "Effort",
      zAxisSignal: "Occurrence",
      scatterDropdownDefaultValue: "Speed/Slip",
      isButtonDisabled: false,
      isApplyZoomEnabled: false,
      startZoomInterval: null,
      endZoomInterval: null,
      lockedZoomDuration: null,
      scatterplotAvg: true,
      loadingOverlay: false,
      //scatterplotdata: this.props.scatterplotdata
      //xAxisSignalScatter: "Slip",
      // yAxisSignalScatter: "Speed"
    };
  }

  componentDidMount() {
    sessionStorage.removeItem("clickedTimeStamp");
    sessionStorage.removeItem("clickedSignal");
    sessionStorage.removeItem("clickedTimeStampForRunSlider");
    let startDate = this.props.startDate,
      endDate = this.props.endDate;
    let formatedStartDte = Moment(
        new Date(this.props.startDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm"),
      formatedEndDte = Moment(
        new Date(this.props.endDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm");
    this.setState({
      startZoomInterval: formatedStartDte,
      endZoomInterval: formatedEndDte,
      lockedZoomDuration: this.getDifferenceText(
        Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      ),
    });

    let currentSamplingType = this.getSamplingType(
      Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
      Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
    );

    sessionStorage.setItem("samplingType", currentSamplingType);
    sessionStorage.setItem("applyZoomInterval", null);
    sessionStorage.setItem("zoomIntervalStartDate", null);
    sessionStorage.setItem("zoomIntervalEndDate", null);
    sessionStorage.setItem("isTrendZoomed", null);
    this.props.setTimetrendLoading(true);
    this.onClickTab(this.props.selectedTab);

    if (this.props.trainList === null) {
      this.props.fetchTrainList(startDate, endDate);
    }

    zoomHistory = [];
    zoomHistory.push([startDate, endDate]);
  }
  componentWillUnmount() {
    //this.onLoadMoreGraph();
  }
  handleScatterDropdownChange = (event) => {
    let val;
    if (event === "Speed Driving Trailer / Slip") val = "Speed/Slip";
    if (event === "Speed Driving Trailer / Effort") val = "Speed/Effort";
    if (event === "Speed Driving Trailer / Effort Mech. Brake")
      val = "Speed/EffortBrakingMechanical";
    if (event === "Slip / Effort") val = "Slip/Effort";
    if (event === "Speed Driving Trailer / Torsional Osc. Ampl.")
      val = "Speed/AmplitudeTorsionalOscillation";
    if (event === "Slip / Torsional Osc. Ampl.")
      val = "Slip/AmplitudeTorsionalOscillation";
    if (event === "Effort / Torsional Osc. Ampl.")
      val = "Effort/AmplitudeTorsionalOscillation";
    if (event === "Slip / Effort Mech. Brake")
      val = "Slip/EffortBrakingMechanical";
    this.setState(
      {
        ...this.state,
        ["scatterDropdownDefaultValue"]: val,
      },
      () => {
        this.getSctterDataBasedonUserSelection();
      }
    );
  };

  getSctterDataBasedonUserSelection = () => {
    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null" &&
      sessionStorage.getItem("applyZoomInterval") === "true"
    ) {
      startDate = sessionStorage.getItem("zoomIntervalStartDate");
      endDate = sessionStorage.getItem("zoomIntervalEndDate");
    }

    let scatterSeletedValue = this.state.scatterDropdownDefaultValue.split("/");
    xAxisSignalScatter = scatterSeletedValue[0];
    yAxisSignalScatter = scatterSeletedValue[1];

    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "1",
      this.state.scatterplotAvg
    );
    this.props.getDetailViewScatterplot(
      this.props.selectedTrain,
      startDate,
      endDate,
      xAxisSignalScatter,
      yAxisSignalScatter,
      "2",
      this.state.scatterplotAvg
    );
  };

  handleScatterplotAvgMaxChange = (value) => {
    let result = value === "Maximum" ? false : true;
    this.setState({ scatterplotAvg: result }, () => {
      this.getSctterDataBasedonUserSelection();
    });
  };

  handleHistogramDropdownChange = (name) => (event) => {
    this.setState({
      ...this.state,
      [name]: event,
    });
  };

  getHistogramDataBasedonUserSelection = () => {
    let startDate = this.props.startDate,
      endDate = this.props.endDate;
    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null" &&
      sessionStorage.getItem("applyZoomInterval") === "true"
    ) {
      startDate = sessionStorage.getItem("zoomIntervalStartDate");
      endDate = sessionStorage.getItem("zoomIntervalEndDate");
    }

    this.props.getDetailViewHistogram(
      this.props.selectedTrain,
      startDate,
      endDate,
      this.state.xAxisSignal,
      this.state.yAxisSignal,
      this.state.zAxisSignal,
      [this.props.basicFilter, this.props.advancedFilter]
    );
  };
  //  method for apply zoom interval starts here

  applyZoomInterval = async () => {
    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null"
    ) {
      sessionStorage.setItem("applyZoomInterval", true);
      this.setState({
        isButtonDisabled: true,
        isApplyZoomEnabled: true,
      });
      await this.props.clearDetailViewMap();
      await this.props.clearDetailViewMapDateSlider();
      await this.props.clearDetailViewScatterplot();
      await this.props.clearDetailViewHistogram();
    }
  };

  // method for apply zoom interval ends here

  applyDateChange = async (start_Date, end_Date, trainSelect = true) => {
    sessionStorage.removeItem("clickedTimeStamp");
    sessionStorage.removeItem("clickedSignal");
    sessionStorage.removeItem("clickedTimeStampForRunSlider");
    if (trainSelect)
      if (
        new Date(start_Date).valueOf() ==
          new Date(this.props.startDate.replace(/%3A/g, ":")).valueOf() &&
        new Date(end_Date).valueOf() ==
          new Date(this.props.endDate.replace(/%3A/g, ":")).valueOf()
      ) {
        return false;
      }
    selectedDate = null;
    await this.props.setDate(start_Date, end_Date);

    await this.props.clearTrainViewMap();
    await this.props.clearTrainViewHistogram();
    await this.props.clearDetailViewMap();
    await this.props.clearDetailViewMapDateSlider();
    await this.props.clearDetailViewTimetrend();
    await this.props.clearDetailViewScatterplot();
    await this.props.clearDetailViewHistogram();
    await this.props.addOverlay(null);
    sessionStorage.setItem("samplingType", null);
    sessionStorage.setItem("applyZoomInterval", null);
    sessionStorage.setItem("zoomIntervalStartDate", null);
    sessionStorage.setItem("zoomIntervalEndDate", null);
    sessionStorage.setItem("isTrendZoomed", null);
    this.setState({
      isButtonDisabled: false,
      isApplyZoomEnabled: false,
    });

    let startDate = this.props.startDate,
      endDate = this.props.endDate;

    let formatedStartDte = Moment(
        new Date(this.props.startDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm"),
      formatedEndDte = Moment(
        new Date(this.props.endDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm");
    this.setState({
      startZoomInterval: formatedStartDte,
      endZoomInterval: formatedEndDte,
      lockedZoomDuration: this.getDifferenceText(
        Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      ),
    });
    let currentSamplingType = this.getSamplingType(
      Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
      Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
    );
    sessionStorage.setItem("samplingType", currentSamplingType);

    this.props.fetchTrainList(startDate, endDate);

    this.props.setTimetrendLoading(true);
    this.onClickTab(this.props.selectedTab);
    zoomHistory = [];
    zoomHistory.push([startDate, endDate]);
  };

  onMapDateSelect = async (key) => {
    if (
      key !== null &&
      key !==
        Moment(sessionStorage.getItem("clickedTimeStamp"), "DD.MM.YYYY").format(
          "DD-M-YYYY"
        )
    ) {
      sessionStorage.removeItem("clickedTimeStamp");
      sessionStorage.removeItem("clickedTimeStampForRunSlider");
      sessionStorage.removeItem("clickedSignal");
    }
    if (sessionStorage.getItem("clickedSignal") !== null) {
      this.props.setMainTrendSignal(
        sessionStorage.getItem("clickedSignal").trim()
      );
      this.props.addOverlay(null);
    }
    let startDate = this.props.startDate;
    let endDate = this.props.endDate;
    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null" &&
      sessionStorage.getItem("applyZoomInterval") === "true" &&
      sessionStorage.getItem("runClick") !== "true"
    ) {
      startDate = sessionStorage.getItem("zoomIntervalStartDate");
      endDate = sessionStorage.getItem("zoomIntervalEndDate");
    }
    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null" &&
      sessionStorage.getItem("applyZoomInterval") === "true" &&
      sessionStorage.getItem("runClick") === "true"
    ) {
      if (sessionStorage.getItem("zoomIntervalPrevStartDate") !== null) {
        startDate = sessionStorage.getItem("zoomIntervalPrevStartDate");
        endDate = sessionStorage.getItem("zoomIntervalPrevEndDate");
      }
      console.log(
        "zoomHistoryyPrev2",
        sessionStorage.getItem("zoomIntervalPrevStartDate"),
        sessionStorage.getItem("zoomIntervalPrevEndDate")
      );
    }

    let topdate = Moment(new Date(startDate.replace(/%3A/g, ":"))).format(
      "DD-MM-YYYY"
    );
    let enddate = Moment(new Date(endDate.replace(/%3A/g, ":"))).format(
      "HH:mm:ss"
    );
    if (enddate === "00:00:00") {
      enddate = Moment(new Date(endDate.replace(/%3A/g, ":")))
        .subtract(1, "days")
        .endOf("day")
        .format("DD-MM-YYYY");
    } else {
      enddate = Moment(new Date(endDate.replace(/%3A/g, ":"))).format(
        "DD-MM-YYYY"
      );
    }

    // let enddate = Moment(new Date(endDate.replace(/%3A/g, ":"))).format(
    //   "DD-MM-YYYY"
    // );
    const totalDays =
      dateDiffInDays(
        new Date(startDate.replace(/%3A/g, ":")),
        new Date(endDate.replace(/%3A/g, ":"))
      ) + 1;
    selectedDate =
      key !== null ? key : selectedDate != null ? selectedDate : null;
    let arr =
      selectedDate != null ? selectedDate.split("-") : enddate.split("-");
    let mapStartDatePrev = mapStartDate,
      mapEndDatePrev = mapEndDate;
    let currMonth = arr[1].length === 1 ? "0" + arr[1] : arr[1];
    let currDate = arr[0].length === 1 ? "0" + arr[0] : arr[0];
    let currentDate = currDate + "-" + currMonth + "-" + arr[2];

    if (sessionStorage.getItem("clickedTimeStamp") !== null) {
      mapStartDate = startDate;
      mapEndDate = endDate;
    } else if (totalDays === 1) {
      mapStartDate = startDate;
      mapEndDate = endDate;
    } else {
      if (topdate === currentDate) {
        mapStartDate = startDate;
        mapEndDate = arr[2] + "-" + arr[1] + "-" + arr[0] + "T23%3A59%3A59";
      } else if (enddate === currentDate) {
        mapStartDate = arr[2] + "-" + arr[1] + "-" + arr[0] + "T00%3A00%3A00";
        mapEndDate = endDate;
      } else {
        mapStartDate = arr[2] + "-" + arr[1] + "-" + arr[0] + "T00%3A00%3A00";
        mapEndDate = arr[2] + "-" + arr[1] + "-" + arr[0] + "T23%3A59%3A59";
      }
    }

    if (
      mapStartDate != mapStartDatePrev ||
      mapEndDate != mapEndDatePrev ||
      sessionStorage.getItem("clickedTimeStamp") !== null
    ) {
      await this.props.clearDetailViewMap();
      //await this.props.clearDetailViewMapDateSlider();
    }

    /*if (this.props.mapTotalRuns === null) {
      this.props.getDetailViewMapDateSlider(
        this.props.selectedTrain,
        startDate,
        endDate
      );
    }*/

    switch (this.props.selectedMainTrend) {
      case "Slip":
        this.props.mapSlip1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Slip",
            "1"
          );
        this.props.mapSlip2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Slip",
            "2"
          );
        break;

      case "Speed at Wheel":
        this.props.mapSpeed1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Speed",
            "1"
          );
        this.props.mapSpeed2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Speed",
            "2"
          );
        break;

      case "Effort":
        this.props.mapEffort1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Effort",
            "1"
          );
        this.props.mapEffort2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "Effort",
            "2"
          );
        break;

      /*case "EffortDriving":
        this.props.mapEffortDriving1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortDriving",
            "1"
          );
        this.props.mapEffortDriving2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortDriving",
            "2"
          );
        break;

      case "EffortBraking":
        this.props.mapEffortBraking1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortBraking",
            "1"
          );
        this.props.mapEffortBraking2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortBraking",
            "2"
          );
        break;*/

      case "Effort Mech. Brake":
        this.props.mapEffortBrakingMech1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortBrakingMechanical",
            "1"
          );
        this.props.mapEffortBrakingMech2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortBrakingMechanical",
            "2"
          );
        break;

      case "Speed Driving Trailer":
        this.props.mapDrivingTrailerVel1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "DrivingTrailerVel",
            "1"
          );
        break;

      case "Effort Reference":
        this.props.mapEffortReference1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortReference",
            "1"
          );
        this.props.mapEffortReference2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "EffortReference",
            "2"
          );
        break;

      case "Power Slip":
        this.props.mapSlipPower1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "SlipPower",
            "1"
          );
        this.props.mapSlipPower2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "SlipPower",
            "2"
          );
        break;

      case "Power Mechanical Brake":
        this.props.mapPowerBrakingMechanical1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "PowerBrakingMechanical",
            "1"
          );
        this.props.mapPowerBrakingMechanical2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "PowerBrakingMechanical",
            "2"
          );
        break;

      case "Pressure Brake Cyl.":
        this.props.mapAirPressureBrakeCylinder1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "AirPressureBrakeCylinder",
            "1"
          );
        this.props.mapAirPressureBrakeCylinder2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "AirPressureBrakeCylinder",
            "2"
          );
        break;

      case "Torsional Osc. Ampl.":
        this.props.mapAmplitudeTorsionalOscillation1 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "AmplitudeTorsionalOscillation",
            "1"
          );
        this.props.mapAmplitudeTorsionalOscillation2 === null &&
          this.props.getDetailViewMap(
            this.props.selectedTrain,
            mapStartDate,
            mapEndDate,
            "AmplitudeTorsionalOscillation",
            "2"
          );
        break;

      default:
        break;
    }
  };

  clearBasicFilter = () => {
    this.props.clearBasicFilter();
  };

  clearAdvancedFilter = () => {
    this.props.clearAdvancedFilter();
  };

  onTrainSelect = async (e) => {
    await this.props.setSelectedTrain(e.target.id);
    let startDate = new Date(this.props.startDate.replace(/%3A/g, ":")),
      endDate = new Date(this.props.endDate.replace(/%3A/g, ":"));
    this.applyDateChange(startDate, endDate, false);
  };

  changeBasicFilter = (e) => {
    let currentTargetValue = Number(e.currentTarget.value);
    if (isNaN(currentTargetValue)) currentTargetValue = e.currentTarget.value;

    if (e.target.type !== "checkbox") {
      this.props.setBasicFilter({
        ...this.props.basicFilter,
        [e.target.name]: currentTargetValue,
      });
    } else if (e.target.type === "checkbox") {
      if (e.target.name === "operation") {
        if (e.currentTarget.checked) {
          let operationValue = [currentTargetValue];

          this.props.basicFilter.operation != null &&
            operationValue.push(...this.props.basicFilter.operation);

          this.props.setBasicFilter({
            ...this.props.basicFilter,
            [e.target.name]: operationValue,
          });
        } else if (!e.currentTarget.checked) {
          let operationValue = [];

          this.props.basicFilter.operation != null &&
            (operationValue = this.props.basicFilter.operation);

          var index = operationValue.indexOf(currentTargetValue);
          index > -1 && operationValue.splice(index, 1);

          this.props.setBasicFilter({
            ...this.props.basicFilter,
            [e.target.name]: operationValue,
          });
        }
      }
    }
  };

  changeAdvancedFilter = (e) => {
    let currentTargetValue = Number(e.currentTarget.value);
    if (isNaN(currentTargetValue)) currentTargetValue = e.currentTarget.value;

    if (e.target.type !== "checkbox") {
      this.props.setAdvancedFilter({
        ...this.props.advancedFilter,
        [e.target.name]: currentTargetValue,
      });
    } else if (e.target.type === "checkbox") {
      if (e.target.name === "temperature") {
        if (e.currentTarget.checked) {
          let operationValue = [currentTargetValue];

          this.props.advancedFilter.temperature != null &&
            operationValue.push(...this.props.advancedFilter.temperature);

          this.props.setAdvancedFilter({
            ...this.props.advancedFilter,
            [e.target.name]: operationValue,
          });
        } else if (!e.currentTarget.checked) {
          let operationValue = [];

          this.props.advancedFilter.temperature != null &&
            (operationValue = this.props.advancedFilter.temperature);

          var index = operationValue.indexOf(currentTargetValue);
          index > -1 && operationValue.splice(index, 1);

          this.props.setAdvancedFilter({
            ...this.props.advancedFilter,
            [e.target.name]: operationValue,
          });
        }
      }
    }
  };

  clickMainSignalTrend = (selectedMainOption) => {
    sessionStorage.removeItem("clickedSignal");
    this.props.setMainTrendSignal(selectedMainOption);
    this.props.addOverlay([]);
  };

  clickOverlaySignalTrend = (selectedOverlaysTrend) => {
    this.props.addOverlay(selectedOverlaysTrend);
    //console.log(`Overlay selected:`, selectedOverlays);
  };

  clickMainSignalScatter = (selectedMainOption) => {
    this.props.setMainScatterSignal(selectedMainOption);
  };

  onClickTab = async (tab) => {
    if (tab !== "MAP_TYPE") {
      sessionStorage.removeItem("clickedTimeStamp");
      sessionStorage.removeItem("clickedTimeStampForRunSlider");
      sessionStorage.removeItem("clickedSignal");
    }
    selectedDate = null;
    this.props.setTab(tab);

    let startDate = this.props.startDate,
      endDate = this.props.endDate;
    if (
      sessionStorage.getItem("zoomIntervalStartDate") !== "null" &&
      sessionStorage.getItem("zoomIntervalEndDate") !== "null" &&
      sessionStorage.getItem("applyZoomInterval") === "true"
    ) {
      startDate = sessionStorage.getItem("zoomIntervalStartDate");
      endDate = sessionStorage.getItem("zoomIntervalEndDate");
    }

    if (tab === "TREND_TYPE") {
      this.getTimetrendData(this.props.selectedMainTrend, startDate, endDate);
    } else if (tab === "SCATTER_TYPE") {
      let scatterSeletedValue = this.state.scatterDropdownDefaultValue.split(
        "/"
      );
      xAxisSignalScatter = scatterSeletedValue[0];
      yAxisSignalScatter = scatterSeletedValue[1];
      if (this.props.scatterplotBothBogieData.length === 0) {
        this.props.getDetailViewScatterplot(
          this.props.selectedTrain,
          startDate,
          endDate,
          xAxisSignalScatter,
          yAxisSignalScatter,
          "1",
          this.state.scatterplotAvg
        );
        this.props.getDetailViewScatterplot(
          this.props.selectedTrain,
          startDate,
          endDate,
          xAxisSignalScatter,
          yAxisSignalScatter,
          "2",
          this.state.scatterplotAvg
        );
      }
    } else if (tab === "HISTOGRAM_TYPE") {
      if (this.props.histogramdata === null) {
        this.props.getDetailViewHistogram(
          this.props.selectedTrain,
          startDate,
          endDate,
          this.state.xAxisSignal,
          this.state.yAxisSignal,
          this.state.zAxisSignal,
          [this.props.basicFilter, this.props.advancedFilter]
        );
      }
    } else if (tab === "MAP_TYPE") {
      if (sessionStorage.getItem("runClick") === "true") {
        await this.props.clearDetailViewMapDateSlider();
        sessionStorage.setItem("runClick", false);
      }
      if (this.props.mapTotalRuns === null) {
        this.props.getDetailViewMapDateSlider(
          this.props.selectedTrain,
          startDate,
          endDate
        );
      }
      this.onMapDateSelect(null);
    }
    this.handleLoading();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.timetrenddata !== nextProps.timetrenddata) {
      let count = 0;
      let bogie = this.props.basicFilter.bogie;
      let allSignals = [],
        selectedSignalsLength;
      allSignals.push(this.props.selectedMainTrend);
      if (this.props.selectedOverlaysTrend != null)
        allSignals = allSignals.concat(this.props.selectedOverlaysTrend);

      allSignals.map((signal) => {
        switch (signal) {
          case "Slip":
            if (bogie === 1) {
              this.props.timetrendSlip1 !== null && count++;
            } else if (bogie === 2) {
              this.props.timetrendSlip2 !== null && count++;
            } else {
              this.props.timetrendSlip1 !== null &&
                this.props.timetrendSlip2 !== null &&
                count++;
            }
            break;
          case "Speed at Wheel":
            if (bogie === 1) {
              this.props.timetrendSpeed1 !== null && count++;
            } else if (bogie === 2) {
              this.props.timetrendSpeed2 !== null && count++;
            } else {
              this.props.timetrendSpeed1 !== null &&
                this.props.timetrendSpeed2 !== null &&
                count++;
            }
            break;
          case "Effort":
            if (bogie === 1) {
              this.props.timetrendEffort1 !== null && count++;
            } else if (bogie === 2) {
              this.props.timetrendEffort2 !== null && count++;
            } else {
              this.props.timetrendEffort1 !== null &&
                this.props.timetrendEffort2 !== null &&
                count++;
            }
            break;
          case "Effort Mech. Brake":
            if (bogie === 1) {
              this.props.timetrendEffortBrakingMech1 !== null && count++;
            } else if (bogie === 2) {
              this.props.timetrendEffortBrakingMech2 !== null && count++;
            } else {
              this.props.timetrendEffortBrakingMech1 !== null &&
                this.props.timetrendEffortBrakingMech2 !== null &&
                count++;
            }
            break;
          case "Speed Driving Trailer":
            this.props.ttDrivingTrailerVel1 !== null && count++;
            break;
          case "Effort Reference":
            if (bogie === 1) {
              this.props.ttEffortReference1 !== null && count++;
            } else if (bogie === 2) {
              this.props.ttEffortReference2 !== null && count++;
            } else {
              this.props.ttEffortReference1 !== null &&
                this.props.ttEffortReference2 !== null &&
                count++;
            }
            break;
          case "Power Slip":
            if (bogie === 1) {
              this.props.ttSlipPower1 !== null && count++;
            } else if (bogie === 2) {
              this.props.ttSlipPower2 !== null && count++;
            } else {
              this.props.ttSlipPower1 !== null &&
                this.props.ttSlipPower2 !== null &&
                count++;
            }
            break;
          case "Power Mechanical Brake":
            if (bogie === 1) {
              this.props.ttPowerBrakingMechanical1 !== null && count++;
            } else if (bogie === 2) {
              this.props.ttPowerBrakingMechanical2 !== null && count++;
            } else {
              this.props.ttPowerBrakingMechanical1 !== null &&
                this.props.ttPowerBrakingMechanical2 !== null &&
                count++;
            }
            break;
          case "Pressure Brake Cyl.":
            if (bogie === 1) {
              this.props.ttAirPressureBrakeCylinder1 !== null && count++;
            } else if (bogie === 2) {
              this.props.ttAirPressureBrakeCylinder2 !== null && count++;
            } else {
              this.props.ttAirPressureBrakeCylinder1 !== null &&
                this.props.ttAirPressureBrakeCylinder2 !== null &&
                count++;
            }
            break;
          case "Torsional Osc. Ampl.":
            if (bogie === 1) {
              this.props.ttAmplitudeTorsionalOscillation1 !== null && count++;
            } else if (bogie === 2) {
              this.props.ttAmplitudeTorsionalOscillation2 !== null && count++;
            } else {
              this.props.ttAmplitudeTorsionalOscillation1 !== null &&
                this.props.ttAmplitudeTorsionalOscillation2 !== null &&
                count++;
            }
            break;
          default:
            break;
        }
      });
      selectedSignalsLength = allSignals.length;
      if (count === selectedSignalsLength) {
        //this.props.setTimetrendLoading(false);
        this.setState({ loadingOverlay: false });
      }
    }
  }

  handleLoading = () => {
    let bogie = this.props.basicFilter.bogie;
    switch (this.props.selectedMainTrend) {
      case "Slip":
        if (bogie === 1) {
          this.props.timetrendSlip1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendSlip2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendSlip1 !== null &&
            this.props.timetrendSlip2 !== null &&
            this.props.setTimetrendLoading(false);
        }
        break;
      case "Speed at Wheel":
        if (bogie === 1) {
          this.props.timetrendSpeed1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendSpeed2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendSpeed1 !== null &&
            this.props.timetrendSpeed2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "Effort":
        if (bogie === 1) {
          this.props.timetrendEffort1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendEffort2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendEffort1 !== null &&
            this.props.timetrendEffort2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      /*case "EffortDriving":
        if (bogie === 1) {
          this.props.timetrendEffortDriving1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendEffortDriving2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendEffortDriving1 !== null &&
            this.props.timetrendEffortDriving2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "EffortBraking":
        if (bogie === 1) {
          this.props.timetrendEffortBraking1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendEffortBraking2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendEffortBraking1 !== null &&
            this.props.timetrendEffortBraking2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;*/
      case "Effort Mech. Brake":
        if (bogie === 1) {
          this.props.timetrendEffortBrakingMech1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.timetrendEffortBrakingMech2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.timetrendEffortBrakingMech1 !== null &&
            this.props.timetrendEffortBrakingMech2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;

      case "Speed Driving Trailer":
        this.props.ttDrivingTrailerVel1 !== null &&
          this.props.setTimetrendLoading(false);

        break;
      case "Effort Reference":
        if (bogie === 1) {
          this.props.ttEffortReference1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.ttEffortReference2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.ttEffortReference1 !== null &&
            this.props.ttEffortReference2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "Power Slip":
        if (bogie === 1) {
          this.props.ttSlipPower1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.ttSlipPower2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.ttSlipPower1 !== null &&
            this.props.ttSlipPower2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "Power Mechanical Brake":
        if (bogie === 1) {
          this.props.ttPowerBrakingMechanical1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.ttPowerBrakingMechanical2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.ttPowerBrakingMechanical1 !== null &&
            this.props.ttPowerBrakingMechanical2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "Pressure Brake Cyl.":
        if (bogie === 1) {
          this.props.ttAirPressureBrakeCylinder1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.ttAirPressureBrakeCylinder2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.ttAirPressureBrakeCylinder1 !== null &&
            this.props.ttAirPressureBrakeCylinder2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;
      case "Torsional Osc. Ampl.":
        if (bogie === 1) {
          this.props.ttAmplitudeTorsionalOscillation1 !== null &&
            this.props.setTimetrendLoading(false);
        } else if (bogie === 2) {
          this.props.ttAmplitudeTorsionalOscillation2 !== null &&
            this.props.setTimetrendLoading(false);
        } else {
          this.props.ttAmplitudeTorsionalOscillation1 !== null &&
            this.props.ttAmplitudeTorsionalOscillation2 !== null &&
            this.props.setTimetrendLoading(false);
        }

        break;

      default:
        break;
    }
  };

  //------------------------------------------------date diff
  getDateDiff = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      let duration = Moment.duration(Moment(EndDate).diff(Moment(StartDate)));
      let totaldays = duration.asDays();
      let TotalHours = duration.asHours();
      return { totaldays, TotalHours };
    }

    return "";
  };

  getSamplingType = (startDate, endDate) => {
    let { totaldays, TotalHours } = this.getDateDiff(startDate, endDate);
    let samplingType = "";
    // let totaldays = 0;
    // let TotalHours = 22;
    if (totaldays > 60 && totaldays <= 90) {
      samplingType = "30 minute";
    } else if (totaldays > 20 && totaldays <= 60) {
      samplingType = "15 minute";
    } else if (totaldays > 5 && totaldays <= 20) {
      samplingType = "4 minute";
    } else if (totaldays > 1 && totaldays <= 5) {
      samplingType = "1 minute";
    } else if (totaldays <= 1) {
      if (TotalHours > 4) {
        samplingType = "15 second";
      } else if (TotalHours > 1 && TotalHours <= 4) {
        samplingType = "4 second";
      } else {
        samplingType = "1 second";
      }
    }

    console.log("dateDiffNewChanged", samplingType, totaldays, TotalHours);
    return samplingType;
  };

  getDifferenceText = (StartDate, EndDate) => {
    if (StartDate && EndDate) {
      const localStartDate = StartDate;
      const localEndDate = EndDate;
      const diff = Moment(localEndDate).diff(Moment(localStartDate));
      const d = Moment.duration(diff);

      const days = Math.floor(d.asHours() / 24);
      const hours = Math.floor(d.asHours() - days * 24);
      const minutes = Math.floor(d.asMinutes() - (hours * 60 + days * 24 * 60));
      let msg = days > 0 ? ` ${days} days` : "";
      msg += hours > 0 || minutes > 0 ? ` ${hours} hours` : "";
      msg += minutes > 0 ? ` ${minutes} minutes` : "";

      if (days < 0 || hours < 0 || minutes < 0) {
        msg = "";
      }
      return msg;
    }

    return "";
  };

  //-------------------------------------------------
  onLoadMoreGraph = async (start, end, iszoom, iszoomout) => {
    sessionStorage.removeItem("clickedTimeStampForRunSlider");
    let formatedStartDate, formatedEndDate;
    if (iszoom === true) {
      let currentSamplingType = this.getSamplingType(
        Moment.utc(start[0], "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(end[0], "DD.MM.YYYY HH:mm:ss").toISOString()
      );

      let previousSamplingType =
        sessionStorage.getItem("samplingType") === null
          ? null
          : sessionStorage.getItem("samplingType");
      console.log("issame", currentSamplingType, previousSamplingType);
      //----------------------------------------------------
      if (
        previousSamplingType !== null &&
        currentSamplingType === previousSamplingType
      ) {
        return false;
      }
      //if (sessionStorage.getItem("applyZoomInterval") === "true")
      {
        await this.props.clearDetailViewMap();
        await this.props.clearDetailViewMapDateSlider();
        await this.props.clearDetailViewScatterplot();
        await this.props.clearDetailViewHistogram();
      }
      await this.props.clearDetailViewTimetrend();
      // await this.props.addOverlay(null);
      formatedStartDate = Moment.utc(start[0], "DD.MM.YYYY HH:mm:ss")
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, "%3A");
      formatedEndDate = Moment.utc(end[0], "DD.MM.YYYY HH:mm:ss")
        .toISOString()
        .slice(0, 19)
        .replace(/:/g, "%3A");
      sessionStorage.setItem("zoomIntervalStartDate", formatedStartDate);
      sessionStorage.setItem("zoomIntervalEndDate", formatedEndDate);
      sessionStorage.setItem("samplingType", currentSamplingType);
      sessionStorage.setItem("isTrendZoomed", true);
      sessionStorage.setItem("applyZoomInterval", true);
      console.log("onzoomdate", start, end, formatedStartDate, formatedEndDate);
      this.setState({
        startZoomInterval: Moment.utc(start[0], "DD.MM.YYYY HH:mm:ss").format(
          "DD.MM.YYYY, HH:mm"
        ),
        endZoomInterval: Moment.utc(end[0], "DD.MM.YYYY HH:mm:ss").format(
          "DD.MM.YYYY, HH:mm"
        ),
        lockedZoomDuration: this.getDifferenceText(
          Moment.utc(start[0], "DD.MM.YYYY HH:mm:ss").toISOString(),
          Moment.utc(end[0], "DD.MM.YYYY HH:mm:ss").toISOString()
        ),
      });
      zoomHistory.push([formatedStartDate, formatedEndDate]);
      //console.log("zoomHistory", zoomHistory);
    } else if (iszoomout === true) {
      if (zoomHistory.length > 1) {
        let backDate = zoomHistory[zoomHistory.length - 2];
        zoomHistory.pop();
        formatedStartDate = backDate[0];
        formatedEndDate = backDate[1];
        //console.log("zoomHistoryy", formatedStartDate, formatedEndDate);
      } else {
        //zoomHistory = [];
        // formatedStartDate = this.props.startDate;
        // formatedEndDate = this.props.endDate;
        return false;
      }

      let formatedStartDte = Moment(
          new Date(formatedStartDate.replace(/%3A/g, ":"))
        ).format("DD.MM.YYYY, HH:mm"),
        formatedEndDte = Moment(
          new Date(formatedEndDate.replace(/%3A/g, ":"))
        ).format("DD.MM.YYYY, HH:mm");
      this.setState({
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration: this.getDifferenceText(
          Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
          Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
        ),
      });
      let currentSamplingType = this.getSamplingType(
        Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      );

      //await this.props.clearTrainViewMap();
      await this.props.clearDetailViewMap();
      await this.props.clearDetailViewMapDateSlider();
      await this.props.clearDetailViewTimetrend();
      await this.props.clearDetailViewScatterplot();
      await this.props.clearDetailViewHistogram();
      // await this.props.addOverlay(null);
      sessionStorage.setItem("zoomIntervalStartDate", formatedStartDate);
      sessionStorage.setItem("zoomIntervalEndDate", formatedEndDate);
      sessionStorage.setItem("samplingType", currentSamplingType);
      sessionStorage.setItem("isTrendZoomed", true);
      sessionStorage.setItem("applyZoomInterval", true);
    } else {
      zoomHistory = [];
      zoomHistory.push([this.props.startDate, this.props.endDate]);
      //await this.props.clearTrainViewMap();
      await this.props.clearDetailViewMap();
      await this.props.clearDetailViewMapDateSlider();
      await this.props.clearDetailViewTimetrend();
      await this.props.clearDetailViewScatterplot();
      await this.props.clearDetailViewHistogram();
      // await this.props.addOverlay(null);
      formatedStartDate = this.props.startDate;
      formatedEndDate = this.props.endDate;
      sessionStorage.setItem("samplingType", null);
      sessionStorage.setItem("applyZoomInterval", null);
      sessionStorage.setItem("zoomIntervalStartDate", null);
      sessionStorage.setItem("zoomIntervalEndDate", null);
      sessionStorage.setItem("isTrendZoomed", null);
      //this.onClickTab(this.props.selectedTab);

      let formatedStartDte = Moment(
          new Date(this.props.startDate.replace(/%3A/g, ":"))
        ).format("DD.MM.YYYY, HH:mm"),
        formatedEndDte = Moment(
          new Date(this.props.endDate.replace(/%3A/g, ":"))
        ).format("DD.MM.YYYY, HH:mm");
      this.setState({
        startZoomInterval: formatedStartDte,
        endZoomInterval: formatedEndDte,
        lockedZoomDuration: this.getDifferenceText(
          Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
          Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
        ),
      });
      let currentSamplingType = this.getSamplingType(
        Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      );
      sessionStorage.setItem("samplingType", currentSamplingType);
      //this.props.setTimetrendLoading(true);
      this.setState({ loadingOverlay: true });
      this.onClickTab(this.props.selectedTab);
      return;
    }
    this.setState({
      isButtonDisabled: false,
      isApplyZoomEnabled: false,
    });
    this.setState({ loadingOverlay: true });
    this.getTimetrendData(
      this.props.selectedMainTrend,
      formatedStartDate,
      formatedEndDate
    );
    /*this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Slip",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Slip",
      "2"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Speed",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Speed",
      "2"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Effort",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "Effort",
      "2"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortDriving",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortDriving",
      "2"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortBraking",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortBraking",
      "2"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortBrakingMechanical",
      "1"
    );

    this.props.getDetailViewTimetrend(
      this.props.selectedTrain,
      formatedStartDate,
      formatedEndDate,
      "EffortBrakingMechanical",
      "2"
    );*/
  };

  //timetrend functions-start(this functions call the api's. In that selected signal api will be called first)
  getTimetrendData = (selectedSignal, startDate, endDate) => {
    let allSignals = [];
    allSignals.push(selectedSignal);
    if (this.props.selectedOverlaysTrend != null)
      allSignals = allSignals.concat(this.props.selectedOverlaysTrend);
    let filterSignals = overlaySignalOptions.filter(
      (item) => !allSignals.includes(item)
    );
    allSignals = allSignals.concat(filterSignals);

    let bogie = [];
    if (this.props.basicFilter.bogie === 1) {
      bogie.push(1);
      bogie.push(2);
    } else if (this.props.basicFilter.bogie === 2) {
      bogie.push(2);
      bogie.push(1);
    } else bogie.push(3);

    bogie.map((bg) => {
      allSignals.map((signal) => {
        switch (signal) {
          case "Slip":
            if (bg === 1 || bg === 3)
              this.props.timetrendSlip1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Slip",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.timetrendSlip2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Slip",
                  "2"
                );
            break;
          case "Speed at Wheel":
            if (bg === 1 || bg === 3)
              this.props.timetrendSpeed1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Speed",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.timetrendSpeed2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Speed",
                  "2"
                );
            break;
          case "Effort":
            if (bg === 1 || bg === 3)
              this.props.timetrendEffort1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Effort",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.timetrendEffort2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "Effort",
                  "2"
                );
            break;
          case "Effort Mech. Brake":
            if (bg === 1 || bg === 3)
              this.props.timetrendEffortBrakingMech1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "EffortBrakingMechanical",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.timetrendEffortBrakingMech2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "EffortBrakingMechanical",
                  "2"
                );
            break;
          case "Speed Driving Trailer":
            if (bg === 1 || bg === 2 || bg === 3)
              this.props.ttDrivingTrailerVel1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "DrivingTrailerVel",
                  "1"
                );
            break;
          case "Effort Reference":
            if (bg === 1 || bg === 3)
              this.props.ttEffortReference1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "EffortReference",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.ttEffortReference2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "EffortReference",
                  "2"
                );
            break;
          case "Power Slip":
            if (bg === 1 || bg === 3)
              this.props.ttSlipPower1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "SlipPower",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.ttSlipPower2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "SlipPower",
                  "2"
                );
            break;
          case "Power Mechanical Brake":
            if (bg === 1 || bg === 3)
              this.props.ttPowerBrakingMechanical1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "PowerBrakingMechanical",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.ttPowerBrakingMechanical2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "PowerBrakingMechanical",
                  "2"
                );
            break;
          case "Pressure Brake Cyl.":
            if (bg === 1 || bg === 3)
              this.props.ttAirPressureBrakeCylinder1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "AirPressureBrakeCylinder",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.ttAirPressureBrakeCylinder2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "AirPressureBrakeCylinder",
                  "2"
                );
            break;
          case "Torsional Osc. Ampl.":
            if (bg === 1 || bg === 3)
              this.props.ttAmplitudeTorsionalOscillation1 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "AmplitudeTorsionalOscillation",
                  "1"
                );
            if (bg === 2 || bg === 3)
              this.props.ttAmplitudeTorsionalOscillation2 === null &&
                this.props.getDetailViewTimetrend(
                  this.props.selectedTrain,
                  startDate,
                  endDate,
                  "AmplitudeTorsionalOscillation",
                  "2"
                );
            break;
          default:
            break;
        }
      });
    });
  };
  //timetrend functions-end

  setZoomIntervalByRunSelection = async (startDate, endDate) => {
    sessionStorage.setItem("isTrendZoomed", true);
    console.log("start1 ", startDate, endDate);
    let formatedPrevStartDate, formatedPrevEndDate;
    if (sessionStorage.getItem("runClick") !== "true")
      if (zoomHistory.length > 1) {
        let backDate = zoomHistory[zoomHistory.length - 1];
        formatedPrevStartDate = backDate[0];
        formatedPrevEndDate = backDate[1];
        sessionStorage.setItem(
          "zoomIntervalPrevStartDate",
          formatedPrevStartDate
        );
        sessionStorage.setItem("zoomIntervalPrevEndDate", formatedPrevEndDate);
        console.log(
          "zoomHistoryyPrev",
          formatedPrevStartDate,
          formatedPrevEndDate
        );
      }
    zoomHistory = [];
    zoomHistory.push([this.props.startDate, this.props.endDate]);
    zoomHistory.push([startDate, endDate]);
    let formatedStartDate = startDate;
    let formatedEndDate = endDate;
    let formatedStartDte = Moment(
        new Date(formatedStartDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm"),
      formatedEndDte = Moment(
        new Date(formatedEndDate.replace(/%3A/g, ":"))
      ).format("DD.MM.YYYY, HH:mm");
    this.setState({
      startZoomInterval: formatedStartDte,
      endZoomInterval: formatedEndDte,
      lockedZoomDuration: this.getDifferenceText(
        Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
        Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
      ),
    });
    let currentSamplingType = this.getSamplingType(
      Moment.utc(formatedStartDte, "DD.MM.YYYY HH:mm:ss").toISOString(),
      Moment.utc(formatedEndDte, "DD.MM.YYYY HH:mm:ss").toISOString()
    );

    //await this.props.clearTrainViewMap();
    //await this.props.clearDetailViewMap();
    //await this.props.clearDetailViewMapDateSlider();
    await this.props.clearDetailViewTimetrend();
    await this.props.clearDetailViewScatterplot();
    await this.props.clearDetailViewHistogram();
    await this.props.addOverlay(null);
    sessionStorage.setItem("zoomIntervalStartDate", formatedStartDate);
    sessionStorage.setItem("zoomIntervalEndDate", formatedEndDate);
    sessionStorage.setItem("samplingType", currentSamplingType);
    //sessionStorage.setItem("isTrendZoomed", true);
    sessionStorage.setItem("applyZoomInterval", true);
    sessionStorage.setItem("runClick", true);
  };

  render() {
    const {
      loading,
      loadingMap,
      loadingTimetrend,
      startDate,
      endDate,
      selectedTrain,
      mapData,
      scatterplotdata,
      timetrenddata,
      histogramdata,
      basicFilter,
      advancedFilter,
      selectedMainTrend,
      selectedOverlaysTrend,
      selectedMainScatter,
      selectedTab,
      mapTotalRuns,
    } = this.props;
    this.handleLoading();
    //console.log("test", this.state.lockedZoomDuration,this.state.startZoomInterval,this.state.endZoomInterval,sessionStorage.getItem("samplingType"));
    return (
      <div>
        <Breadcrumb
          BreadcrumbIcon={
            <img
              src={require("../../assets/images/home.svg")}
              className={""}
              alt="Detail Train View"
            />
          }
          BreadcrumbPath={<FormattedMessage {...messages.header} />}
          NumberofTrains={this.props.trainList && this.props.trainList.length}
        />
        <Row>
          <Col md={12} className="addMarginTop">
            <Datepicker
              onApplyClick={this.applyDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Col>
        </Row>
        <Row className="addMarginTop">
          <Col md={3} className="sideMenucon">
            <SideMenu
              onChange={this.changeBasicFilter}
              onChangeAdvanced={this.changeAdvancedFilter}
              clearBasicFilter={this.clearBasicFilter}
              clearAdvancedFilter={this.clearAdvancedFilter}
              onTrainSelect={this.onTrainSelect}
              advanceFilter={
                selectedTab === "TREND_TYPE" || selectedTab === "SCATTER_TYPE"
                  ? true
                  : false
              }
              trainno={selectedTrain}
              basicFilter={basicFilter}
              advancedFilter={advancedFilter}
              trainList={this.props.trainList}
              selectedTab={selectedTab}
            />
          </Col>
          <Col md={9} className="detailViewRightinner">
            <SingleGraphPage
              mapData={mapData}
              timetrendata={timetrenddata}
              scatterplotdata={this.props.filteredScatterData}
              histogramdata={histogramdata}
              selectedTrain={selectedTrain}
              selectedMainTrend={selectedMainTrend}
              selectedOverlaysTrend={selectedOverlaysTrend}
              clickMainSignalTrend={this.clickMainSignalTrend}
              clickOverlaySignalTrend={this.clickOverlaySignalTrend}
              selectedMainScatter={this.state.scatterDropdownDefaultValue}
              clickMainSignalScatter={this.clickMainSignalScatter}
              basicFilter={basicFilter}
              advancedFilter={advancedFilter}
              loading={loading}
              loadingMap={loadingMap}
              loadingTimetrend={loadingTimetrend}
              selectedTab={selectedTab}
              onClickTab={this.onClickTab}
              handleHistogramDropdownChange={this.handleHistogramDropdownChange}
              getHistogramDataBasedonUserSelection={
                this.getHistogramDataBasedonUserSelection
              }
              selectedSignalsHistogram={this.state}
              handleScatterDropdownChange={this.handleScatterDropdownChange}
              loadingScatterB2={this.props.loadingScatterB2}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              setMainTrendSignal={this.props.setMainTrendSignal}
              onMapDateSelect={this.onMapDateSelect}
              selectedDate={selectedDate}
              mapTotalRuns={mapTotalRuns}
              onLoadMoreGraph={this.onLoadMoreGraph}
              applyZoomInterval={this.applyZoomInterval}
              isButtonDisabled={this.state.isButtonDisabled}
              isApplyZoomEnabled={this.state.isApplyZoomEnabled}
              startZoomInterval={this.state.startZoomInterval}
              endZoomInterval={this.state.endZoomInterval}
              lockedZoomDuration={this.state.lockedZoomDuration}
              setZoomIntervalByRunSelection={this.setZoomIntervalByRunSelection}
              addOverlay={this.props.addOverlay}
              handleScatterplotAvgMaxChange={this.handleScatterplotAvgMaxChange}
              scatterplotAvg={this.state.scatterplotAvg}
              loadingOverlay={this.state.loadingOverlay}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

DetailTrainView.defaultProps = {
  selectedTrain: undefined,
  basicFilter: undefined,
  advancedFilter: undefined,
  startTime: undefined,
  endTime: undefined,
  startTimeDate: undefined,
  endTimeDate: undefined,
  timetrenddata: undefined,
  scatterplotdata: undefined,
  histogramdata: undefined,
};

DetailTrainView.propTypes = {
  selectedTrain: PropTypes.string,
  basicFilter: PropTypes.object,
  advancedFilter: PropTypes.object,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  startTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  endTimeDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  timetrenddata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scatterplotdata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  histogramdata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  clickMainSignalTrend: PropTypes.func,
  clickOverlaySignalTrend: PropTypes.func,
  clickMainSignalScatter: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: loadingSelector(),
  loadingMap: loadingMapSelector(),
  loadingTimetrend: loadingTimetrendSelector(),
  startDate: startDateSelector(),
  endDate: endDateSelector(),
  selectedTrain: selectedTrainSelector(),
  timetrenddata: filteredTimetrendSelector(),
  scatterplotdata: scatterplotSelector(),
  scatterplotBothBogieData: scatterplotBothBogieSelector(),
  filteredScatterData: filteredScatterSelector(),
  basicFilter: basicFilterSelector(),
  advancedFilter: advancedFilterSelector(),
  selectedMainTrend: selectedMainTrendSelector(),
  selectedOverlaysTrend: selectedOverlaysTrendSelector(),
  selectedMainScatter: selectedMainScatterSelector(),
  histogramdata: histogramSelector(),
  selectedTab: selectedTabSelector(),
  timetrendSlip1: timetrendSlip1Selector(),
  timetrendSlip2: timetrendSlip2Selector(),
  timetrendSpeed1: timetrendSpeed1Selector(),
  timetrendSpeed2: timetrendSpeed2Selector(),
  timetrendEffort1: timetrendEffort1Selector(),
  timetrendEffort2: timetrendEffort2Selector(),
  timetrendEffortDriving1: timetrendEffortDriving1Selector(),
  timetrendEffortDriving2: timetrendEffortDriving2Selector(),
  timetrendEffortBraking1: timetrendEffortBraking1Selector(),
  timetrendEffortBraking2: timetrendEffortBraking2Selector(),
  timetrendEffortBrakingMech1: timetrendEffortBrakingMech1Selector(),
  timetrendEffortBrakingMech2: timetrendEffortBrakingMech2Selector(),
  ttDrivingTrailerVel1: ttDrivingTrailerVel1Selector(),
  ttEffortReference1: ttEffortReference1Selector(),
  ttEffortReference2: ttEffortReference2Selector(),
  ttSlipPower1: ttSlipPower1Selector(),
  ttSlipPower2: ttSlipPower2Selector(),
  ttPowerBrakingMechanical1: ttPowerBrakingMechanical1Selector(),
  ttPowerBrakingMechanical2: ttPowerBrakingMechanical2Selector(),
  ttAirPressureBrakeCylinder1: ttAirPressureBrakeCylinder1Selector(),
  ttAirPressureBrakeCylinder2: ttAirPressureBrakeCylinder2Selector(),
  ttAmplitudeTorsionalOscillation1: ttAmplitudeTorsionalOscillation1Selector(),
  ttAmplitudeTorsionalOscillation2: ttAmplitudeTorsionalOscillation2Selector(),
  mapSlip1: mapSlip1Selector(),
  mapSlip2: mapSlip2Selector(),
  mapSpeed1: mapSpeed1Selector(),
  mapSpeed2: mapSpeed2Selector(),
  mapEffort1: mapEffort1Selector(),
  mapEffort2: mapEffort2Selector(),
  mapEffortDriving1: mapEffortDriving1Selector(),
  mapEffortDriving2: mapEffortDriving2Selector(),
  mapEffortBraking1: mapEffortBraking1Selector(),
  mapEffortBraking2: mapEffortBraking2Selector(),
  mapEffortBrakingMech1: mapEffortBrakingMech1Selector(),
  mapEffortBrakingMech2: mapEffortBrakingMech2Selector(),
  mapDrivingTrailerVel1: mapDrivingTrailerVel1Selector(),
  mapEffortReference1: mapEffortReference1Selector(),
  mapEffortReference2: mapEffortReference2Selector(),
  mapSlipPower1: mapSlipPower1Selector(),
  mapSlipPower2: mapSlipPower2Selector(),
  mapPowerBrakingMechanical1: mapPowerBrakingMechanical1Selector(),
  mapPowerBrakingMechanical2: mapPowerBrakingMechanical2Selector(),
  mapAirPressureBrakeCylinder1: mapAirPressureBrakeCylinder1Selector(),
  mapAirPressureBrakeCylinder2: mapAirPressureBrakeCylinder2Selector(),
  mapAmplitudeTorsionalOscillation1: mapAmplitudeTorsionalOscillation1Selector(),
  mapAmplitudeTorsionalOscillation2: mapAmplitudeTorsionalOscillation2Selector(),
  mapData: filteredMapSelector(),
  mapTotalRuns: mapTotalRunsSelector(),
  trainList: fleetDataSelector(),
  loadingScatterB2: loadingScatterB2Selector(),
});

const mapDispatchToProps = {
  getDetailViewMap,
  getDetailViewTimetrend,
  getDetailViewScatterplot,
  setSelectedTrain,
  setDate,
  setBasicFilter,
  setAdvancedFilter,
  setMainTrendSignal,
  setMainScatterSignal,
  addOverlay,
  clearBasicFilter,
  clearAdvancedFilter,
  getDetailViewHistogram,
  setTab,
  setTimetrendLoading,
  fetchTrainList,
  clearDetailViewMap,
  clearDetailViewTimetrend,
  clearDetailViewScatterplot,
  clearDetailViewHistogram,
  clearTrainViewMap,
  clearTrainViewHistogram,
  getDetailViewMapDateSlider,
  clearDetailViewMapDateSlider,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTrainView);
